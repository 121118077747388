import { useSelector } from 'react-redux';

// Type
import { GlobalStateType } from 'redux/store';

// Domain Object
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';
import DebitCardRenewalCancelRequestCherryVisa01Factory from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Factory';

export const useDebitCardRenewalCancelRequestCherryVisa01 = (
  id: string,
): DebitCardRenewalCancelRequestCherryVisa01 | undefined => {
  const state = useSelector(
    (globalState: GlobalStateType) =>
      globalState.debitCardRenewalCancelRequestCherryVisa01sStore,
  );
  return (
    state.byId &&
    state.byId[id] &&
    DebitCardRenewalCancelRequestCherryVisa01Factory.createFromRawData(
      state.byId[id],
    )
  );
};

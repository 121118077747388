import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

// Type
import { useTranslation } from 'react-i18next';
import { DebitCardRenewalRequestCherryVisa01StepStatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// Constant
import { ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS } from 'config/constants/routing';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import CircleSpinLoader from 'components/atoms/spinner/CircleSpinLoader';
import DebitCardRenewalRequestStatusHeader from 'components/organisms/header/HeaderDebitCardRequestStatus';
import BreadCrumbLink from 'components/molecules/navigation/BreadCrumbLink';
import DebitCardRenewalRequestCherryVisa01StepTemplateLayout from './DebitCardRenewalRequestCherryVisa01StepTemplateLayout';

export interface DebitCardRenewalRequestCherryVisa01StepTemplateProps {
  debitCardRenewalRequestCherryVisa01?: DebitCardRenewalRequestCherryVisa01;
  children: React.ReactNode;
  title: string;
  status?: DebitCardRenewalRequestCherryVisa01StepStatusType;
  rejectedReason?: string;
}

const DebitCardRenewalRequestCherryVisa01StepTemplate: FC<DebitCardRenewalRequestCherryVisa01StepTemplateProps> = ({
  debitCardRenewalRequestCherryVisa01,
  children,
  status,
  title,
  rejectedReason,
}: DebitCardRenewalRequestCherryVisa01StepTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <DebitCardRenewalRequestCherryVisa01StepTemplateLayout
      isLoading={!debitCardRenewalRequestCherryVisa01}
    >
      <DebitCardRenewalRequestStatusHeader
        key="header"
        status={status}
        rejectedReason={rejectedReason}
      />
      <PageTitle key="title">{title}</PageTitle>
      <BreadCrumbLink
        key="link"
        onClick={() => {
          history.push(
            `${ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS.replace(
              ':id',
              debitCardRenewalRequestCherryVisa01?.getId() ?? '',
            )}`,
          );
        }}
        text={t(`atoms.button.back`)}
      />
      <CircleSpinLoader key="loader" />
      <div key="childNode">{children}</div>
    </DebitCardRenewalRequestCherryVisa01StepTemplateLayout>
  );
};
export default DebitCardRenewalRequestCherryVisa01StepTemplate;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from 'utils/helpers/connection';
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';
import { useDispatch } from 'react-redux';

// Redux
import { setDebitCardRenewalCancelRequestCherryVisa01ById } from 'redux/reducers/debitCardRenewalCancelRequestCherryVisa01sReducer';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import DebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase';

// Repository
import DebitCardRenewalCancelRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01Functions';

// DomainObject
import { useDebitCardRenewalCancelRequestCherryVisa01 } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/useDebitCardRenewalCancelRequestCherryVisa01';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase = (
  id: string,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  /**
   *
   *  DomainObject
   *
   */
  const debitCardRenewalCancelRequestCherryVisa01 = useDebitCardRenewalCancelRequestCherryVisa01(
    id,
  );

  /**
   *
   *  Repository
   *
   */
  const debitCardRenewalCancelRequestCherryVisa01Repository = new DebitCardRenewalCancelRequestCherryVisa01Functions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase(
    debitCardRenewalCancelRequestCherryVisa01Repository,
  );

  /**
   *
   *  Method
   *
   */
  const openDebitCardRenewalCancelRequestCherryVisa01StatusPage = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(id);

      dispatch(
        setDebitCardRenewalCancelRequestCherryVisa01ById(
          output.debitCardRenewalCancelRequestCherryVisa01,
        ),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /**
   *
   *  UseCase
   *
   */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted)
      openDebitCardRenewalCancelRequestCherryVisa01StatusPage();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [openDebitCardRenewalCancelRequestCherryVisa01StatusPage]);

  return {
    state,
    setState,
    debitCardRenewalCancelRequestCherryVisa01,
  };
};

// IService
import IDebitCardRenewalCancelRequestCherryVisa01UpdateService from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01UpdateService';

// Service
import DebitCardRenewalCancelRequestCherryVisa01UpdateService from 'service/debitCard/debitCardRenewalCancelRequest/debitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01UpdateService';

import { IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

class DebitCardRenewalCancelRequestCherryVisa01PayRenewalFeeUseCase {
  private debitCardRenewalCancelRequestCherryVisa01UpdateService: IDebitCardRenewalCancelRequestCherryVisa01UpdateService;

  constructor(
    debitCardRenewalCancelRequestCherryVisa01Repository: IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRenewalCancelRequestCherryVisa01UpdateService = new DebitCardRenewalCancelRequestCherryVisa01UpdateService(
      debitCardRenewalCancelRequestCherryVisa01Repository,
    );
  }

  payCancellationFee = async (
    debitCardRenewalCancelRequestCherryVisa01: DebitCardRenewalCancelRequestCherryVisa01,
  ) => {
    const updatedDebitCardRenewalCancelRequestCherryVisa01 = await this.debitCardRenewalCancelRequestCherryVisa01UpdateService.payCancellationFee(
      debitCardRenewalCancelRequestCherryVisa01,
    );

    return {
      debitCardRenewalCancelRequestCherryVisa01: updatedDebitCardRenewalCancelRequestCherryVisa01,
    };
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01PayRenewalFeeUseCase;

import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import DebitCardShippingInformationModalContext from 'components/organisms/modal/DebitCardShippingInformationModal/DebitCardShippingInformationModalContext';
import DebitCardIssuanceFeePaymentModalContext from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalContext';

// Component
import DebitCardRequestStatusCard from 'components/organisms/card/verification/DebitCardRenewalRequestStatusCard';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

// Mapper
import DebitCardRenewalRequestCherryVisa01StepsMapper from 'components/organisms/list/DebitCardRenewalRequestCherryVisa01StatusList/mapper/DebitCardRenewalRequestCherryVisa01StepMapper/DebitCardRenewalRequestCherryVisa01StepMapper';

export type DebitCardRenewalRequestCherryVisa01StatusListProps = {
  debitCardRenewalRequestCherryVisa01?: DebitCardRenewalRequestCherryVisa01;
};

const DebitCardRenewalRequestCherryVisa01StatusList: FC<DebitCardRenewalRequestCherryVisa01StatusListProps> = ({
  debitCardRenewalRequestCherryVisa01,
}: DebitCardRenewalRequestCherryVisa01StatusListProps) => {
  const history = useHistory();
  const { showModal: showCardShippingInformationModal } = useContext(
    DebitCardShippingInformationModalContext,
  );
  const { showModal: showCardIssuanceFeePaymentModal } = useContext(
    DebitCardIssuanceFeePaymentModalContext,
  );

  const debitCardRenewalRequestSteps = useMemo(
    () =>
      !debitCardRenewalRequestCherryVisa01
        ? []
        : DebitCardRenewalRequestCherryVisa01StepsMapper.toSteps(
            debitCardRenewalRequestCherryVisa01,
            history,
            showCardShippingInformationModal,
            showCardIssuanceFeePaymentModal,
          ),
    [
      showCardShippingInformationModal,
      showCardIssuanceFeePaymentModal,
      debitCardRenewalRequestCherryVisa01,
      history,
    ],
  );

  return (
    <>
      {debitCardRenewalRequestSteps.map((debitCardRenewalRequestStep, i) => {
        return (
          <div key={`status${i}`} style={{ marginBottom: `${41 / 16}rem` }}>
            <DebitCardRequestStatusCard
              order={i + 1}
              debitCardRequestStepType={
                debitCardRenewalRequestStep.debitCardRenewalRequestStepType
              }
              status={debitCardRenewalRequestStep.status}
              onClick={
                debitCardRenewalRequestStep.onClick
                  ? debitCardRenewalRequestStep.onClick
                  : () => undefined
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default DebitCardRenewalRequestCherryVisa01StatusList;

import React, { FC, useContext } from 'react';
import { useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider/useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase';
import DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContext from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContext';
import DebitCardRenewalCancelRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Context';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider: FC<DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProviderProps> = ({
  children,
}: DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProviderProps) => {
  const { debitCardRenewalCancelRequestCherryVisa01 } = useContext(
    DebitCardRenewalCancelRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    payCancellationFee,
  } = useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase(
    debitCardRenewalCancelRequestCherryVisa01,
  );

  return (
    <DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContext.Provider
      value={{
        state,
        payCancellationFee,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContext.Provider>
  );
};
export default DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider;

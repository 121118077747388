import React from 'react';

export interface DebitCardRenewalRequestCherryVisa01CancelConfirmModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01CancelConfirmModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext;

import React, { FC, useMemo } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Type
import {
  DebitCardRequestCherryVisa01StepStatusType,
  DebitCardRequestCherryVisa01StepType,
} from 'types/DebitCardRequestCherryVisa01.type';

// Style
import {
  StepNoContainerStyle,
  ContainerStyle,
  ContainerDisabledStyle,
} from 'components/organisms/card/verification/DebitCardRequestStatusCard/style';

// Layout Component
import {
  FlexBoxRow,
  FlexBoxColumn,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

// Component
import Display from 'components/atoms/div/Display';

export interface DebitCardRequestStatusCardLayoutProps {
  children: React.ReactElement[];
  status?: DebitCardRequestCherryVisa01StepStatusType;
  debitCardRequestStepType: DebitCardRequestCherryVisa01StepType;
}

const REVIEW_STEPS = [
  DebitCardRequestCherryVisa01StepType.waitingProofOfIdentityReview,
  DebitCardRequestCherryVisa01StepType.waitingProofOfReceiptReview,
];

const DebitCardRequestStatusCardLayout: FC<DebitCardRequestStatusCardLayoutProps> = ({
  children,
  status,
  debitCardRequestStepType,
}: DebitCardRequestStatusCardLayoutProps) => {
  const elements = [
    'step',
    'stepNo',
    'title',
    'statusBadge',
    'description',
    'button',
  ];
  const [
    step,
    stepNo,
    title,
    statusBadge,
    description,
    button,
  ] = getElementsFromKeys(children, elements);

  const containerStyle =
    status === DebitCardRequestCherryVisa01StepStatusType.disabled
      ? ContainerDisabledStyle
      : ContainerStyle;

  const isReviewStep = useMemo(
    () => REVIEW_STEPS.includes(debitCardRequestStepType),
    [debitCardRequestStepType],
  );

  return (
    <FlexBoxRow
      alignItems="center"
      height={186}
      justifyContent="center"
      theme={containerStyle}
      width={1170}
    >
      <FlexBoxRow alignItems="center" padding="30.5 0" width={944}>
        <FlexBoxColumn
          width={114}
          alignItems="center"
          justifyContent="center"
          style={StepNoContainerStyle}
        >
          {step}
          {stepNo}
        </FlexBoxColumn>
        <FlexBoxColumn width={830} paddingLeft={40.5}>
          <FlexBoxRow alignItems="center" height="auto">
            {title}
            <Margin left={22} />
            {statusBadge}
          </FlexBoxRow>
          <Margin top={10} />
          {description}
        </FlexBoxColumn>
      </FlexBoxRow>
      <Display
        isDisplay={!isReviewStep}
        style={{ width: '100%', height: '100%' }}
      >
        {button}
      </Display>
      <Display
        isDisplay={isReviewStep}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <div />
      </Display>
    </FlexBoxRow>
  );
};

export default DebitCardRequestStatusCardLayout;

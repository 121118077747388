// React
import React, { FC } from 'react';

export interface LineBreakReplacedProps {
  text: string;
}

const LineBreakReplaced: FC<LineBreakReplacedProps> = ({
  text,
}: LineBreakReplacedProps) => {
  if (!text) return <></>;

  const lines = text.split('\n');

  if (lines.length === 1) return <>{text}</>;

  return (
    <>
      {lines.map((line, i) => (
        <>
          <span
            key={`line${i}`}
            dangerouslySetInnerHTML={{ __html: line }}
          ></span>
          <br />
        </>
      ))}
    </>
  );
};

export default LineBreakReplaced;

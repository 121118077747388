import color from 'components/atoms/utils/const/color';
import fontWeight from 'components/atoms/utils/const/fontWeight';

export const ButtonAttentionStyle = {
  borderRadius: `${5 / 16}rem`,
  boxShadow: '0px 5px 0px #81818141',
};

export const ButtonStartRenewalStyle = {
  ...ButtonAttentionStyle,
  backgroundColor: color.background.summerSky,
  border: `${1 / 16}rem solid ${color.background.summerSky}`,
};

export const ButtonCancelCardStyle = {
  ...ButtonAttentionStyle,
  backgroundColor: color.background.cinnabar,
  border: `${1 / 16}rem solid ${color.background.cinnabar}`,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
};

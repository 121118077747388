import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { updateDebitCardRenewalRequestCherryVisa01StatusById } from 'redux/reducers/debitCardRenewalRequestCherryVisa01sReducer';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from 'utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Type
import { DebitCardRenewalRequestCherryVisa01StatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import DebitCardRenewalRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01Functions';

// UseCase
import DebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase = (
  debitCardRenewalRequestCherryVisa01:
    | DebitCardRenewalRequestCherryVisa01
    | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  /**
   *
   *  Repository
   *
   */
  const debitCardRenewalRequestCherryVisa01Functions = new DebitCardRenewalRequestCherryVisa01Functions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase(
    debitCardRenewalRequestCherryVisa01Functions,
  );

  /**
   *
   *  Method
   *
   */
  const payRenewalFee = useCallback(
    async (callback?: () => void) => {
      if (!userAuthorized || !debitCardRenewalRequestCherryVisa01) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        await useCase.payRenewalFee(debitCardRenewalRequestCherryVisa01);

        dispatch(
          updateDebitCardRenewalRequestCherryVisa01StatusById({
            debitCardRenewalRequestCherryVisa01,
            status:
              DebitCardRenewalRequestCherryVisa01StatusType.waitingProofOfReceipt,
          }),
        );
        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, debitCardRenewalRequestCherryVisa01],
  );

  return {
    state,
    setState,
    payRenewalFee,
  };
};

import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface DebitCardRenewalRequestCherryVisa01PayRenewalFeeContextProps {
  state: string;
  payRenewalFee: (callback?: () => void) => void;
}

const DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01PayRenewalFeeContextProps
>({
  state: INITIAL,
  payRenewalFee: () => undefined,
});

export default DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface DebitCardRenewalRequestCherryVisa01CancelConfirmContextProps {
  state: string;
  cancel: (callback?: () => void) => void;
}

const DebitCardRenewalRequestCherryVisa01CancelConfirmContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01CancelConfirmContextProps
>({
  state: INITIAL,
  cancel: (callback?: () => void) => undefined,
});

export default DebitCardRenewalRequestCherryVisa01CancelConfirmContext;

import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { EnhancedDebitCardRenewalRequestCherryVisa01ProofOfReceiptPage } from 'containers/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01ProofOfReceiptPage';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PROOF_OF_RECEIPT,
  ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS,
} from '../config/constants/routing';

// Component
import { EnhancedDebitCardRenewalRequestCherryVisa01StatusPage } from '../containers/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01StatusPage';

const DebitCardRenewalRequestCherryVisa01Router: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.debitCards]}
        >
          <Route
            path={`${ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS}`}
          >
            <EnhancedDebitCardRenewalRequestCherryVisa01StatusPage />
          </Route>
          <Route
            path={`${ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PROOF_OF_RECEIPT}`}
          >
            <EnhancedDebitCardRenewalRequestCherryVisa01ProofOfReceiptPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default DebitCardRenewalRequestCherryVisa01Router;

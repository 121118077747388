import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageVewProvider from '../enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from '../enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from '../config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from './accessHandler/userStatusPageAccessMapper';
import { ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS } from '../config/constants/routing';

// Component
import { EnhancedDebitCardRenewalCancelRequestCherryVisa01StatusPage } from '../containers/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01StatusPage';

const DebitCardRenewalCancelRequestCherryVisa01Router: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.debitCards]}
        >
          <Route
            path={`${ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS}`}
          >
            <EnhancedDebitCardRenewalCancelRequestCherryVisa01StatusPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default DebitCardRenewalCancelRequestCherryVisa01Router;

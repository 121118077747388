import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { updateDebitCardRenewalRequestCherryVisa01StatusById } from 'redux/reducers/debitCardRenewalRequestCherryVisa01sReducer';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from 'utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';
import { DebitCardRenewalRequestCherryVisa01StatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// Type
import { useFormInputCherryVisa01ProofOfReceipt } from 'components/organisms/form/debitCardRenewalRequests/FormCherryVisa01ProofOfReceipt/FormCherryVisa01ProofOfReceipt.type';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import CherryStorageFunctions from 'storage/cherryStorageHandler/CherryStorageFunctions';
import DebitCardRenewalRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01Functions';

// UseCase
import DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// File
import { useCherryVisa01ProofOfReceiptFile } from 'enhancers/fileHandler/debitCard/debitCardRequest/ProofOfReceiptFileProvider/useCherryVisa01ProofOfReceiptFile';

export const useDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase = (
  debitCardRenewalRequestCherryVisa01:
    | DebitCardRenewalRequestCherryVisa01
    | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();
  const formInputProofOfReceiptContext = useFormInputCherryVisa01ProofOfReceipt();

  // File
  const { selfie, setSelfie } = useCherryVisa01ProofOfReceiptFile();

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRenewalRequestCherryVisa01Functions = new DebitCardRenewalRequestCherryVisa01Functions();
  const storageHandler = new CherryStorageFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase(
    debitCardRenewalRequestCherryVisa01Functions,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const submitProofOfReceipt = useCallback(
    async (callback?: () => void) => {
      if (!userAuthorized || !debitCardRenewalRequestCherryVisa01 || !selfie)
        return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        await useCase.update(debitCardRenewalRequestCherryVisa01, selfie);

        dispatch(
          updateDebitCardRenewalRequestCherryVisa01StatusById({
            debitCardRenewalRequestCherryVisa01,
            status:
              DebitCardRenewalRequestCherryVisa01StatusType.proofOfReceiptWaitingReview,
          }),
        );

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, debitCardRenewalRequestCherryVisa01, selfie],
  );

  return {
    state,
    setState,
    submitProofOfReceipt,
    formInputProofOfReceiptContext,
    proofOfReceiptFileContext: {
      selfie,
      setSelfie,
    },
  };
};

// IService
import IDebitCardRenewalRequestCherryVisa01UpdateService from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01UpdateService';

// IRepository
import { IDebitCardRenewalRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

class DebitCardRenewalRequestCherryVisa01UpdateService
  implements IDebitCardRenewalRequestCherryVisa01UpdateService {
  private debitCardRenewalRequestCherryVisa01UpdateRepository: IDebitCardRenewalRequestCherryVisa01UpdateRepository;

  constructor(
    debitCardRenewalRequestCherryVisa01UpdateRepository: IDebitCardRenewalRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRenewalRequestCherryVisa01UpdateRepository = debitCardRenewalRequestCherryVisa01UpdateRepository;
  }

  public confirmShippingAddress = async (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    const updatedDebitCardRenewalRequestCherryVisa01 = await this.debitCardRenewalRequestCherryVisa01UpdateRepository.confirmShippingAddress(
      debitCardRenewalRequestCherryVisa01,
    );

    return updatedDebitCardRenewalRequestCherryVisa01;
  };

  public payRenewalFee = async (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    await this.debitCardRenewalRequestCherryVisa01UpdateRepository.payRenewalFee(
      debitCardRenewalRequestCherryVisa01,
    );

    return debitCardRenewalRequestCherryVisa01;
  };

  public updateProofOfReceipt = (
    debitCardRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
    selfieId: string,
  ) => {
    const updatedDebitCardRenewalRequestCherryVisa01 = this.debitCardRenewalRequestCherryVisa01UpdateRepository.updateProofOfReceipt(
      debitCardRequestCherryVisa01,
      selfieId,
    );

    return updatedDebitCardRenewalRequestCherryVisa01;
  };
}

export default DebitCardRenewalRequestCherryVisa01UpdateService;

export enum DebitCardRenewalRequestCherryVisa01StepStatusType {
  initial = 'initial',
  confirmed = 'confirmed',
  shipped = 'shipped',
  delivered = 'delivered',
  paid = 'paid',
  submitted = 'submitted',
  waitingFPBM = 'waitingFPBM',
  disabled = 'disabled',
  rejected = 'rejected',
  onDelivery = 'onDelivery',
}

export enum DebitCardRenewalRequestCherryVisa01StatusType {
  initial = 'initial',
  waitingForShipment = 'waitingForShipment',
  deliveredBeforePayment = 'deliveredBeforePayment',
  waitingProofOfReceipt = 'waitingProofOfReceipt',
  proofOfReceiptWaitingReview = 'proofOfReceiptWaitingReview',
  proofOfReceiptRejected = 'proofOfReceiptRejected',
  active = 'active',
  cancelled = 'cancelled',
}

export enum DebitCardRenewalRequestCherryVisa01StepType {
  shippingInformation = 'shippingInformation',
  preparingCardShipment = 'preparingCardShipment',
  cardDelivery = 'cardDelivery',
  proofOfReceipt = 'proofOfReceipt',
  waitingProofOfReceiptReview = 'waitingProofOfReceiptReview',
  payForCardIssuanceFee = 'payForCardIssuanceFee',
}

// Type
import { DebitCardRenewalRequestCherryVisa01StatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';
import { DebitCardRenewalRequestProps } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest.type';

// ValueObject
import TimeZonedTimeFactory from 'value/datetime/TimeZonedTimeFactory';

class DebitCardRenewalRequest {
  protected id: string;

  protected debitCardId: string;

  protected status: DebitCardRenewalRequestCherryVisa01StatusType;

  protected userId: string;

  protected createdAt: number;

  protected updatedAt: number;

  protected expiredYearMonth: {
    year: number;
    month: number;
  };

  protected props: DebitCardRenewalRequestProps;

  constructor(id: string, props: DebitCardRenewalRequestProps) {
    this.id = id;
    this.status = props.status;
    this.debitCardId = props.debitCardId;
    this.userId = props.userId;
    this.expiredYearMonth = props.expiredYearMonth;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public isUnderRenewalProcess = () =>
    ![
      DebitCardRenewalRequestCherryVisa01StatusType.initial,
      DebitCardRenewalRequestCherryVisa01StatusType.cancelled,
      DebitCardRenewalRequestCherryVisa01StatusType.active,
    ].includes(this.status);

  public inNotCompleted = () =>
    ![
      DebitCardRenewalRequestCherryVisa01StatusType.cancelled,
      DebitCardRenewalRequestCherryVisa01StatusType.active,
    ].includes(this.status);

  public getExpiredDateFormatted = () => {
    const { year, month } = this.expiredYearMonth;
    const date = `${year}/${month.toString().padStart(2, '0')}/01`;
    return TimeZonedTimeFactory.createZeroOClock(date)
      .addMonth(1)
      .subtractDay(1)
      .format('DD/MMM/YYYY');
  };

  public canCancelCard = (nextMonth: number) => {
    const { year, month } = this.expiredYearMonth;
    const date = `${year}/${month.toString().padStart(2, '0')}/01`;

    // ExpiryDate
    const expiryDateTz = TimeZonedTimeFactory.createZeroOClock(date).addMonth(
      1,
    );

    // First day of preparation month
    const firstDayOfPreparationMonthTz = expiryDateTz.subtractMonth(nextMonth);

    // return now < ExpiryDate - nextMonth
    return firstDayOfPreparationMonthTz.diffTimestampFromCurrentTime() >= 0;
  };
}
export default DebitCardRenewalRequest;

import React, { FC } from 'react';
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import DebitCardRenewalCancelRequestCherryVisa01StatusTemplate from 'components/templates/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01StatusTemplate';

const DebitCardRenewalCancelRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DashboardTemplate>
      <DebitCardRenewalCancelRequestCherryVisa01StatusTemplate />
    </DashboardTemplate>
  );
};

export default DebitCardRenewalCancelRequestCherryVisa01StatusPage;

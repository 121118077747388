import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { ContainerBoxStyle } from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/style';

// Components
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardIssuanceFeePaymentModalLayoutProps {
  children: React.ReactElement[];
  isLoading: boolean;
}

const DebitCardIssuanceFeePaymentModalLayout: FC<DebitCardIssuanceFeePaymentModalLayoutProps> = ({
  children,
  isLoading,
}: DebitCardIssuanceFeePaymentModalLayoutProps) => {
  const elements = [
    'title',
    'table',
    'loader',
    'message',
    'submitButton',
    'cancelButton',
  ];
  const [
    title,
    table,
    loader,
    message,
    submitButton,
    cancelButton,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      width={1200}
      height="auto"
      padding={50}
      theme={ContainerBoxStyle}
    >
      {title}
      <Margin top={60} />
      {!isLoading && table}
      {isLoading && loader}
      <Margin top={60} />
      {!isLoading && message}
      <Margin top={60} />
      <FlexBoxRow justifyContent="center">
        {submitButton}
        <Margin left={40} />
        {cancelButton}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default DebitCardIssuanceFeePaymentModalLayout;

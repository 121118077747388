import React, { FC, useContext } from 'react';
import { useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider/useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewUseCase';
import DebitCardRenewalCancelRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import SavingsAccountContext from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider: FC<DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProviderProps> = ({
  children,
}: DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProviderProps) => {
  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRenewalCancelRequestCherryVisa01 } = useContext(
    DebitCardRenewalCancelRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    savingsAccount,
  } = useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewUseCase(
    debitCardRenewalCancelRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <SavingsAccountContext.Provider value={{ savingsAccount }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </SavingsAccountContext.Provider>
  );
};

export default DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider;

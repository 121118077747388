import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Constant
import { ROUTE_DEBIT_CARD } from 'config/constants/routing';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import BreadCrumbLink from 'components/molecules/navigation/BreadCrumbLink';
import HeaderContentTitleLayout from 'components/organisms/header/HeaderContentTitle/HeaderContentTitleLayout';

const ORGANISM_ID = `organisms.header.debitCard.debitCardRenewalCancelRequest.HeaderDebitCardRenewalCancelRequestCherryVisa01Status`;

const HeaderDebitCardRenewalCancelRequestCherryVisa01Status: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <BreadCrumbLink
        key="right"
        onClick={() => {
          history.push(ROUTE_DEBIT_CARD);
        }}
        text={t(`${ORGANISM_ID}.linkText`)}
      />
    </HeaderContentTitleLayout>
  );
};

export default HeaderDebitCardRenewalCancelRequestCherryVisa01Status;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContextProps {
  state: string;
  confirm: (callback?: () => void) => void;
}

const DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContextProps
>({
  state: INITIAL,
  confirm: (callback?: () => void) => undefined,
});

export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext;

import React, { FC } from 'react';
import DebitCardIssuanceFeePaymentModalProvider from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalProvider';
import DebitCardShippingInformationModalProvider from 'components/organisms/modal/CardShippingInformationModal/CardShippingInformationModalProvider';
import DebitCardRequestCherryVisa01StatusViewProvider from 'enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01StatusViewProvider';
import DebitCardRequestCherryVisa01PayIssuanceFeeProvider from 'enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/DebitCardRequestCherryVisa01PayIssuanceFeeProvider';
import DebitCardRequestCherryVisa01StatusPage from './DebitCardRequestCherryVisa01StatusPage';

const EnhancedDebitCardRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DebitCardRequestCherryVisa01StatusViewProvider>
      <DebitCardRequestCherryVisa01PayIssuanceFeeProvider>
        <DebitCardShippingInformationModalProvider>
          <DebitCardIssuanceFeePaymentModalProvider>
            <DebitCardRequestCherryVisa01StatusPage />
          </DebitCardIssuanceFeePaymentModalProvider>
        </DebitCardShippingInformationModalProvider>
      </DebitCardRequestCherryVisa01PayIssuanceFeeProvider>
    </DebitCardRequestCherryVisa01StatusViewProvider>
  );
};

export default EnhancedDebitCardRequestCherryVisa01StatusPage;

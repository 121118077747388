import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';
import {
  DebitCardRenewalCancelRequestCherryVisa01Props,
  DebitCardRenewalCancelRequestCherryVisa01PropsFormat,
  DebitCardRenewalCancelRequestCherryVisa01RawDataType,
} from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01.type';

class DebitCardRenewalCancelRequestCherryVisa01Factory {
  static create = (
    id: string,
    props: DebitCardRenewalCancelRequestCherryVisa01Props,
  ) => {
    const result = Joi.object(
      DebitCardRenewalCancelRequestCherryVisa01PropsFormat,
    ).validate({
      props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardRenewalCancelRequestCherryVisa01.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new DebitCardRenewalCancelRequestCherryVisa01(id, props);
  };

  static createFromRawData = (
    debitCardRequestCherryVisa01RawData: DebitCardRenewalCancelRequestCherryVisa01RawDataType,
  ) => {
    const { id, props } = debitCardRequestCherryVisa01RawData;

    return new DebitCardRenewalCancelRequestCherryVisa01(id, props);
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01Factory;

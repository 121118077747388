import React, { FC } from 'react';
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import DebitCardRenewalRequestCherryVisa01StatusTemplate from 'components/templates/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01StatusTemplate';

const DebitCardRenewalRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DashboardTemplate>
      <DebitCardRenewalRequestCherryVisa01StatusTemplate />
    </DashboardTemplate>
  );
};

export default DebitCardRenewalRequestCherryVisa01StatusPage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { DebitCardRenewalRequestCherryVisa01StepStatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// Style
import { TextStyle } from 'components/molecules/badge/DebitCardRenewalRequestStatusBadge/style';

// DomainObject
import { TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRenewalRequestStatusBadgeLayout from 'components/molecules/badge/DebitCardRenewalRequestStatusBadge/DebitCardRenewalRequestStatusBadgeLayout';
import DebitCardRenewalRequestStatusIcon from 'components/atoms/icon/DebitCardRenewalRequestStatusIcon';

export interface DebitCardRenewalRequestStepStatusTypeBadgeProps {
  status?: DebitCardRenewalRequestCherryVisa01StepStatusType;
  layout: {
    height: string | number;
    iconWidth: string | number;
    iconHeight: string | number;
  };
}

const DebitCardRenewalRequestStepStatusTypeBadge: FC<DebitCardRenewalRequestStepStatusTypeBadgeProps> = ({
  status,
  layout,
}: DebitCardRenewalRequestStepStatusTypeBadgeProps) => {
  const { t } = useTranslation();

  const displayBadgeStatus = [
    DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    DebitCardRenewalRequestCherryVisa01StepStatusType.rejected,
    DebitCardRenewalRequestCherryVisa01StepStatusType.submitted,
    DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM,
    DebitCardRenewalRequestCherryVisa01StepStatusType.shipped,
    DebitCardRenewalRequestCherryVisa01StepStatusType.delivered,
    DebitCardRenewalRequestCherryVisa01StepStatusType.paid,
  ];

  if (status && !displayBadgeStatus.includes(status)) return <></>;

  let [backgroundColor, color] = ['', ''];

  switch (status) {
    case DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed:
    case DebitCardRenewalRequestCherryVisa01StepStatusType.shipped:
    case DebitCardRenewalRequestCherryVisa01StepStatusType.paid:
    case DebitCardRenewalRequestCherryVisa01StepStatusType.delivered:
      [backgroundColor, color] = ['#EAF8D7', '#95CB4B'];
      break;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM:
      [backgroundColor, color] = ['#FFF1D1', '#F5A026'];
      break;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.submitted:
      [backgroundColor, color] = ['#C7EAFF', '#2474C5'];
      break;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.rejected:
      [backgroundColor, color] = ['#FFE4E2', '#E44838'];
      break;
    default:
      [backgroundColor, color] = ['', ''];
  }

  return (
    <DebitCardRenewalRequestStatusBadgeLayout
      backgroundColor={backgroundColor}
      height={layout.height}
    >
      <DebitCardRenewalRequestStatusIcon
        key="icon"
        status={status}
        height={layout.iconHeight}
        width={layout.iconWidth}
      />
      <TextSecondary
        key="text"
        theme={{
          ...TextStyle,
          color,
        }}
      >
        {t(`debitCardRenewalRequestStepStatusType.${status}`)}
      </TextSecondary>
    </DebitCardRenewalRequestStatusBadgeLayout>
  );
};

export default DebitCardRenewalRequestStepStatusTypeBadge;

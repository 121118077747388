import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router';
import DebitCardIssuanceFeePaymentModalContext from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalContext';
import DebitCardRequestCherryVisa01Context from 'domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider from 'enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider';
import DebitCardRequestCherryVisa01PayIssuanceFeeContext from 'enhancers/useCase/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/DebitCardRequestCherryVisa01PayIssuanceFeeProvider/DebitCardRequestCherryVisa01PayIssuanceFeeContext';

// Constant
import { ROUTE_DEBIT_CARD } from 'config/constants/routing';

// Component
import DebitCardIssuanceFeePaymentModal from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal';

export type DebitCardIssuanceFeePaymentModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardIssuanceFeePaymentModalProvider: FC<DebitCardIssuanceFeePaymentModalProviderProps> = ({
  children,
}: DebitCardIssuanceFeePaymentModalProviderProps) => {
  const history = useHistory();
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );

  const { state, submit } = useContext(
    DebitCardRequestCherryVisa01PayIssuanceFeeContext,
  );

  const userAuthorizedContext = useContext(UserAuthorizedContext);

  const [showModal, hideModal] = useModal(
    () => (
      <DebitCardIssuanceFeePaymentModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRequestCherryVisa01Context.Provider
          value={{ debitCardRequestCherryVisa01 }}
        >
          <UserAuthorizedContext.Provider value={userAuthorizedContext}>
            <DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider>
              <DebitCardIssuanceFeePaymentModal
                state={state}
                onSubmit={() => {
                  submit(() => {
                    hideModal();
                    history.push(ROUTE_DEBIT_CARD);
                  });
                }}
              />
            </DebitCardRequestCherryVisa01PayIssuanceFeeViewProvider>
          </UserAuthorizedContext.Provider>
        </DebitCardRequestCherryVisa01Context.Provider>
      </DebitCardIssuanceFeePaymentModalContext.Provider>
    ),
    [debitCardRequestCherryVisa01, userAuthorizedContext, state, submit],
  );

  return (
    <DebitCardIssuanceFeePaymentModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </DebitCardIssuanceFeePaymentModalContext.Provider>
  );
};
export default DebitCardIssuanceFeePaymentModalProvider;

import { useCallback, useState } from 'react';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from 'utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import DebitCardRenewalRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01Functions';

// UseCase
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

export const useDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase = (
  debitCardRenewalRequestCherryVisa01:
    | DebitCardRenewalRequestCherryVisa01
    | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRenewalRequestCherryVisa01Functions = new DebitCardRenewalRequestCherryVisa01Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase(
    debitCardRenewalRequestCherryVisa01Functions,
  );

  /* *
   *
   *  Method
   *
   * */
  const confirm = useCallback(
    async (callback?: () => void) => {
      if (!debitCardRenewalRequestCherryVisa01) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        await useCase.confirm(debitCardRenewalRequestCherryVisa01);

        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [debitCardRenewalRequestCherryVisa01],
  );

  return {
    state,
    setState,
    confirm,
  };
};

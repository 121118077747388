import React from 'react';

export interface DebitCardShippingInformationModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const DebitCardShippingInformationModalContext = React.createContext<
  DebitCardShippingInformationModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default DebitCardShippingInformationModalContext;

import React, { FC } from 'react';

// Libs
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Styles
import { ContainerBoxStyle } from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/style';

// Component
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardRenewalRequestCherryVisa01CancelConfirmModalLayoutProps {
  children: React.ReactElement[];
}

const DebitCardRenewalRequestCherryVisa01CancelConfirmModalLayout: FC<DebitCardRenewalRequestCherryVisa01CancelConfirmModalLayoutProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01CancelConfirmModalLayoutProps) => {
  const elements = ['title', 'message', 'submitButton', 'cancelButton'];
  const [title, message, submitButton, cancelButton] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn
      alignItems="center"
      width={800}
      height="auto"
      padding={50}
      theme={ContainerBoxStyle}
    >
      {title}
      <Margin top={60} />
      {message}
      <Margin top={60} />
      <FlexBoxRow justifyContent="center">
        {submitButton}
        <Margin left={40} />
        {cancelButton}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default DebitCardRenewalRequestCherryVisa01CancelConfirmModalLayout;

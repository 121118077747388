import React, { FC } from 'react';
import DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider';
import DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider';
import DebitCardRenewalRequestCherryVisa01ProofOfReceiptPage from './DebitCardRenewalRequestCherryVisa01ProofOfReceiptPage';

const EnhancedDebitCardRenewalRequestCherryVisa01ProofOfReceiptPage: FC = () => {
  return (
    <DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider>
      <DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider>
        <DebitCardRenewalRequestCherryVisa01ProofOfReceiptPage />
      </DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider>
    </DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider>
  );
};

export default EnhancedDebitCardRenewalRequestCherryVisa01ProofOfReceiptPage;

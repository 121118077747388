import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Type
import { DebitCardHistoryType } from 'components/molecules/selector/tabBar/UnderBarTabBar/DebitCardHistoryTabBar/DebitCardHistory.type';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Box from 'components/atoms/utils/layout/Box/Box';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardTemplateContentLayoutProps {
  children: React.ReactElement[];
  debitCardHistoryOption: DebitCardHistoryType;
  isAnnualFeeDateDisplay: boolean;
  is3DSecure: boolean;
  isShowAttentionMessageBox: boolean;
}

const DebitCardTemplateContentLayout: FC<DebitCardTemplateContentLayoutProps> = ({
  children,
  debitCardHistoryOption,
  isAnnualFeeDateDisplay,
  is3DSecure,
  isShowAttentionMessageBox,
}: DebitCardTemplateContentLayoutProps) => {
  const elements = [
    'attentionMessageBox',
    'debitCard',
    'balance',
    'accountHolder',
    'selectBox',
    'annualFeeDate',
    'badge3dSecure',
    // 'statementIcon',
    // 'statement',
    'button',
    'csvButton',
    'iconCardFee',
    'linkCardFee',
    'historyTabBar',
    'cardHistory',
    'cardHistoryDescription',
    'topUpHistory',
  ];

  const [
    attentionMessageBox,
    debitCard,
    balance,
    accountHolder,
    selectBox,
    annualFeeDate,
    badge3dSecure,
    // statementIcon,
    // statement,
    button,
    csvButton,
    iconCardFee,
    linkCardFee,
    historyTabBar,
    cardHistory,
    cardHistoryDescription,
    topUpHistory,
  ] = getElementsFromKeys(children, elements);

  return (
    <>
      {isShowAttentionMessageBox && (
        <>
          {attentionMessageBox}
          <Margin top={30} />
        </>
      )}
      <FlexBoxRow height={236} justifyContent="space-between">
        {debitCard}
        <Margin left={50} />
        <FlexBoxColumn width="540" alignItems="start">
          {balance}
          <Margin top={10} />
          {accountHolder}
          <Margin top={20} />
          <Box width={400} height={50}>
            {selectBox}
          </Box>
          {isAnnualFeeDateDisplay && (
            <>
              {is3DSecure && (
                <>
                  <Margin top={10} />
                  {badge3dSecure}
                </>
              )}
              <Margin top={10} />
              {annualFeeDate}
              <Margin top={10} />
              <FlexBoxRow
                alignItems="center"
                height="auto"
                justifyContent="flex-start"
                width={280}
              >
                {iconCardFee}
                <Margin left={10} />
                {linkCardFee}
              </FlexBoxRow>
            </>
          )}
          <Margin top={40} />
        </FlexBoxColumn>
        <FlexBoxColumn
          alignItems="end"
          height="auto"
          justifyContent="flex-end"
          width="auto"
        >
          {button}
          <Margin top={20} />
          {csvButton}
        </FlexBoxColumn>
      </FlexBoxRow>
      <Margin top={100} />
      {historyTabBar}
      <Margin top={30} />
      <FlexBoxColumn height={1050}>
        {debitCardHistoryOption === DebitCardHistoryType.cardTransaction && (
          <>
            {cardHistoryDescription}
            <Margin top={30} />
            {cardHistory}
          </>
        )}
        {debitCardHistoryOption === DebitCardHistoryType.topUp && (
          <>{topUpHistory}</>
        )}
      </FlexBoxColumn>
    </>
  );
};
export default DebitCardTemplateContentLayout;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import {
  DebitCardRenewalRequestCherryVisa01StepType,
  DebitCardRenewalRequestCherryVisa01StepStatusType,
} from 'types/DebitCardRenewalRequestCherryVisa01.type';

// Style
import {
  StepStyle,
  StepNumberStyle,
  TitleStyle,
  DescriptionStyle,
  ButtonTextStyle,
  IconStyle,
} from 'components/organisms/card/verification/DebitCardRenewalRequestStatusCard/style';

// Component
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRenewalRequestStatusSquareButton from 'components/atoms/button/DebitCardRenewalRequestStatusSquareButton';
import DebitCardRenewalRequestStepBadge from 'components/molecules/badge/DebitCardRenewalRequestStatusBadge';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';
import DebitCardRenewalRequestStatusCardLayout from 'components/organisms/card/verification/DebitCardRenewalRequestStatusCard/DebitCardRenewalRequestStatusCardLayout';

const ORGANISM_ID = `organisms.card.DebitCardRenewalRequestStatusCard`;

export interface DebitCardRenewalRequestStatusCardProps {
  order: number;
  debitCardRequestStepType: DebitCardRenewalRequestCherryVisa01StepType;
  status: DebitCardRenewalRequestCherryVisa01StepStatusType;
  onClick: () => void;
}

const DebitCardRenewalRequestStatusCard: FC<DebitCardRenewalRequestStatusCardProps> = ({
  order,
  debitCardRequestStepType,
  status,
  onClick,
}: DebitCardRenewalRequestStatusCardProps) => {
  const { t } = useTranslation();

  return (
    <DebitCardRenewalRequestStatusCardLayout
      status={status}
      debitCardRequestStepType={debitCardRequestStepType}
    >
      <TextSecondary key="step" theme={StepStyle}>
        {t(`${ORGANISM_ID}.word.step`)}
      </TextSecondary>
      <TextSecondary key="stepNo" theme={StepNumberStyle}>
        {String(order)}
      </TextSecondary>
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.title`)}
      </TextPrimary>
      <DebitCardRenewalRequestStepBadge
        key="statusBadge"
        status={status}
        layout={{
          height: 40,
          iconWidth: 18,
          iconHeight: 18,
        }}
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced
          text={t(
            `${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.description`,
          )}
        />
      </TextSecondary>
      <DebitCardRenewalRequestStatusSquareButton
        key="button"
        height="100%"
        marginBetween={10}
        onClick={onClick}
        status={status}
        theme={{
          text: ButtonTextStyle,
          icon: IconStyle,
        }}
        width="100%"
      />
    </DebitCardRenewalRequestStatusCardLayout>
  );
};
export default DebitCardRenewalRequestStatusCard;

import React, { FC } from 'react';
import DebitCardRenewalRequestCherryVisa01StatusViewProvider from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRequestRenewalCherryVisa01StatusViewProvider';
import DebitCardRenewalShippingInformationModalProvider from 'components/organisms/modal/DebitCardShippingInformationModal/DebitCardRenewalShippingInformationModal/DebitCardRenewalShippingInformationModalProvider';
import DebitCardRenewalFeePaymentModalProvider from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardRenewalFeePaymentModal/DebitCardRenewalFeePaymentModalProvider';
import DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider';
import DebitCardRenewalRequestCherryVisa01StatusPage from './DebitCardRenewalRequestCherryVisa01StatusPage';

const EnhancedDebitCardRenewalRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DebitCardRenewalRequestCherryVisa01StatusViewProvider>
      <DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider>
        <DebitCardRenewalShippingInformationModalProvider>
          <DebitCardRenewalFeePaymentModalProvider>
            <DebitCardRenewalRequestCherryVisa01StatusPage />
          </DebitCardRenewalFeePaymentModalProvider>
        </DebitCardRenewalShippingInformationModalProvider>
      </DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider>
    </DebitCardRenewalRequestCherryVisa01StatusViewProvider>
  );
};

export default EnhancedDebitCardRenewalRequestCherryVisa01StatusPage;

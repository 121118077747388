import React, { FC, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider/useDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

// Constant
import { ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS } from 'config/constants/routing';

export type DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider: FC<DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProviderProps) => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    debitCardRenewalRequestCherryVisa01,
  } = useDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase(
    id,
    userAuthorized,
  );

  // Redirect if status is invalid
  useEffect(() => {
    if (
      debitCardRenewalRequestCherryVisa01 &&
      !debitCardRenewalRequestCherryVisa01.isProofOfReceiptEditable()
    )
      history.push(
        ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS.replace(
          ':id',
          debitCardRenewalRequestCherryVisa01.getId(),
        ),
      );
    // eslint-disable-next-line
  }, [debitCardRenewalRequestCherryVisa01]);

  return (
    <DebitCardRenewalRequestCherryVisa01Context.Provider
      value={{ debitCardRenewalRequestCherryVisa01 }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalRequestCherryVisa01Context.Provider>
  );
};

export default DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider;

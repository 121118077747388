// DomainObject
import DomainObjects from 'domain/interface/Iterator/DomainObjects';
import DebitCardRenewalRequest from '../DebitCardRenewalRequest/DebitCardRenewalRequest';

class DebitCardRenewalRequests extends DomainObjects<DebitCardRenewalRequest> {
  public filterNotCompleted = () => {
    const filtered = this.items.filter((debitCardRenewalRequest) =>
      debitCardRenewalRequest.inNotCompleted(),
    );
    return new DebitCardRenewalRequests(filtered);
  };

  public filterByDebitCardId = (debitCardId: string) => {
    const filtered = this.items.filter(
      (debitCardRenewalRequest) =>
        debitCardRenewalRequest.getProps().debitCardId === debitCardId,
    );
    return new DebitCardRenewalRequests(filtered);
  };
}

export default DebitCardRenewalRequests;

import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext';
import DebitCardRenewalRequestContext from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequestContext';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider';

// Component
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

export type DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProvider: FC<DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProviderProps) => {
  const history = useHistory();
  const { debitCardRenewalRequest } = useContext(
    DebitCardRenewalRequestContext,
  );
  const userAuthorizedContext = useContext(UserAuthorizedContext);

  const [showModal, hideModal] = useModal(
    () => (
      <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRenewalRequestCherryVisa01Context.Provider
          value={{ debitCardRenewalRequestCherryVisa01: debitCardRenewalRequest as DebitCardRenewalRequestCherryVisa01 }}
        >
          <UserAuthorizedContext.Provider value={userAuthorizedContext}>
            <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider>
              <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal history={history} />
            </DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider>
          </UserAuthorizedContext.Provider>
        </DebitCardRenewalRequestCherryVisa01Context.Provider>
      </DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext.Provider>
    ),
    [debitCardRenewalRequest, userAuthorizedContext],
  );

  return (
    <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext.Provider>
  );
};
export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProvider;

import React from 'react';

export interface DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext;

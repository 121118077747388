// Type
import { TypeOfT } from 'types/typeof/Translation.type';

// DomainObject
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

const ORGANISM_ID = `organisms.table.TableConfirmDebitCardRenewalRequestShippingAddress`;

class TableConfirmDebitCardRenewalRequestShippingAddressMapper {
  static domainToTd = (userAuthorized: UserAuthorized, t: TypeOfT) => {
    const shippingAddress = userAuthorized.getShippingAddress();

    if (!shippingAddress) return [];

    const {
      country,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      receiverName,
    } = shippingAddress.getProps();
    const tdContactNumber = shippingAddress.getPhoneFormatted();

    return [
      {
        header: t(`${ORGANISM_ID}.th.country`),
        data: country,
      },
      {
        header: t(`${ORGANISM_ID}.th.address`),
        data: `${addressLine1}\n${addressLine2}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.city`),
        data: city,
      },
      {
        header: t(`${ORGANISM_ID}.th.state`),
        data: state,
      },
      {
        header: t(`${ORGANISM_ID}.th.postalCode`),
        data: postalCode,
      },
      {
        header: t(`${ORGANISM_ID}.th.contactNumber`),
        data: tdContactNumber,
      },
      {
        header: t(`${ORGANISM_ID}.th.receiverName`),
        data: receiverName,
      },
    ];
  };
}

export default TableConfirmDebitCardRenewalRequestShippingAddressMapper;

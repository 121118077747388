/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContextProps {
  state: string;
  submitProofOfReceipt: (callback?: () => void) => void;
}

const DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContextProps
>({
  state: INITIAL,
  submitProofOfReceipt: (callback?: () => void) => undefined,
});

export default DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContext;

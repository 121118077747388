// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import { DebitCardRenewalRequestCherryVisa01RawDataType } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01.type';
import { DebitCardRenewalRequestCherryVisa01StatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

export interface DebitCardRenewalRequestCherryVisa01sState {
  byId: {
    [id: string]: DebitCardRenewalRequestCherryVisa01RawDataType;
  };
}

// eslint-disable-next-line
const initialState: any = {
  byId: {},
};

const debitCardRenewalRequestCherryVisa01sSlice = createSlice({
  name: 'debitCardRenewalRequestCherryVisa01s',
  initialState,
  reducers: {
    setDebitCardRenewalRequestCherryVisa01ById: (
      state: DebitCardRenewalRequestCherryVisa01sState,
      action: PayloadAction<DebitCardRenewalRequestCherryVisa01RawDataType>,
    ) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload,
      },
    }),
    updateDebitCardRenewalRequestCherryVisa01StatusById: (
      state: DebitCardRenewalRequestCherryVisa01sState,
      action: PayloadAction<{
        debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01;
        status: DebitCardRenewalRequestCherryVisa01StatusType;
      }>,
    ) => {
      const { debitCardRenewalRequestCherryVisa01, status } = action.payload;
      const id = debitCardRenewalRequestCherryVisa01.getId();
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            props: {
              ...state.byId[id].props,
              status,
            },
          },
        },
      };
    },
  },
});

export const {
  setDebitCardRenewalRequestCherryVisa01ById,
  updateDebitCardRenewalRequestCherryVisa01StatusById,
} = debitCardRenewalRequestCherryVisa01sSlice.actions;
export default debitCardRenewalRequestCherryVisa01sSlice.reducer;

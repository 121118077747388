// Functions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import IDebitCardRenewalRequestRepository from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/IDebitCardRenewalRequestRepository';

// Constant
import { DEBIT_CARD_RENEWAL_REQUEST_GET_RAW_BY_USER_ID } from 'infrastructure/firebase/firebaseFunctions/endpoint';

class DebitCardRenewalRequestFunctions
  extends FirebasePrivateFunctions
  implements IDebitCardRenewalRequestRepository {
  public findAllByUserId = async (userId: string) => {
    // Replace userId
    const endpoint = DEBIT_CARD_RENEWAL_REQUEST_GET_RAW_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    return response.data.data;
  };
}
export default DebitCardRenewalRequestFunctions;

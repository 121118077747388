// IService
import IDebitCardRenewalCancelRequestCherryVisa01UpdateService from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01UpdateService';

// IRepository
import { IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalRequest from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest';
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

class DebitCardRenewalCancelRequestCherryVisa01UpdateService
  implements IDebitCardRenewalCancelRequestCherryVisa01UpdateService {
  private debitCardRenewalCancelRequestCherryVisa01UpdateRepository: IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository;

  constructor(
    debitCardRenewalCancelRequestCherryVisa01UpdateRepository: IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRenewalCancelRequestCherryVisa01UpdateRepository = debitCardRenewalCancelRequestCherryVisa01UpdateRepository;
  }

  public confirmCancel = async (
    debitCardRenewalRequest: DebitCardRenewalRequest,
  ) => {
    const updatedDebitCardRenewalCancelRequestCherryVisa01 = await this.debitCardRenewalCancelRequestCherryVisa01UpdateRepository.confirmCancel(
      debitCardRenewalRequest,
    );

    return updatedDebitCardRenewalCancelRequestCherryVisa01;
  };

  public payCancellationFee = async (
    debitCardRenewalCancelRequestCherryVisa01: DebitCardRenewalCancelRequestCherryVisa01,
  ) => {
    await this.debitCardRenewalCancelRequestCherryVisa01UpdateRepository.payCancellationFee(
      debitCardRenewalCancelRequestCherryVisa01,
    );

    return debitCardRenewalCancelRequestCherryVisa01;
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01UpdateService;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRenewalCancelRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Context';

// Type
import { DescriptionStyle } from 'components/templates/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01StatusTemplate/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import CircleSpinLoader from 'components/atoms/spinner/CircleSpinLoader';
import DebitCardRenewalCancelRequestCherryVisa01StatusList from 'components/organisms/list/DebitCardRenewalCancelRequestCherryVisa01StatusList';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';
import HeaderDebitCardRenewalCancelRequestCherryVisa01Status from 'components/organisms/header/HeaderContentTitle/debitCard/HeaderDebitCardRenewalCancelRequestCherryVisa01Status';
import DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayout from 'components/templates/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01StatusTemplate/DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayout';

const TEMPLATE_ID = `templates.debitCards.DebitCardRenewalCancelRequestCherryVisa01StatusTemplate`;

const DebitCardRenewalCancelRequestCherryVisa01StatusTemplate: FC = () => {
  const { t } = useTranslation();

  const { debitCardRenewalCancelRequestCherryVisa01 } = useContext(
    DebitCardRenewalCancelRequestCherryVisa01Context,
  );

  return (
    <DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayout
      isLoading={!debitCardRenewalCancelRequestCherryVisa01}
    >
      <HeaderDebitCardRenewalCancelRequestCherryVisa01Status key="header" />
      <CircleSpinLoader key="loader" />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={t(`${TEMPLATE_ID}.description`)} />
      </TextSecondary>
      <DebitCardRenewalCancelRequestCherryVisa01StatusList
        key="list"
        debitCardRenewalCancelRequestCherryVisa01={
          debitCardRenewalCancelRequestCherryVisa01
        }
      />
    </DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayout>
  );
};

export default DebitCardRenewalCancelRequestCherryVisa01StatusTemplate;

import Joi from '@hapi/joi';

// Type
import { DebitCardRenewalCancelRequestCherryVisa01StatusType } from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

export interface DebitCardRenewalCancelRequestProps {
  status: DebitCardRenewalCancelRequestCherryVisa01StatusType;

  debitCardId: string;

  userId: string;

  expiredYearMonth: {
    year: number;
    month: number;
  };

  createdAt?: number;

  updatedAt?: number;
}

export interface DebitCardRenewalCancelRequestRawDataType {
  id: string;

  props: DebitCardRenewalCancelRequestProps;
}

export const DebitCardRenewalCancelRequestPropsFormat = {
  id: Joi.string().min(1).required(),

  status: Object.keys(DebitCardRenewalCancelRequestCherryVisa01StatusType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  userId: Joi.string().required(),

  debitCardId: Joi.string().required(),

  expiredYearMonth: Joi.object({
    year: Joi.number().required(),
    month: Joi.number().required(),
  }).required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};

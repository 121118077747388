import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import MaintenanceProvider from '../enhancers/useCase/setting/maintenance/MaintenanceProvider';

// Router
import AssetRouter from './assetRouter';
import CryptoCurrencyRouter from './cryptoCurrencyRouter';
import SavingsAccountRouter from './savingsAccountRouter';
import ContractTimeDepositRouter from './contractTimeDepositRouter';
import DebitCardRouter from './debitCardRouter';
import TransferRouter from './transferRouter';
// import JointAccountRouter from './jointAccountRouter';
import InvitationRouter from './invitationRouter';
import VerificationRouter from './verificationRouter';
import DebitCardRequestRouter from './debitCardRequestRouter';
import DebitCardRequestCherryVisa01Router from './debitCardRequestCherryVisa01Router';
import DebitCardRenewalRequestCherryVisa01Router from './debitCardRenewalRequestCherryVisa01Router';
import DebitCardRenewalCancelRequestCherryVisa01Router from './debitCardRenewalCancelRequestCherryVisa01Router';
import SettingsRouter from './settingsRouter';
import ManualRouter from './manualRouter';
import ErrorRouter from './errorRouter';
import FrontPageRouter from './frontPageRouter';

// Constant
import {
  ROUTE_PREFIX_ASSET,
  ROUTE_PREFIX_CRYPTO_CURRENCIES,
  ROUTE_PREFIX_DEBIT_CARD,
  ROUTE_PREFIX_SAVINGS_ACCOUNT,
  ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT,
  ROUTE_PREFIX_TRANSFER,
  // ROUTE_PREFIX_JOINT_ACCOUNT,
  ROUTE_PREFIX_INVITATION,
  ROUTE_PREFIX_VERIFICATION,
  ROUTE_PREFIX_SETTINGS,
  ROUTE_PREFIX_MANUAL,
  ROUTE_PREFIX_ERROR,
  ROUTE_PREFIX_DEBIT_CARD_REQUEST,
  ROUTE_PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01,
  ROUTE_PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01,
  ROUTE_PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01,
} from '../config/constants/routing';

const RootRouter: FC = () => {
  return (
    <MaintenanceProvider>
      <Switch>
        <Route path={ROUTE_PREFIX_ASSET} component={AssetRouter} />
        <Route
          path={ROUTE_PREFIX_CRYPTO_CURRENCIES}
          component={CryptoCurrencyRouter}
        />
        <Route
          path={ROUTE_PREFIX_SAVINGS_ACCOUNT}
          component={SavingsAccountRouter}
        />
        <Route
          path={ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT}
          component={ContractTimeDepositRouter}
        />
        <Route path={ROUTE_PREFIX_DEBIT_CARD} component={DebitCardRouter} />
        <Route path={ROUTE_PREFIX_TRANSFER} component={TransferRouter} />
        {/* <Route
          path={ROUTE_PREFIX_JOINT_ACCOUNT}
          component={JointAccountRouter}
        /> */}
        <Route path={ROUTE_PREFIX_INVITATION} component={InvitationRouter} />
        <Route path={ROUTE_PREFIX_SETTINGS} component={SettingsRouter} />
        <Route
          path={ROUTE_PREFIX_VERIFICATION}
          component={VerificationRouter}
        />
        <Route path={ROUTE_PREFIX_ERROR} component={ErrorRouter} />
        <Route
          path={ROUTE_PREFIX_DEBIT_CARD_REQUEST}
          component={DebitCardRequestRouter}
        />
        <Route
          path={ROUTE_PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}
          component={DebitCardRequestCherryVisa01Router}
        />
        <Route
          path={ROUTE_PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}
          component={DebitCardRenewalRequestCherryVisa01Router}
        />
        <Route
          path={ROUTE_PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}
          component={DebitCardRenewalCancelRequestCherryVisa01Router}
        />
        <Route path={ROUTE_PREFIX_MANUAL} component={ManualRouter} />
        <Route component={FrontPageRouter} />
      </Switch>
    </MaintenanceProvider>
  );
};

export default RootRouter;

import React from 'react';

export interface DebitCardIssuanceFeePaymentModalContextProps {
  showModal: () => void;
  hideModal: () => void;
}

const DebitCardIssuanceFeePaymentModalContext = React.createContext<
  DebitCardIssuanceFeePaymentModalContextProps
>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export default DebitCardIssuanceFeePaymentModalContext;

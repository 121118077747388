import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  z-index: 200;
  width: ${330 / 16}rem;
  height: auto;
  min-height: 100vh;
  padding-bottom: ${400 / 60}rem;
  background-color: #212f41;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media print {
    display: none;
  }
`;

export const LogoImageContainer = styled.div`
  flex: ${164 / 960};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MenuListContainer = styled.div`
  flex: ${796 / 960};
`;

export const LogoImageWrap = styled.div`
  width: 175.48px;
  height: 25.6px;
  // width: ${175.48 / 16}rem;
  // height: ${25.6 / 16}rem;
`;

import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { throttle } from 'lodash';
import { DisabledButton, SolidButton } from './Button';

export interface DangerButtonProps {
  children: React.ReactNode;
  height?: string | number;
  sizeProgress?: number;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  isLoading?: boolean;
  theme?: {
    borderRadius?: string | number;
  };
  width?: string | number;
}

const DangerButton: FC<DangerButtonProps> = ({
  children,
  width = '100%',
  height = '100%',
  sizeProgress = 30,
  disabled = false,
  isLoading = false,
  onClick = () => undefined,
  theme,
}: DangerButtonProps) => {
  if (disabled)
    return (
      <DisabledButton
        disabled={disabled}
        height={height}
        theme={theme}
        width={width}
      >
        {children}
      </DisabledButton>
    );

  const throttledOnClick = throttle((e) => onClick(e), 1500);

  return (
    <SolidButton
      height={height}
      onClick={throttledOnClick}
      theme={theme}
      width={width}
      disabled={isLoading}
    >
      {(() => {
        if (isLoading)
          return <CircularProgress size={sizeProgress} color="primary" />;
        return <>{children}</>;
      })()}
    </SolidButton>
  );
};

export default DangerButton;

import {
  convertMoneyFormat,
  getSymbolFromCurrency,
} from 'utils/helpers/currencyHelper';

// Constant
import { DEBIT_CARD_ISSUANCE_FEE } from 'config/constants/business';

// Type
import { FiatCurrencyType } from 'types/FiatCurrency.type';
import { TypeOfT } from 'types/typeof/Translation.type';

// DomainObject
import SavingsAccount from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';

const ORGANISM_ID = `organisms.table.TableCardIssuanceFeePayment`;

class TableCardIssuanceFeePaymentMapper {
  static toTableData = (savingsAccount: SavingsAccount, t: TypeOfT) => {
    const { balance, currency } = savingsAccount.getProps();

    const currencySymbol = getSymbolFromCurrency(currency);

    return [
      {
        header: t(`${ORGANISM_ID}.th.payFrom`),
        data: t(`${ORGANISM_ID}.td.savingsAccount`, {
          savingsAccount: savingsAccount.getFormattedAccountNumber(),
        }),
      },
      {
        header: t(`${ORGANISM_ID}.th.balance`),
        data: `${currencySymbol} ${convertMoneyFormat(balance, currency)}`,
      },
      {
        header: t(`${ORGANISM_ID}.th.issuanceFee`),
        data: `${currencySymbol} ${convertMoneyFormat(
          DEBIT_CARD_ISSUANCE_FEE,
          FiatCurrencyType.USD,
        )}`,
      },
    ];
  };
}

export default TableCardIssuanceFeePaymentMapper;

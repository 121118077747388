import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { ContainerBoxStyle } from 'components/organisms/modal/DebitCardShippingInformationModal/style';

// Component
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardShippingInformationModalLayoutProps {
  children: React.ReactElement[];
}

const DebitCardShippingInformationModalLayout: FC<DebitCardShippingInformationModalLayoutProps> = ({
  children,
}: DebitCardShippingInformationModalLayoutProps) => {
  const elements = ['title', 'table', 'button'];
  const [title, table, button] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      width={1200}
      height="auto"
      padding={50}
      theme={ContainerBoxStyle}
    >
      {title}
      <Margin top={60} />
      {table}
      <Margin top={60} />
      {button}
    </FlexBoxColumn>
  );
};
export default DebitCardShippingInformationModalLayout;

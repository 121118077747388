import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Component
import DebitCardRequestStatusCard from 'components/organisms/card/verification/DebitCardRequestStatusCard';

// DomainObject
import DebitCardRequestCherryVisa01 from 'domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01';
import DebitCardIssuanceFeePaymentModalContext from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalContext';
import CardShippingInformationModalContext from 'components/organisms/modal/CardShippingInformationModal/CardShippingInformationModalContext';

// Mapper
import DebitCardRequestCherryVisa01StepsMapper from 'components/organisms/list/DebitCardRequestCherryVisa01StatusList/mapper/DebitCardRequestCherryVisa01StepMapper/DebitCardRequestCherryVisa01StepMapper';

export type DebitCardRequestCherryVisa01StatusListProps = {
  debitCardRequestCherryVisa01?: DebitCardRequestCherryVisa01;
};

const DebitCardRequestCherryVisa01StatusList: FC<DebitCardRequestCherryVisa01StatusListProps> = ({
  debitCardRequestCherryVisa01,
}: DebitCardRequestCherryVisa01StatusListProps) => {
  const history = useHistory();

  const { showModal: showCardShippingInformationModal } = useContext(
    CardShippingInformationModalContext,
  );
  const { showModal: showCardIssuanceFeePaymentModal } = useContext(
    DebitCardIssuanceFeePaymentModalContext,
  );

  const debitCardRequestSteps = useMemo(
    () =>
      !debitCardRequestCherryVisa01
        ? []
        : DebitCardRequestCherryVisa01StepsMapper.toSteps(
            debitCardRequestCherryVisa01,
            history,
            showCardShippingInformationModal,
            showCardIssuanceFeePaymentModal,
          ),
    [
      debitCardRequestCherryVisa01,
      history,
      showCardShippingInformationModal,
      showCardIssuanceFeePaymentModal,
    ],
  );

  return (
    <>
      {debitCardRequestSteps.map((debitCardRequestStep, i) => {
        return (
          <div key={`status${i}`} style={{ marginBottom: `${41 / 16}rem` }}>
            <DebitCardRequestStatusCard
              order={i + 1}
              debitCardRequestStepType={
                debitCardRequestStep.debitCardRequestStepType
              }
              status={debitCardRequestStep.status}
              onClick={
                debitCardRequestStep.onClick
                  ? debitCardRequestStep.onClick
                  : () => undefined
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default DebitCardRequestCherryVisa01StatusList;

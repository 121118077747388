// IRepository
import { IDebitCardRenewalCancelRequestCherryVisa01QueryRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01Repository';

class DebitCardRenewalCancelRequestCherryVisa01QueryService {
  private debitCardRenewalCancelRequestCherryVisa01QueryRepository: IDebitCardRenewalCancelRequestCherryVisa01QueryRepository;

  constructor(
    debitCardRenewalCancelRequestCherryVisa01QueryRepository: IDebitCardRenewalCancelRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRenewalCancelRequestCherryVisa01QueryRepository = debitCardRenewalCancelRequestCherryVisa01QueryRepository;
  }

  public findById = async (id: string) => {
    const debitCardRenewalCancelRequest = await this.debitCardRenewalCancelRequestCherryVisa01QueryRepository.findById(
      id,
    );

    return debitCardRenewalCancelRequest;
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01QueryService;

import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Type
import {
  DebitCardRenewalCancelRequestCherryVisa01StepStatusType,
  DebitCardRenewalCancelRequestCherryVisa01StepType,
} from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

// Style
import { ContainerStyle } from 'components/organisms/card/verification/DebitCardRenewalCancelRequestStatusCard/style';

// Layout Component
import {
  FlexBoxRow,
  FlexBoxColumn,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardRenewalCancelRequestStatusCardLayoutProps {
  children: React.ReactElement[];
  status?: DebitCardRenewalCancelRequestCherryVisa01StepStatusType;
  isShowSubtitle: boolean;
  debitCardRequestStepType: DebitCardRenewalCancelRequestCherryVisa01StepType;
}

const DebitCardRenewalCancelRequestStatusCardLayout: FC<DebitCardRenewalCancelRequestStatusCardLayoutProps> = ({
  children,
  isShowSubtitle,
}: DebitCardRenewalCancelRequestStatusCardLayoutProps) => {
  const elements = [
    'title',
    'subTitle',
    'statusBadge',
    'description',
    'button',
  ];
  const [
    title,
    subTitle,
    statusBadge,
    description,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow
      alignItems="center"
      justifyContent="center"
      theme={ContainerStyle}
      width={1170}
    >
      <FlexBoxRow alignItems="center" padding="30.5 0" width={944}>
        <FlexBoxColumn width={1300} paddingLeft={40.5}>
          <FlexBoxRow alignItems="center" height="auto">
            {title}
            <Margin left={22} />
            {statusBadge}
          </FlexBoxRow>
          {isShowSubtitle && (
            <>
              <Margin top={10} />
              {subTitle}
            </>
          )}
          <Margin top={10} />
          {description}
        </FlexBoxColumn>
      </FlexBoxRow>
      {button}
    </FlexBoxRow>
  );
};

export default DebitCardRenewalCancelRequestStatusCardLayout;

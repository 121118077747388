import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import Margin from 'components/atoms/utils/layout/Margin';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';

export interface DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayoutProps {
  children: React.ReactElement[];
  isLoading: boolean;
}

const DebitCardRenewalCancelRequestStatusTemplateLayout: FC<DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayoutProps> = ({
  children,
  isLoading,
}: DebitCardRenewalCancelRequestCherryVisa01StatusTemplateLayoutProps) => {
  const elements = ['header', 'loader', 'description', 'list'];
  const [header, loader, description, list] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn
      paddingLeft={85}
      paddingTop={`${(80 / 890) * 100}vh`}
      width={1255}
    >
      {header}
      <Margin top={26} />
      {description}
      <Margin top={74} />
      {isLoading && (
        <FlexBoxRow justifyContent="center" paddingTop="30">
          {loader}
        </FlexBoxRow>
      )}
      {list}
      <Margin top={150} />
    </FlexBoxColumn>
  );
};
export default DebitCardRenewalCancelRequestStatusTemplateLayout;

import React, { FC, useContext } from 'react';
import { useDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/useDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';

export type DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider: FC<DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProviderProps) => {
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    confirm,
  } = useDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase(
    debitCardRenewalRequestCherryVisa01,
  );

  return (
    <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext.Provider
      value={{
        state,
        confirm,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext.Provider>
  );
};
export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider;

// DomainObject
import DomainObjects from 'domain/interface/Iterator/DomainObjects';
import DebitCardRenewalCancelRequest from '../DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest';

class DebitCardRenewalCancelRequests extends DomainObjects<
  DebitCardRenewalCancelRequest
> {
  public filterByDebitCardId = (debitCardId: string) => {
    const filtered = this.items.filter(
      (debitCardRenewalCancelRequest) =>
        debitCardRenewalCancelRequest.getProps().debitCardId === debitCardId,
    );
    return new DebitCardRenewalCancelRequests(filtered);
  };
}

export default DebitCardRenewalCancelRequests;

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Style
import { TableVerticalLayout, TableVerticalStyle } from 'components/organisms/table/confirm/TableConfirmDebitCardRenewalRequestShippingAddress/style';

// Mapper
import TableConfirmDebitCardRenewalRequestShippingAddressMapper from 'components/organisms/table/confirm/TableConfirmDebitCardRenewalRequestShippingAddress/TableConfirmDebitCardRenewalRequestShippingAddressMapper';

// Component
import TableVertical from 'components/molecules/table/table/TableVertical';

const TableConfirmDebitCardRenewalRequestShippingAddress: FC = () => {
  const { t } = useTranslation();

  const { userAuthorized } = useContext(UserAuthorizedContext);

  const rows = useMemo(
    () =>
      userAuthorized && TableConfirmDebitCardRenewalRequestShippingAddressMapper.domainToTd(
        userAuthorized,
        t,
      ),
    [userAuthorized, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableConfirmDebitCardRenewalRequestShippingAddress;

import React, { FC, useContext, useMemo } from 'react';
import DebitCardCancellationFeePaymentModalContext from 'components/organisms/modal/DebitCardCancellationFeePaymentModal/DebitCardCancellationFeePaymentModalContext';
import DebitCardRenewalCancelRequestCherryVisa01StepsMapper from 'components/organisms/list/DebitCardRenewalCancelRequestCherryVisa01StatusList/mapper/DebitCardRenewalCancelRequestCherryVisa01StepMapper/DebitCardRenewalCancelRequestCherryVisa01StepMapper';

// Component
import DebitCardRequestStatusCard from 'components/organisms/card/verification/DebitCardRenewalCancelRequestStatusCard';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

export type DebitCardRenewalCancelRequestCherryVisa01StatusListProps = {
  debitCardRenewalCancelRequestCherryVisa01?: DebitCardRenewalCancelRequestCherryVisa01;
};

const DebitCardRenewalCancelRequestCherryVisa01StatusList: FC<DebitCardRenewalCancelRequestCherryVisa01StatusListProps> = ({
  debitCardRenewalCancelRequestCherryVisa01,
}: DebitCardRenewalCancelRequestCherryVisa01StatusListProps) => {
  const { showModal: showCardCancellationFeePaymentModal } = useContext(
    DebitCardCancellationFeePaymentModalContext,
  );

  const debitCardRenewalCancelRequestSteps = useMemo(
    () =>
      !debitCardRenewalCancelRequestCherryVisa01
        ? []
        : DebitCardRenewalCancelRequestCherryVisa01StepsMapper.toSteps(
            debitCardRenewalCancelRequestCherryVisa01,
            showCardCancellationFeePaymentModal,
          ),
    [
      debitCardRenewalCancelRequestCherryVisa01,
      showCardCancellationFeePaymentModal,
    ],
  );

  return (
    <>
      {debitCardRenewalCancelRequestSteps.map(
        (debitCardRenewalCancelRequestStep, i) => {
          return (
            <div key={`status${i}`} style={{ marginBottom: `${41 / 16}rem` }}>
              <DebitCardRequestStatusCard
                debitCardRequestStepType={
                  debitCardRenewalCancelRequestStep.debitCardRenewalCancelRequestStepType
                }
                status={debitCardRenewalCancelRequestStep.status}
                onClick={
                  debitCardRenewalCancelRequestStep.onClick
                    ? debitCardRenewalCancelRequestStep.onClick
                    : () => undefined
                }
                submissionDeadline={
                  debitCardRenewalCancelRequestStep.submissionDeadline
                }
              />
            </div>
          );
        },
      )}
    </>
  );
};

export default DebitCardRenewalCancelRequestCherryVisa01StatusList;

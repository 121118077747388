import React from 'react';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from './DebitCardRenewalRequestCherryVisa01';

export interface DebitCardRenewalRequestCherryVisa01ContextProps {
  debitCardRenewalRequestCherryVisa01?: DebitCardRenewalRequestCherryVisa01;
}

const DebitCardRenewalRequestCherryVisa01Context = React.createContext<
  DebitCardRenewalRequestCherryVisa01ContextProps
>({
  debitCardRenewalRequestCherryVisa01: undefined,
});

export default DebitCardRenewalRequestCherryVisa01Context;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// Type
import {
  DebitCardRenewalRequestProps,
  DebitCardRenewalRequestPropsFormat,
} from './DebitCardRenewalRequest.type';

// DomainObject
import DebitCardRenewalRequest from './DebitCardRenewalRequest';

class DebitCardRenewalRequestFactory {
  static create = (id: string, props: DebitCardRenewalRequestProps) => {
    const result = Joi.object(DebitCardRenewalRequestPropsFormat).validate({
      id,
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardRenewalRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new DebitCardRenewalRequest(id, props);
  };
}

export default DebitCardRenewalRequestFactory;

// IService
import IDebitCardRenewalCancelRequestCherryVisa01UpdateService from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01UpdateService';

// Service
import DebitCardRenewalCancelRequestCherryVisa01UpdateService from 'service/debitCard/debitCardRenewalCancelRequest/debitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01UpdateService';

// IRepository
import { IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalRequest from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest';

class DebitCardRenewalRequestCherryVisa01CancelConfirmUseCase {
  private debitCardRenewalCancelRequestCherryVisa01UpdateService: IDebitCardRenewalCancelRequestCherryVisa01UpdateService;

  constructor(
    debitCardRenewalCancelRequestCherryVisa01Repository: IDebitCardRenewalCancelRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRenewalCancelRequestCherryVisa01UpdateService = new DebitCardRenewalCancelRequestCherryVisa01UpdateService(
      debitCardRenewalCancelRequestCherryVisa01Repository,
    );
  }

  public cancel = async (debitCardRenewalRequest: DebitCardRenewalRequest) => {
    const updatedDebitCardRenewalCancelRequestCherryVisa01 = await this.debitCardRenewalCancelRequestCherryVisa01UpdateService.confirmCancel(
      debitCardRenewalRequest,
    );

    return updatedDebitCardRenewalCancelRequestCherryVisa01;
  };
}

export default DebitCardRenewalRequestCherryVisa01CancelConfirmUseCase;

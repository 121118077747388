import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';

// Type
import { DescriptionStyle } from 'components/templates/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01StatusTemplate/style';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import { TextSecondary } from 'components/atoms/text2/Text2';
import CircleSpinLoader from 'components/atoms/spinner/CircleSpinLoader';
import DebitCardRenewalRequestCherryVisa01StatusList from 'components/organisms/list/DebitCardRenewalRequestCherryVisa01StatusList';
import DebitCardRenewalRequestCherryVisa01StatusTemplateLayout from 'components/templates/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01StatusTemplate/DebitCardRenewalRequestCherryVisa01StatusTemplateLayout';

const TEMPLATE_ID = `templates.debitCards.DebitCardRenewalRequestCherryVisa01StatusTemplate`;

const DebitCardRenewalRequestCherryVisa01StatusTemplate: FC = () => {
  const { t } = useTranslation();

  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  return (
    <DebitCardRenewalRequestCherryVisa01StatusTemplateLayout
      isLoading={!debitCardRenewalRequestCherryVisa01}
    >
      <PageTitle key="header">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <CircleSpinLoader key="loader" />
      <TextSecondary key="description" theme={DescriptionStyle}>
        {t(`${TEMPLATE_ID}.description`)}
      </TextSecondary>
      <DebitCardRenewalRequestCherryVisa01StatusList
        key="list"
        debitCardRenewalRequestCherryVisa01={
          debitCardRenewalRequestCherryVisa01
        }
      />
    </DebitCardRenewalRequestCherryVisa01StatusTemplateLayout>
  );
};

export default DebitCardRenewalRequestCherryVisa01StatusTemplate;

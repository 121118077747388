import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRequestCancelRenewalCherryVisa01StatusViewProvider/useDebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase';
import DebitCardRenewalCancelRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalCancelRequestCherryVisa01StatusViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalCancelRequestCherryVisa01StatusViewProvider: FC<DebitCardRenewalCancelRequestCherryVisa01StatusViewProviderProps> = ({
  children,
}: DebitCardRenewalCancelRequestCherryVisa01StatusViewProviderProps) => {
  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { id } = useParams<{ id: string }>();

  const {
    state,
    setState,
    debitCardRenewalCancelRequestCherryVisa01,
  } = useDebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase(
    id,
    userAuthorized,
  );

  return (
    <DebitCardRenewalCancelRequestCherryVisa01Context.Provider
      value={{ debitCardRenewalCancelRequestCherryVisa01 }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalCancelRequestCherryVisa01Context.Provider>
  );
};

export default DebitCardRenewalCancelRequestCherryVisa01StatusViewProvider;

import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardRenewalNotificationLayoutProps {
  children: React.ReactElement[];
  isInCancellationProcess: boolean;
  isInRenewalProcess: boolean;
  canCancelCard: boolean;
}

const DebitCardRenewalNotificationLayout: FC<DebitCardRenewalNotificationLayoutProps> = ({
  children,
  isInCancellationProcess,
  isInRenewalProcess,
  canCancelCard,
}: DebitCardRenewalNotificationLayoutProps) => {
  const elements = [
    'description',
    'textAttentionButtons',
    'buttonProceedRenewal',
    'buttonStartRenewal',
    'buttonProceedCancellation',
    'buttonCancelCard',
  ];
  const [
    description,
    textAttentionButtons,
    buttonProceedRenewal,
    buttonStartRenewal,
    buttonProceedCancellation,
    buttonCancelCard,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      <FlexBoxColumn>
        {description}
        &nbsp;
        {textAttentionButtons}
      </FlexBoxColumn>
      <Margin top={35} />
      <FlexBoxRow>
        {isInRenewalProcess && buttonProceedRenewal}
        {!isInRenewalProcess && !isInCancellationProcess && (
          <>
            {buttonStartRenewal}
            <Margin left={30} />
            {canCancelCard && buttonCancelCard}
          </>
        )}
        {isInRenewalProcess && <Margin left={30} />}
        {isInCancellationProcess && buttonProceedCancellation}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default DebitCardRenewalNotificationLayout;

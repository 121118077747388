// IUseCase
import IDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/IDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase';

// IService

// Service
import DebitCardRenewalRequestCherryVisa01UpdateService from 'service/debitCard/debitCardRenewalRequest/debitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01UpdateService';

// IRepository
import { IDebitCardRenewalRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';
import IDebitCardRenewalRequestCherryVisa01UpdateService from './IDebitCardRenewalRequestCherryVisa01UpdateService';

class DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase
  implements IDebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase {
  private debitCardRenewalRequestCherryVisa01UpdateService: IDebitCardRenewalRequestCherryVisa01UpdateService;

  constructor(
    debitCardRenewalRequestCherryVisa01Repository: IDebitCardRenewalRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRenewalRequestCherryVisa01UpdateService = new DebitCardRenewalRequestCherryVisa01UpdateService(
      debitCardRenewalRequestCherryVisa01Repository,
    );
  }

  public confirm = async (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    const updatedDebitCardRenewalRequestCherryVisa01 = await this.debitCardRenewalRequestCherryVisa01UpdateService.confirmShippingAddress(
      debitCardRenewalRequestCherryVisa01,
    );

    return updatedDebitCardRenewalRequestCherryVisa01;
  };
}

export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmUseCase;

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { updateDebitCardRenewalCancelRequestCherryVisa01FeePaidById } from 'redux/reducers/debitCardRenewalCancelRequestCherryVisa01sReducer';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from 'utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import DebitCardRenewalCancelRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01Functions';

// UseCase
import DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase = (
  debitCardRenewalCancelRequestCherryVisa01:
    | DebitCardRenewalCancelRequestCherryVisa01
    | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  /**
   *
   *  Repository
   *
   */
  const debitCardRenewalCancelRequestCherryVisa01Functions = new DebitCardRenewalCancelRequestCherryVisa01Functions();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeUseCase(
    debitCardRenewalCancelRequestCherryVisa01Functions,
  );

  /**
   *
   *  Method
   *
   */
  const payCancellationFee = useCallback(
    async (callback?: () => void) => {
      if (!debitCardRenewalCancelRequestCherryVisa01) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        await useCase.payCancellationFee(
          debitCardRenewalCancelRequestCherryVisa01,
        );

        dispatch(
          updateDebitCardRenewalCancelRequestCherryVisa01FeePaidById({
            debitCardRenewalCancelRequestCherryVisa01,
            isCancelFeePaid: true,
          }),
        );
        setState(SUCCESS);

        if (callback) callback();
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized, debitCardRenewalCancelRequestCherryVisa01],
  );

  return {
    state,
    setState,
    payCancellationFee,
  };
};

import { TypeOfT } from 'types/typeof/Translation.type';

// ValueObject
import DeliveryTrackingInformation from 'value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation';

const ORGANISM_ID = `organisms.table.TableCardDeliveryStatus`;

class TableCardDeliveryStatusMapper {
  static toTableData = (
    t: TypeOfT,
    deliveryTrackingInformation?: DeliveryTrackingInformation,
  ) => {
    if (!deliveryTrackingInformation)
      return TableCardDeliveryStatusMapper.alt(t);

    const props = deliveryTrackingInformation.getProps();

    return [
      {
        header: t(`${ORGANISM_ID}.th.carrier`),
        data: props.carrier,
      },
      {
        header: t(`${ORGANISM_ID}.th.trackingNumber`),
        data: props.trackingNumber,
      },
      {
        header: t(`${ORGANISM_ID}.th.trackingUrl`),
        data: props.trackingUrl,
      },
    ];
  };

  static alt = (t: TypeOfT) => {
    return [
      {
        header: t(`${ORGANISM_ID}.th.carrier`),
        data: '',
      },
      {
        header: t(`${ORGANISM_ID}.th.trackingNumber`),
        data: '',
      },
      {
        header: t(`${ORGANISM_ID}.th.trackingUrl`),
        data: '',
      },
    ];
  };
}

export default TableCardDeliveryStatusMapper;

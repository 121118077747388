// Type
import {
  DebitCardRenewalRequestCherryVisa01StatusType,
  DebitCardRenewalRequestCherryVisa01StepStatusType,
  DebitCardRenewalRequestCherryVisa01StepType,
} from 'types/DebitCardRenewalRequestCherryVisa01.type';

export const debitCardRenewalRequestCherryVisa01StatusMapper = {
  [DebitCardRenewalRequestCherryVisa01StatusType.waitingForShipment]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM,
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRenewalRequestCherryVisa01StatusType.deliveredBeforePayment]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.shipped,
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.onDelivery,
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.initial,
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRenewalRequestCherryVisa01StatusType.waitingProofOfReceipt]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.shipped,
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.onDelivery,
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.paid,
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.initial,
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRenewalRequestCherryVisa01StatusType.proofOfReceiptWaitingReview]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.shipped,
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.delivered,
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.paid,
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.submitted,
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM,
  },
  [DebitCardRenewalRequestCherryVisa01StatusType.proofOfReceiptRejected]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.shipped,
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.delivered,
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.paid,
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.rejected,
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.disabled,
  },
  [DebitCardRenewalRequestCherryVisa01StatusType.active]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed,
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.shipped,
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.delivered,
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.paid,
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM,
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]:
      DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM,
  },
} as {
  [key in DebitCardRenewalRequestCherryVisa01StatusType]: {
    [DebitCardRenewalRequestCherryVisa01StepType.shippingInformation]: DebitCardRenewalRequestCherryVisa01StepStatusType;
    [DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment]: DebitCardRenewalRequestCherryVisa01StepStatusType;
    [DebitCardRenewalRequestCherryVisa01StepType.cardDelivery]: DebitCardRenewalRequestCherryVisa01StepStatusType;
    [DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt]: DebitCardRenewalRequestCherryVisa01StepStatusType;
    [DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview]: DebitCardRenewalRequestCherryVisa01StepStatusType;
    [DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee]: DebitCardRenewalRequestCherryVisa01StepStatusType;
  };
};

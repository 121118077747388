import Joi from '@hapi/joi';
import {
  DebitCardRenewalCancelRequestProps,
  DebitCardRenewalCancelRequestPropsFormat,
  DebitCardRenewalCancelRequestRawDataType,
} from '../../DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest.type';

export interface DebitCardRenewalCancelRequestCherryVisa01Props
  extends DebitCardRenewalCancelRequestProps {
  customerId: string;

  isCancelFeePaid: boolean;
}

export type DebitCardRenewalCancelRequestCherryVisa01RawDataType = DebitCardRenewalCancelRequestRawDataType & {
  props: DebitCardRenewalCancelRequestCherryVisa01Props & {
    customerId: string;

    isCancelFeePaid: boolean;
  };
};

export const DebitCardRenewalCancelRequestCherryVisa01PropsFormat = {
  ...DebitCardRenewalCancelRequestPropsFormat,

  customerId: Joi.string().required().allow(''),

  isCancelFeePaid: Joi.boolean().required(),
};

import React, { FC } from 'react';
import ApprovedImage from 'assets/images/approved.png';
import SubmittedImage from 'assets/images/submitted.png';
import ReviewImage from 'assets/images/reviewing.png';
import RejectedImage from 'assets/images/rejected.png';

// Type
import { DebitCardRenewalRequestCherryVisa01StepStatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

export interface DebitCardRenewalRequestStatusIconProps {
  status?: DebitCardRenewalRequestCherryVisa01StepStatusType;
  width?: string | number;
  height?: string | number;
}

const DebitCardRenewalRequestStatusIcon: FC<DebitCardRenewalRequestStatusIconProps> = ({
  status,
  width = '100%',
  height = '100%',
}: DebitCardRenewalRequestStatusIconProps) => {
  switch (status) {
    case DebitCardRenewalRequestCherryVisa01StepStatusType.confirmed:
    case DebitCardRenewalRequestCherryVisa01StepStatusType.shipped:
    case DebitCardRenewalRequestCherryVisa01StepStatusType.paid:
    case DebitCardRenewalRequestCherryVisa01StepStatusType.delivered:
      return <img src={ApprovedImage} alt="✓" width={width} height={height} />;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.waitingFPBM:
      return <img src={ReviewImage} alt="-" width={width} height={height} />;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.submitted:
      return (
        <img src={SubmittedImage} alt="|_" width={width} height={height} />
      );
    case DebitCardRenewalRequestCherryVisa01StepStatusType.rejected:
      return <img src={RejectedImage} alt="x" width={width} height={height} />;
    default:
      return <></>;
  }
};
export default DebitCardRenewalRequestStatusIcon;

import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router';
import DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal/DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext';
import DebitCardRenewalRequestContext from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequestContext';
import DebitCardRenewalRequestCherryVisa01CancelConfirmProvider from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01CancelConfirmProvider';

// Component
import DebitCardRenewalRequestCherryVisa01CancelConfirmModal from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal';

export type DebitCardRenewalRequestCherryVisa01CancelConfirmModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01CancelConfirmModalProvider: FC<DebitCardRenewalRequestCherryVisa01CancelConfirmModalProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01CancelConfirmModalProviderProps) => {
  const history = useHistory();
  const { debitCardRenewalRequest } = useContext(
    DebitCardRenewalRequestContext,
  );

  const [showModal, hideModal] = useModal(
    () => (
      <DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRenewalRequestContext.Provider
          value={{ debitCardRenewalRequest }}
        >
          <DebitCardRenewalRequestCherryVisa01CancelConfirmProvider>
            <DebitCardRenewalRequestCherryVisa01CancelConfirmModal
              history={history}
            />
          </DebitCardRenewalRequestCherryVisa01CancelConfirmProvider>
        </DebitCardRenewalRequestContext.Provider>
      </DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext.Provider>
    ),
    [debitCardRenewalRequest, history],
  );

  return (
    <DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext.Provider>
  );
};
export default DebitCardRenewalRequestCherryVisa01CancelConfirmModalProvider;

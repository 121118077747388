// React
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import DebitCardIssuanceFeePaymentModalContext from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalContext';
import SavingsAccountContext from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Constant
import { DEBIT_CARD_ISSUANCE_FEE } from 'config/constants/business';
import { IS_LOADING } from 'config/constants/requestState';

// Style
import {
  CancelButtonTextStyle,
  contentStyle,
  ErrorMessageStyle,
  MessageStyle,
  overlayStyle,
  TitleStyle,
} from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/style';

// Components
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import {
  CancelButton,
  DefaultButtonTextStyle,
  SubmitButton,
} from 'components/atoms/button/Button2';
import CircleSpinLoader from 'components/atoms/spinner/CircleSpinLoader';
import TableCardIssuanceFeePayment from 'components/organisms/table/confirm/TableCardIssuanceFeePayment';
import DebitCardIssuanceFeePaymentModalLayout from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalLayout';

const ORGANISM_ID = `organisms.modal.DebitCardIssuanceFeePaymentModal`;

interface DebitCardIssuanceFeePaymentModalProps {
  state: string;
  onSubmit: () => void;
}

const DebitCardIssuanceFeePaymentModal = ({
  state,
  onSubmit,
}: DebitCardIssuanceFeePaymentModalProps) => {
  const { t } = useTranslation();
  const { hideModal } = useContext(DebitCardIssuanceFeePaymentModalContext);

  const { savingsAccount } = useContext(SavingsAccountContext);

  const hasSufficientBalance = useMemo(() => {
    const balance = savingsAccount?.getProps().balance;
    return balance && balance >= DEBIT_CARD_ISSUANCE_FEE;
  }, [savingsAccount]);

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <DebitCardIssuanceFeePaymentModalLayout isLoading={!savingsAccount}>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TableCardIssuanceFeePayment key="table" />
        <CircleSpinLoader key="loader" />
        <TextSecondary
          key="message"
          theme={hasSufficientBalance ? MessageStyle : ErrorMessageStyle}
        >
          {t(
            hasSufficientBalance
              ? `${ORGANISM_ID}.message`
              : `${ORGANISM_ID}.errorMessage`,
          )}
        </TextSecondary>
        <SubmitButton
          key="submitButton"
          disabled={!hasSufficientBalance}
          isLoading={state === IS_LOADING}
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          sizeProgress={20}
          onClick={onSubmit}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.yes`)}
          </TextPrimary>
        </SubmitButton>
        <CancelButton
          key="cancelButton"
          theme={{ borderRadius: 30 }}
          disabled={state === IS_LOADING}
          height={60}
          width={200}
          onClick={hideModal}
        >
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.cancel`)}
          </TextPrimary>
        </CancelButton>
      </DebitCardIssuanceFeePaymentModalLayout>
    </ReactModal>
  );
};

export default DebitCardIssuanceFeePaymentModal;

import React, { FC } from 'react';
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import CherryVisa01ProofOfReceiptTemplate from 'components/templates/debitCardRenewalRequest/CherryVisa01ProofOfReceiptTemplate';

const DebitCardRenewalRequestCherryVisa01ProofOfReceiptPage: FC = () => {
  return (
    <DashboardTemplate>
      <CherryVisa01ProofOfReceiptTemplate />
    </DashboardTemplate>
  );
};

export default DebitCardRenewalRequestCherryVisa01ProofOfReceiptPage;

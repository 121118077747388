import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Lib
import { isEmpty } from 'lodash';
import { convertRem } from 'components/atoms/utils/helper/helper';

// Type
import { DebitCardRenewalRequestCherryVisa01StepStatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// LayoutComponent
import { FlexBoxRow } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

// Component
import { TextSecondary, TextThemeProps } from 'components/atoms/text2/Text2';
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
import Display from 'components/atoms/div/Display';

export interface DebitCardRenewalRequestStatusSquareButtonProps {
  height: string | number;
  marginBetween: string | number;
  onClick?: () => void;
  status: DebitCardRenewalRequestCherryVisa01StepStatusType;
  theme: {
    text: TextThemeProps;
    icon: {
      color: string;
      size: string | number;
    };
  };
  width: string | number;
}

const DebitCardRenewalRequestStatusSquareButton: FC<DebitCardRenewalRequestStatusSquareButtonProps> = ({
  height,
  marginBetween,
  onClick = () => undefined,
  status,
  theme,
  width,
}: DebitCardRenewalRequestStatusSquareButtonProps) => {
  const { t } = useTranslation();
  let [buttonText, backgroundColor] = ['', ''];

  switch (status) {
    case DebitCardRenewalRequestCherryVisa01StepStatusType.initial:
      [buttonText, backgroundColor] = [
        t(`atoms.button.debitCardRenewalRequestStepStatus.${status}`),
        '#15609B',
      ];
      break;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.rejected:
      [buttonText, backgroundColor] = [
        t(`atoms.button.debitCardRenewalRequestStepStatus.${status}`),
        '#299ADF',
      ];
      break;
    case DebitCardRenewalRequestCherryVisa01StepStatusType.onDelivery:
      [buttonText, backgroundColor] = [
        t(`atoms.button.debitCardRenewalRequestStepStatus.${status}`),
        '#2C8A9D',
      ];
      break;
    default:
      [buttonText, backgroundColor] = ['', ''];
  }

  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        height: convertRem(height),
        outline: 'none',
        width: convertRem(width),
      }}
    >
      <FlexBoxRow
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor }}
      >
        <FlexBoxRow height="auto" width="auto" alignItems="center">
          <TextSecondary theme={theme.text}>{buttonText}</TextSecondary>
          <Margin left={marginBetween} />
          <Display
            isDisplay={!isEmpty(buttonText)}
            style={{
              paddingTop: `${5 / 16}rem`,
            }}
          >
            <ArrowIcon
              type="forward"
              color={theme.icon.color}
              size={convertRem(theme.icon.size)}
            />
          </Display>
        </FlexBoxRow>
      </FlexBoxRow>
    </button>
  );
};

export default DebitCardRenewalRequestStatusSquareButton;

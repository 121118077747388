import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import Margin from 'components/atoms/utils/layout/Margin';
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';

export interface DebitCardRequestStepTemplateLayoutProps {
  children: React.ReactElement[];
  isLoading: boolean;
}

const DebitCardRequestStepTemplateLayout: FC<DebitCardRequestStepTemplateLayoutProps> = ({
  children,
  isLoading,
}: DebitCardRequestStepTemplateLayoutProps) => {
  const elements = ['header', 'title', 'link', 'loader', 'childNode'];
  const [header, title, link, loader, childNode] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      {header}
      <Margin top={80} />
      <FlexBoxColumn paddingLeft={85}>
        <FlexBoxRow width={1195} justifyContent="space-between">
          {title}
          {link}
        </FlexBoxRow>
        {isLoading && (
          <FlexBoxRow width={1195} justifyContent="center" paddingTop={100}>
            {loader}
          </FlexBoxRow>
        )}
        <Margin top={46} />
        {!isLoading && childNode}
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
export default DebitCardRequestStepTemplateLayout;

// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import { DebitCardRenewalCancelRequestRawDataType } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest.type';

export interface DebitCardRenewalCancelRequestsState {
  list: DebitCardRenewalCancelRequestRawDataType[] | undefined;
}

// eslint-disable-next-line
const initialState: any = {
  list: undefined,
};

const debitCardRenewalCancelRequestsSlice = createSlice({
  name: 'debitCardRenewalCancelRequests',
  initialState,
  reducers: {
    setDebitCardRenewalCancelRequests: (
      state: DebitCardRenewalCancelRequestsState,
      action: PayloadAction<{
        debitCardRenewalCancelRequests: DebitCardRenewalCancelRequestRawDataType[];
      }>,
    ) => ({
      ...state,
      list: action.payload.debitCardRenewalCancelRequests,
    }),
  },
});

export const {
  setDebitCardRenewalCancelRequests,
} = debitCardRenewalCancelRequestsSlice.actions;
export default debitCardRenewalCancelRequestsSlice.reducer;

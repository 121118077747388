import { useSelector } from 'react-redux';

// Type
import { GlobalStateType } from 'redux/store';

// Domain Object
import DebitCardRenewalRequestCherryVisa01 from './DebitCardRenewalRequestCherryVisa01';
import DebitCardRenewalRequestCherryVisa01Factory from './DebitCardRenewalRequestCherryVisa01Factory';

export const useDebitCardRenewalRequestCherryVisa01 = (
  id: string,
): DebitCardRenewalRequestCherryVisa01 | undefined => {
  const state = useSelector(
    (globalState: GlobalStateType) =>
      globalState.debitCardRenewalRequestCherryVisa01sStore,
  );
  return (
    state.byId &&
    state.byId[id] &&
    DebitCardRenewalRequestCherryVisa01Factory.createFromRawData(state.byId[id])
  );
};

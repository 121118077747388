// Type
import { DebitCardRenewalCancelRequestRawDataType } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest.type';
import { DebitCardRenewalCancelRequestsCherryVisa01RawDataType } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestsCherryVisa01/DebitCardRenewalCancelRequestsCherryVisa01.type';

class DebitCardRenewalCancelRequestsFactory {
  static createRawDataFromDebitCardRenewalCancelRequestsCherryVisa01RawData = (
    debitCardRenewalCancelRequestsCherryVisa01RawData: DebitCardRenewalCancelRequestsCherryVisa01RawDataType,
  ): DebitCardRenewalCancelRequestRawDataType[] =>
    debitCardRenewalCancelRequestsCherryVisa01RawData.map(({ id, props }) => ({
      id,
      props: {
        status: props.status,
        debitCardId: props.debitCardId,
        userId: props.userId,
        expiredYearMonth: props.expiredYearMonth,
        createdAt: props.createdAt,
        updatedAt: props.updatedAt,
      },
    }));
}

export default DebitCardRenewalCancelRequestsFactory;

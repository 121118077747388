import React, { FC, useContext } from 'react';
import { useDebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider/useDebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import SavingsAccountContext from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider: FC<DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProviderProps) => {
  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    savingsAccount,
  } = useDebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase(
    debitCardRenewalRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <SavingsAccountContext.Provider value={{ savingsAccount }}>
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </SavingsAccountContext.Provider>
  );
};

export default DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider;

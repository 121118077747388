import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import DebitCardShippingInformationModalContext from 'components/organisms/modal/DebitCardShippingInformationModal/DebitCardShippingInformationModalContext';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';

// Component
import DebitCardShippingInformationModal from 'components/organisms/modal/DebitCardShippingInformationModal';

export type DebitCardRenewalShippingInformationModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalShippingInformationModalProvider: FC<DebitCardRenewalShippingInformationModalProviderProps> = ({
  children,
}: DebitCardRenewalShippingInformationModalProviderProps) => {
  const debitCardRenewalRequestCherryVisa01Context = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  const [showModal, hideModal] = useModal(
    () => (
      <DebitCardShippingInformationModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRenewalRequestCherryVisa01Context.Provider
          value={debitCardRenewalRequestCherryVisa01Context}
        >
          <DebitCardShippingInformationModal />
        </DebitCardRenewalRequestCherryVisa01Context.Provider>
      </DebitCardShippingInformationModalContext.Provider>
    ),
    [debitCardRenewalRequestCherryVisa01Context],
  );

  return (
    <DebitCardShippingInformationModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </DebitCardShippingInformationModalContext.Provider>
  );
};
export default DebitCardRenewalShippingInformationModalProvider;

import React, { FC, useContext } from 'react';
import { useDebitCardViewUseCase } from 'enhancers/useCase/debitCard/debitCard/DebitCardViewProvider/useDebitCardViewUseCase';
import { useDebitCardFeeFile } from 'enhancers/storage/files/useDebitCardFeeFile';
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';
import DebitCardOptionContext from 'components/molecules/selectBox/option/option/DebitCardOption/DebitCardOptionContext';
import DebitCardV2sContext from 'domain/debitCard/debitCardV2/DebitCardV2s/DebitCardV2sContext';
import PageRequestStateContext from 'enhancers/pageState/general/PageRequestState/PageRequestStateContext';
import SavingsAccountOptionContext from 'components/molecules/selectBox/option/option/SavingsAccountOption/SavingsAccountOptionContext';
import SavingsAccountsContext from 'domain/bankAccount/savingsAccount/SavingsAccounts/SavingsAccountsContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import LinksDebitCardContext from 'enhancers/storage/context/LinksDebitCardContext';
import DebitCardRenewalRequestContext from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequestContext';
import DebitCardRenewalCancelRequestContext from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequestContext';

export type DebitCardViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardViewProvider: FC<DebitCardViewProviderProps> = ({
  children,
}: DebitCardViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
    debitCards,
    debitCardOption,
    setDebitCardOption,
    debitCardRenewalRequest,
    debitCardRenewalCancelRequest,
  } = useDebitCardViewUseCase(userAuthorized);

  const { debitCardFeeFileUrl } = useDebitCardFeeFile();

  return (
    <PageRequestStateContext.Provider value={{ state, setState }}>
      <DebitCardV2sContext.Provider value={{ debitCards }}>
        <DebitCardOptionContext.Provider
          value={{
            debitCardOption,
            setDebitCardOption,
          }}
        >
          <SavingsAccountsContext.Provider value={{ savingsAccounts }}>
            <SavingsAccountOptionContext.Provider
              value={{
                savingsAccountOption,
                setSavingsAccountOption,
              }}
            >
              <LinksDebitCardContext.Provider
                value={{
                  debitCardFeeFileUrl,
                }}
              >
                <SystemErrorHandler state={state} setState={setState}>
                  <DebitCardRenewalRequestContext.Provider
                    value={{ debitCardRenewalRequest }}
                  >
                    <DebitCardRenewalCancelRequestContext.Provider
                      value={{ debitCardRenewalCancelRequest }}
                    >
                      {children}
                    </DebitCardRenewalCancelRequestContext.Provider>
                  </DebitCardRenewalRequestContext.Provider>
                </SystemErrorHandler>
              </LinksDebitCardContext.Provider>
            </SavingsAccountOptionContext.Provider>
          </SavingsAccountsContext.Provider>
        </DebitCardOptionContext.Provider>
      </DebitCardV2sContext.Provider>
    </PageRequestStateContext.Provider>
  );
};
export default DebitCardViewProvider;

import React, { FC } from 'react';
import ApprovedImage from 'assets/images/approved.png';
import InitialImage from 'assets/images/initial.svg';
import RejectedImage from 'assets/images/rejected.png';
import SubmittedImage from 'assets/images/submitted.png';

// Type
import { DebitCardRenewalCancelRequestCherryVisa01StepStatusType } from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

export interface DebitCardRenewalCancelRequestStatusIconProps {
  status?: DebitCardRenewalCancelRequestCherryVisa01StepStatusType;
  width?: string | number;
  height?: string | number;
}

const DebitCardRenewalCancelRequestStatusIcon: FC<DebitCardRenewalCancelRequestStatusIconProps> = ({
  status,
  width = '100%',
  height = '100%',
}: DebitCardRenewalCancelRequestStatusIconProps) => {
  switch (status) {
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.paid:
      return <img src={ApprovedImage} alt="✓" width={width} height={height} />;
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.notSubmitted:
      return <img src={InitialImage} alt="x" width={width} height={height} />;
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.submitted:
      return <img src={SubmittedImage} alt="x" width={width} height={height} />;
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.rejected:
      return <img src={RejectedImage} alt="x" width={width} height={height} />;
    default:
      return <></>;
  }
};
export default DebitCardRenewalCancelRequestStatusIcon;

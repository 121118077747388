import React, { FC } from 'react';
import DebitCardRenewalCancelRequestCherryVisa01StatusViewProvider from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRequestCancelRenewalCherryVisa01StatusViewProvider';
import DebitCardCancellationFeePaymentModalProvider from 'components/organisms/modal/DebitCardCancellationFeePaymentModal/DebitCardCancellationFeePaymentModalProvider';
import DebitCardRenewalCancelRequestCherryVisa01StatusPage from './DebitCardRenewalCancelRequestCherryVisa01StatusPage';

const EnhancedDebitCardRenewalCancelRequestCherryVisa01StatusPage: FC = () => {
  return (
    <DebitCardRenewalCancelRequestCherryVisa01StatusViewProvider>
      <DebitCardCancellationFeePaymentModalProvider>
        <DebitCardRenewalCancelRequestCherryVisa01StatusPage />
      </DebitCardCancellationFeePaymentModalProvider>
    </DebitCardRenewalCancelRequestCherryVisa01StatusViewProvider>
  );
};

export default EnhancedDebitCardRenewalCancelRequestCherryVisa01StatusPage;

// IService
import IDebitCardRenewalRenewalRequestQueryService from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/IDebitCardRenewalRequestQueryService';

// IRepository
import { IDebitCardRenewalRequestQueryRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/IDebitCardRenewalRequestRepository';

class DebitCardRenewalRequestQueryService
  implements IDebitCardRenewalRenewalRequestQueryService {
  private debitCardRenewalRequestQueryRepository: IDebitCardRenewalRequestQueryRepository;

  constructor(
    debitCardRenewalRequestQueryRepository: IDebitCardRenewalRequestQueryRepository,
  ) {
    this.debitCardRenewalRequestQueryRepository = debitCardRenewalRequestQueryRepository;
  }

  public findAllByUserId = async (userId: string) => {
    const debitCardRenewalRequests = await this.debitCardRenewalRequestQueryRepository.findAllByUserId(
      userId,
    );

    return debitCardRenewalRequests;
  };
}

export default DebitCardRenewalRequestQueryService;

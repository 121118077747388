import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { setDebitCardRenewalRequestCherryVisa01ById } from 'redux/reducers/debitCardRenewalRequestCherryVisa01sReducer';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// UseCase
import DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase';

// Repository
import DebitCardRenewalRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01Functions';

// DomainObject
import { useDebitCardRenewalRequestCherryVisa01 } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/useDebitCardRenewalRequestCherryVisa01';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase = (
  id: string,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  /* *
   *
   *  DomainObject
   *
   * */
  const debitCardRenewalRequestCherryVisa01 = useDebitCardRenewalRequestCherryVisa01(
    id,
  );

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRenewalRequestCherryVisa01Repository = new DebitCardRenewalRequestCherryVisa01Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase(
    debitCardRenewalRequestCherryVisa01Repository,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);
    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(id);

      dispatch(
        setDebitCardRenewalRequestCherryVisa01ById(
          output.debitCardRenewalRequestCherryVisa01,
        ),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  return {
    state,
    setState,
    debitCardRenewalRequestCherryVisa01,
  };
};

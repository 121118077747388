import React from 'react';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from './DebitCardRenewalCancelRequestCherryVisa01';

export interface DebitCardRenewalCancelRequestCherryVisa01ContextProps {
  debitCardRenewalCancelRequestCherryVisa01?: DebitCardRenewalCancelRequestCherryVisa01;
}

const DebitCardRenewalCancelRequestCherryVisa01Context = React.createContext<
  DebitCardRenewalCancelRequestCherryVisa01ContextProps
>({
  debitCardRenewalCancelRequestCherryVisa01: undefined,
});

export default DebitCardRenewalCancelRequestCherryVisa01Context;

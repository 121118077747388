// React
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DebitCardRenewalRequestContext from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequestContext';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext';
import DebitCardRenewalCancelRequestContext from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequestContext';
import DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal/DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext';

// Constant
import {
  ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS,
  ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS,
} from 'config/constants/routing';
import { DEBIT_CARD_RENEWAL_CANCEL_REQUEST_ALLOWED_MONTH } from 'config/constants/business';

// Style
import { DefaultButtonTextStyle } from 'components/atoms/button/Button2';
import {
  ButtonCancelCardStyle,
  ButtonStartRenewalStyle,
  DescriptionStyle,
} from 'components/organisms/notification/DebitCardRenewalNotification/style';

// Component
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import { SolidButton } from 'components/atoms/button/Button2/Button';
import AttentionMessageBox from 'components/organisms/notification/AttentionMessageBox';
import DebitCardRenewalNotificationLayout from 'components/organisms/notification/DebitCardRenewalNotification/DebitCardRenewalNotificationLayout';

const ORGANISM_ID = 'organisms.notification.DebitCardRenewalNotification';

const DebitCardRenewalNotification: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { debitCardRenewalRequest } = useContext(
    DebitCardRenewalRequestContext,
  );
  const { debitCardRenewalCancelRequest } = useContext(
    DebitCardRenewalCancelRequestContext,
  );
  const {
    showModal: showModalDebitCardRenewalRequestCherryVisa01ShippingAddressConfirm,
  } = useContext(
    DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext,
  );
  const {
    showModal: showModalDebitCardRenewalRequestCherryVisa01CancelConfirm,
  } = useContext(DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext);

  const [expiredDateFormatted, canCancelCard] = useMemo(() => {
    if (debitCardRenewalRequest)
      return [
        debitCardRenewalRequest.getExpiredDateFormatted(),
        debitCardRenewalRequest.canCancelCard(
          DEBIT_CARD_RENEWAL_CANCEL_REQUEST_ALLOWED_MONTH,
        ),
      ];
    if (debitCardRenewalCancelRequest)
      return [
        debitCardRenewalCancelRequest.getExpiredDateFormatted(),
        debitCardRenewalCancelRequest.canCancelCard(
          DEBIT_CARD_RENEWAL_CANCEL_REQUEST_ALLOWED_MONTH,
        ),
      ];
    return ['', false];
  }, [debitCardRenewalRequest, debitCardRenewalCancelRequest]);

  const [isInRenewalProcess, isInCancellationProcess] = useMemo(
    () =>
      debitCardRenewalRequest
        ? [
            debitCardRenewalRequest.isUnderRenewalProcess(),
            !!debitCardRenewalCancelRequest,
          ]
        : [false, !!debitCardRenewalCancelRequest],
    [debitCardRenewalRequest, debitCardRenewalCancelRequest],
  );

  const textAttentionButtons = useMemo(() => {
    if (isInCancellationProcess && isInRenewalProcess)
      return t(`${ORGANISM_ID}.button.startRenewalOrCancellation`);
    if (isInRenewalProcess) return t(`${ORGANISM_ID}.button.inRenewalProcess`);

    if (isInCancellationProcess)
      return t(`${ORGANISM_ID}.button.inCancellationProcess`);
    if (!canCancelCard) return t(`${ORGANISM_ID}.button.startRenewal`);

    return t(`${ORGANISM_ID}.button.startRenewalOrCancellation`);
  }, [isInRenewalProcess, isInCancellationProcess, canCancelCard, t]);

  return (
    <AttentionMessageBox>
      <DebitCardRenewalNotificationLayout
        isInCancellationProcess={!!isInCancellationProcess}
        isInRenewalProcess={!!isInRenewalProcess}
        canCancelCard={canCancelCard}
      >
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${ORGANISM_ID}.description`, {
            expiredDate: expiredDateFormatted,
          })}
        </TextSecondary>
        <TextSecondary key="textAttentionButtons" theme={DescriptionStyle}>
          {textAttentionButtons}
        </TextSecondary>
        <SolidButton
          key="buttonProceedRenewal"
          onClick={() => {
            history.push(
              ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS.replace(
                ':id',
                debitCardRenewalRequest?.getId() ?? '',
              ),
            );
          }}
          height={50}
          width={260}
          theme={ButtonStartRenewalStyle}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.proceedRenewal`)}
          </TextPrimary>
        </SolidButton>
        <SolidButton
          key="buttonStartRenewal"
          onClick={
            showModalDebitCardRenewalRequestCherryVisa01ShippingAddressConfirm
          }
          height={50}
          width={220}
          theme={ButtonStartRenewalStyle}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.startRenewal`)}
          </TextPrimary>
        </SolidButton>
        <SolidButton
          key="buttonProceedCancellation"
          onClick={() => {
            history.push(
              ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS.replace(
                ':id',
                debitCardRenewalCancelRequest?.getId() ?? '',
              ),
            );
          }}
          height={50}
          width={260}
          theme={ButtonCancelCardStyle}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.proceedCancellation`)}
          </TextPrimary>
        </SolidButton>
        <SolidButton
          key="buttonCancelCard"
          onClick={showModalDebitCardRenewalRequestCherryVisa01CancelConfirm}
          height={50}
          width={220}
          theme={ButtonCancelCardStyle}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.cancelCard`)}
          </TextPrimary>
        </SolidButton>
      </DebitCardRenewalNotificationLayout>
    </AttentionMessageBox>
  );
};

export default DebitCardRenewalNotification;

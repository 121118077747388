import React, { FC } from 'react';
import { convertRem } from '../../../../atoms/utils/helper/helper';

// Component
import { TableBordered } from '../../../../atoms/table/Table/Table';
import TrTableVertical from '../../tr/TrTableVertical';

export interface TableVerticalProps {
  isTableBorder: boolean;
  layout: {
    rowHeight: number | string;
    rowWidth: number | string;
    thWidth: number | string;
    tdWidth: number | string;
  };
  rows?: { header: string; data: string }[];
  theme: {
    th: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
    };
    td: {
      backgroundColor: string;
      borderBottom?: string;
      textAlign?: string;
      whiteSpace?: string;
    };
  };
}

const TableVertical: FC<TableVerticalProps> = ({
  isTableBorder,
  layout,
  rows,
  theme,
}: TableVerticalProps) => {
  if (!rows) return <></>;

  return (
    <TableBordered
      borderWidth={isTableBorder ? 1 : 0}
      borderColor="#D5D0DA"
      width={convertRem(layout.rowWidth)}
    >
      <tbody style={{ width: '100%' }}>
        {rows.map((row, i) => (
          <TrTableVertical
            key={`item${i}`}
            isBordered={isTableBorder && i !== rows.length - 1}
            header={row.header}
            data={row.data}
            theme={theme}
            layout={layout}
          />
        ))}
      </tbody>
    </TableBordered>
  );
};

export default TableVertical;

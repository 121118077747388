// IUseCase
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import IDebitCardViewUseCase from 'enhancers/useCase/debitCard/debitCard/DebitCardViewProvider/IDebitCardViewUseCase';

// IService
import IDebitCardV2QueryService from 'useCases/debitCard/debitCard/IDebitCardV2QueryService';
import ISavingsAccountQueryService from 'useCases/bankAccount/savingsAccount/ISavingsAccountQueryService';

// Service
import DebitCardV2QueryService from 'service/debitCard/debitCard/DebitCardV2QueryService';
import SavingsAccountQueryService from 'service/bankAccount/savingsAccount/SavingsAccountQueryService';
import DebitCardRenewalRequestQueryService from 'service/debitCard/debitCardRenewalRequest/debitCardRenewalRequest/DebitCardRenewalRequestQueryService';
import DebitCardRenewalCancelRequestQueryService from 'service/debitCard/debitCardRenewalCancelRequest/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestQueryService';

// IRepository
import { IDebitCardRenewalRequestQueryRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/IDebitCardRenewalRequestRepository';
import { IDebitCardRenewalCancelRequestQueryRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/IDebitCardRenewalCancelRequestRepository';
import IDebitCardV2Repository from 'domain/debitCard/debitCardV2/IDebitCardV2Repository';
import ISavingsAccountRepository from 'domain/bankAccount/savingsAccount/ISavingsAccountRepository';

// DomainObject
import SavingsAccount from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccount';
import IDebitCardRenewalCancelRequestQueryService from '../debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/IDebitCardRenewalCancelRequestQueryService';
import IDebitCardRenewalRequestQueryService from '../debitCardRenewalRequest/DebitCardRenewalRequest/IDebitCardRenewalRequestQueryService';

class DebitCardViewUseCase implements IDebitCardViewUseCase {
  private debitCardV2QueryService: IDebitCardV2QueryService;

  private savingsAccountQueryService: ISavingsAccountQueryService;

  private debitCardRenewalRequestQueryService: IDebitCardRenewalRequestQueryService;

  private debitCardRenewalCancelRequestQueryService: IDebitCardRenewalCancelRequestQueryService;

  constructor(
    debitCardV2Repository: IDebitCardV2Repository,
    savingsAccountRepository: ISavingsAccountRepository,
    debitCardRenewalRequestQueryRepository: IDebitCardRenewalRequestQueryRepository,
    debitCardRenewalCancelRequestQueryRepository: IDebitCardRenewalCancelRequestQueryRepository,
  ) {
    this.debitCardV2QueryService = new DebitCardV2QueryService(
      debitCardV2Repository,
    );

    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountRepository,
    );

    this.debitCardRenewalRequestQueryService = new DebitCardRenewalRequestQueryService(
      debitCardRenewalRequestQueryRepository,
    );

    this.debitCardRenewalCancelRequestQueryService = new DebitCardRenewalCancelRequestQueryService(
      debitCardRenewalCancelRequestQueryRepository,
    );
  }

  public open = async (user: UserAuthorized) => {
    const [
      savingsAccounts,
      debitCardRenewalRequests,
      debitCardRenewalCancelRequests,
    ] = await Promise.all([
      this.savingsAccountQueryService.findAllByUserId(user.getId()),
      this.debitCardRenewalRequestQueryService.findAllByUserId(user.getId()),
      this.debitCardRenewalCancelRequestQueryService.findAllUnCompletedByUserId(
        user.getId(),
      ),
    ]);

    return {
      savingsAccounts,
      debitCardRenewalRequests,
      debitCardRenewalCancelRequests,
    };
  };

  public findDebitCards = async (savingsAccount: SavingsAccount) => {
    const debitCardV2s = await this.debitCardV2QueryService.findAllBySavingsAccountNumber(
      savingsAccount.getRawAccountNumber(),
    );

    return { debitCardV2s };
  };
}
export default DebitCardViewUseCase;

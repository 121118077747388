// Libs
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Type
import { ShippingAddressProps } from 'value/personalInformation/address/Address/ShippingAddress/ShippingAddress.type';

// ValueObejct
import Address from 'value/personalInformation/address/Address/Address/Address';

class ShippingAddress extends Address {
  private receiverName: string;

  private contactNumber: {
    countryCode: string;
    number: string;
  };

  constructor(props: ShippingAddressProps) {
    super(props);
    this.receiverName = props.receiverName;
    this.contactNumber = props.contactNumber;
  }

  public getProps = () => ({
    ...super.getProps(),
    receiverName: this.receiverName,
    contactNumber: this.contactNumber,
  });

  public getPhoneFormatted = () => {
    const { countryCode, number } = this.contactNumber;

    const phoneNumber = parsePhoneNumberFromString(countryCode + number);
    if (!phoneNumber) return '';
    const phoneNumberInFormatNational = phoneNumber.formatNational();

    return `(${countryCode}) ${phoneNumberInFormatNational
      .substring(1)
      .replace(/ /g, '-')}`; // +213987654321 -> (+213) 98-765-43-21
  };
}
export default ShippingAddress;

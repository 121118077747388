import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface FormProofOfReceiptLayoutProps {
  children: React.ReactElement[];
}

const FormProofOfReceiptLayout: FC<FormProofOfReceiptLayoutProps> = ({
  children,
}: FormProofOfReceiptLayoutProps) => {
  const elements = ['selfie', 'button'];
  const [selfie, button] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {selfie}
      <Margin top={60} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormProofOfReceiptLayout;

import { useSelector } from 'react-redux';

// Type
import { GlobalStateType } from 'redux/store';
import { DebitCardRenewalCancelRequestProps } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest.type';

// Domain
import DebitCardRenewalCancelRequests from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequests/DebitCardRenewalCancelRequests';
import DebitCardRenewalCancelRequestFactory from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequestFactory';

export const useDebitCardRenewalCancelRequests = ():
  | DebitCardRenewalCancelRequests
  | undefined => {
  const state = useSelector(
    (globalState: GlobalStateType) =>
      globalState.debitCardRenewalCancelRequestsStore,
  );

  return state?.list?.reduce(
    (
      objs: DebitCardRenewalCancelRequests,
      item: { id: string; props: DebitCardRenewalCancelRequestProps },
    ) => {
      objs.add(
        DebitCardRenewalCancelRequestFactory.create(item.id, item.props),
      );
      return objs;
    },
    new DebitCardRenewalCancelRequests(),
  );
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import {
  DebitCardRequestCherryVisa01StepType,
  DebitCardRequestCherryVisa01StepStatusType,
} from 'types/DebitCardRequestCherryVisa01.type';

// Style
import {
  StepStyle,
  StepNumberStyle,
  TitleStyle,
  DescriptionStyle,
  ButtonTextStyle,
  IconStyle,
} from 'components/organisms/card/verification/DebitCardRequestStatusCard/style';

// Component
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRequestStatusSquareButton from 'components/atoms/button/DebitCardRequestStatusSquareButton';
import DebitCardRequestStepBadge from 'components/molecules/badge/DebitCardRequestStatusBadge';
import DebitCardRequestStatusCardLayout from 'components/organisms/card/verification/DebitCardRequestStatusCard/DebitCardRequestStatusCardLayout';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';

const ORGANISM_ID = `organisms.card.DebitCardRequestStatusCard`;

export interface DebitCardRequestStatusCardProps {
  order: number;
  debitCardRequestStepType: DebitCardRequestCherryVisa01StepType;
  status?: DebitCardRequestCherryVisa01StepStatusType;
  onClick: () => void;
}

const DebitCardRequestStatusCard: FC<DebitCardRequestStatusCardProps> = ({
  order,
  debitCardRequestStepType,
  status,
  onClick,
}: DebitCardRequestStatusCardProps) => {
  const { t } = useTranslation();

  return (
    <DebitCardRequestStatusCardLayout
      status={status}
      debitCardRequestStepType={debitCardRequestStepType}
    >
      <TextSecondary key="step" theme={StepStyle}>
        {t(`${ORGANISM_ID}.word.step`)}
      </TextSecondary>
      <TextSecondary key="stepNo" theme={StepNumberStyle}>
        {String(order)}
      </TextSecondary>
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.title`)}
      </TextPrimary>
      <DebitCardRequestStepBadge
        key="statusBadge"
        status={status as DebitCardRequestCherryVisa01StepStatusType}
        layout={{
          height: 36,
          iconWidth: 20,
          iconHeight: 20,
        }}
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced
          text={t(
            `${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.description`,
          )}
        />
      </TextSecondary>
      <DebitCardRequestStatusSquareButton
        key="button"
        height="100%"
        marginBetween={10}
        onClick={onClick}
        status={status as DebitCardRequestCherryVisa01StepStatusType}
        theme={{
          text: ButtonTextStyle,
          icon: IconStyle,
        }}
        width="100%"
      />
    </DebitCardRequestStatusCardLayout>
  );
};
export default DebitCardRequestStatusCard;

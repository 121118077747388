import { useCallback, useState, useEffect } from 'react';
import { checkInternetConnection } from 'utils/helpers/connection';
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// UseCase
import DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase';

// Repository
import SavingsAccountFunctions from 'repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import DebitCardV2Functions from 'repository/debitCard/debitCard/DebitCardV2Functions';

// DomainObject
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import { useSavingsAccount } from 'domain/bankAccount/savingsAccount/SavingsAccount/useSavingsAccount';
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

export const useDebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase = (
  debitCardRenewalRequestCherryVisa01:
    | DebitCardRenewalRequestCherryVisa01
    | undefined,
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccount, setSavingsAccount } = useSavingsAccount();

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountRepository = new SavingsAccountFunctions();
  const debitCardV2Repository = new DebitCardV2Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewUseCase(
    savingsAccountRepository,
    debitCardV2Repository,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!debitCardRenewalRequestCherryVisa01 || !userAuthorized) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(
        userAuthorized,
        debitCardRenewalRequestCherryVisa01.getProps().debitCardId,
      );

      setSavingsAccount(output.savingsAccount);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized, debitCardRenewalRequestCherryVisa01]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [open]);

  return {
    state,
    setState,
    savingsAccount,
  };
};

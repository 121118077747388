/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import React, { useContext, } from 'react';
import { useTranslation } from 'react-i18next';
import { History } from 'history'
import ReactModal from 'react-modal';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmProvider/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext';

// Constant
import { ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS } from 'config/constants/routing';
import { EMAIL_SUPPORT } from 'config/constants/business';
import { IS_LOADING } from 'config/constants/requestState';

// Style
import {
  CancelButtonTextStyle,
  contentStyle,
  DescriptionStyle,
  EmailStyle,
  overlayStyle,
  TitleStyle,
} from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/style';

// Components
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import { CancelButton, DefaultButtonTextStyle, SubmitButton } from 'components/atoms/button/Button2';
import TableConfirmDebitCardRenewalRequestShippingAddress from 'components/organisms/table/confirm/TableConfirmDebitCardRenewalRequestShippingAddress';
import { LinkTextSecondary } from 'components/atoms/text2/LinkText';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayout from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayout';

const ORGANISM_ID = `organisms.modal.DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal`;

interface DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProps {
  history: History<History.UnknownFacade>;
}

const DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal = ({
  history,
}: DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProps) => {
  const { t } = useTranslation();
  const { debitCardRenewalRequestCherryVisa01 } = useContext(DebitCardRenewalRequestCherryVisa01Context);
  const { hideModal } = useContext(DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalContext);
  const { confirm, state } = useContext(DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmContext);

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayout>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TableConfirmDebitCardRenewalRequestShippingAddress key="table" />
        <TextSecondary key="description" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${ORGANISM_ID}.description`)} />
        </TextSecondary>
        <TextSecondary key="contactUs" theme={DescriptionStyle}>
          <LineBreakReplaced text={t(`${ORGANISM_ID}.contactUs`)} />
        </TextSecondary>
        <LinkTextSecondary key="emailSupport" theme={EmailStyle} href={`mailto:${EMAIL_SUPPORT}`}>
          {EMAIL_SUPPORT}
        </LinkTextSecondary>
        <TextSecondary key="emailSupport1" theme={EmailStyle}>
          {t(`${ORGANISM_ID}.description`)}
        </TextSecondary>
        <SubmitButton
          key="submitButton"
          isLoading={state === IS_LOADING}
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          sizeProgress={20}
          onClick={() => {
            confirm(() => {
              hideModal();
              history.push(ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS.replace(':id', debitCardRenewalRequestCherryVisa01?.getId() ?? ''));
            })
          }}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.confirm`)}
          </TextPrimary>
        </SubmitButton>
        <CancelButton
          key="cancelButton"
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          onClick={hideModal}
          disabled={state === IS_LOADING}
        >
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.cancel`)}
          </TextPrimary>
        </CancelButton>
      </DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayout>
    </ReactModal>
  );
};

export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal;

import fontWeight from 'components/atoms/utils/const/fontWeight';
import color from 'components/atoms/utils/const/color';

export const contentStyle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: 15,
  width: '100%',
  height: 'auto',
  background: 'rgba(92, 92, 92, 0.75)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const overlayStyle = {
  backgroundColor: 'transparent',
  zIndex: 300,
};

export const ContainerBoxStyle = {
  backgroundColor: color.white,
  overflow: 'auto',
};

export const TitleStyle = {
  fontSize: 28,
  color: color.text.primary,
  fontWeight: fontWeight.bold,
};

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 36,
  textAlign: 'center',
};

export const SupportBoxStyle = {
  backgroundColor: color.background.ghostWhite,
};

export const EmailStyle = {
  fontSize: 22,
  color: color.text.summerSky,
  fontWeight: fontWeight.regular,
  textDecoration: 'underline',
};

export const CancelButtonTextStyle = {
  fontSize: 24,
  color: color.blue,
  fontWeight: fontWeight.medium,
};

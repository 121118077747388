// IUseCase
import IDebitCardRenewalRequestCherryVisa01StatusViewUseCase from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRequestRenewalCherryVisa01StatusViewProvider/IDebitCardRenewalRequestCherryVisa01StatusViewUseCase';

// IService
import IDebitCardRenewalRequestCherryVisa01QueryService from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01QueryService';

// Service
import DebitCardRenewalRequestCherryVisa01QueryService from 'service/debitCard/debitCardRenewalRequest/debitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01QueryService';

// IRepository
import { IDebitCardRenewalRequestCherryVisa01QueryRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

class DebitCardRenewalRequestCherryVisa01StatusViewUseCase
  implements IDebitCardRenewalRequestCherryVisa01StatusViewUseCase {
  private debitCardRequestCherryVisa01QueryService: IDebitCardRenewalRequestCherryVisa01QueryService;

  constructor(
    debitCardRequestCherryVisa01QueryRepository: IDebitCardRenewalRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRequestCherryVisa01QueryService = new DebitCardRenewalRequestCherryVisa01QueryService(
      debitCardRequestCherryVisa01QueryRepository,
    );
  }

  public open = async (userId: string) => {
    const debitCardRenewalRequestCherryVisa01 = await this.debitCardRequestCherryVisa01QueryService.findById(
      userId,
    );

    return { debitCardRenewalRequestCherryVisa01 };
  };
}

export default DebitCardRenewalRequestCherryVisa01StatusViewUseCase;

// Type
import {
  DebitCardRenewalCancelRequestCherryVisa01StepStatusType,
  DebitCardRenewalCancelRequestCherryVisa01StepType,
} from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

// Constant
import { DEBIT_CARD_RENEWAL_CANCEL_REQUEST_ALLOWED_MONTH } from 'config/constants/business';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

class DebitCardRenewalCancelRequestCherryVisa01StepMapper {
  static toStepsStatus = (
    debitCardRenewalCancelRequestCherryVisa01: DebitCardRenewalCancelRequestCherryVisa01,
  ) => {
    const {
      isCancelFeePaid,
    } = debitCardRenewalCancelRequestCherryVisa01.getProps();

    const submitCustomerRequestFormStepValue = debitCardRenewalCancelRequestCherryVisa01.isRequestFormSubmitted()
      ? DebitCardRenewalCancelRequestCherryVisa01StepStatusType.submitted
      : DebitCardRenewalCancelRequestCherryVisa01StepStatusType.notSubmitted;
    const payForCardCancellationFeeStepValue = isCancelFeePaid
      ? DebitCardRenewalCancelRequestCherryVisa01StepStatusType.paid
      : DebitCardRenewalCancelRequestCherryVisa01StepStatusType.initial;

    return {
      [DebitCardRenewalCancelRequestCherryVisa01StepType.cancellationForm]: submitCustomerRequestFormStepValue,
      [DebitCardRenewalCancelRequestCherryVisa01StepType.cancellationFee]: payForCardCancellationFeeStepValue,
    };
  };

  static toSteps = (
    debitCardRenewalCancelRequestCherryVisa01: DebitCardRenewalCancelRequestCherryVisa01,
    onClickPayForCardCancellationFee: () => void,
  ) => {
    const debitCardRenewalCancelRequestStepsStatus = DebitCardRenewalCancelRequestCherryVisa01StepMapper.toStepsStatus(
      debitCardRenewalCancelRequestCherryVisa01,
    );
    const submissionDeadlineFormatted = debitCardRenewalCancelRequestCherryVisa01.getSubmissionDeadline(
      DEBIT_CARD_RENEWAL_CANCEL_REQUEST_ALLOWED_MONTH,
    );

    return [
      {
        debitCardRenewalCancelRequestStepType:
          DebitCardRenewalCancelRequestCherryVisa01StepType.cancellationForm,
        status: debitCardRenewalCancelRequestStepsStatus.cancellationForm,
        submissionDeadline: submissionDeadlineFormatted,
      },
      {
        debitCardRenewalCancelRequestStepType:
          DebitCardRenewalCancelRequestCherryVisa01StepType.cancellationFee,
        status: debitCardRenewalCancelRequestStepsStatus.cancellationFee,
        onClick: onClickPayForCardCancellationFee,
      },
    ];
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01StepMapper;

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SavingsAccountContext from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Style
import {
  TableVerticalLayout,
  TableVerticalStyle,
} from 'components/organisms/table/confirm/TableCardCancellationFeePayment/style';

// Mapper
import TableCardCancellationFeePaymentMapper from 'components/organisms/table/confirm/TableCardCancellationFeePayment/TableCardCancellationFeePaymentMapper';

// Component
import TableVertical from 'components/molecules/table/table/TableVertical';

const TableCardCancellationFeePayment: FC = () => {
  const { t } = useTranslation();
  const { savingsAccount } = useContext(SavingsAccountContext);

  const rows = useMemo(
    () =>
      savingsAccount &&
      TableCardCancellationFeePaymentMapper.toTableData(savingsAccount, t),
    [savingsAccount, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableCardCancellationFeePayment;

import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContextProps {
  state: string;
  payCancellationFee: (callback?: () => void) => void;
}

const DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContext = React.createContext<
  DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContextProps
>({
  state: INITIAL,
  payCancellationFee: () => undefined,
});

export default DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeContext;

import Joi from '@hapi/joi';

// Type
import {
  CherryVisa01ProofOfIdentityRawDataType,
  CherryVisa01ProofOfIdentityRawDataTypeWithFiles,
} from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity.type';
import {
  CherryVisa01ProofOfReceiptRawDataType,
  CherryVisa01ProofOfReceiptRawDataTypeWithFiles,
} from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt.type';
import { DeliveryTrackingInformationRawDataType } from 'value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation.type';
import { IssuedDebitCardInformationRawDataType } from 'value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformation.type';

// ValueObject
import DeliveryTrackingInformation from 'value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation';
import IssuedDebitCardInformation from 'value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformation';
import CherryVisa01ProofOfIdentity from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';
import CherryVisa01ProofOfReceipt from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt';
import {
  DebitCardRenewalRequestProps,
  DebitCardRenewalRequestPropsFormat,
  DebitCardRenewalRequestRawDataType,
} from '../../DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest.type';

export interface DebitCardRenewalRequestCherryVisa01Props
  extends DebitCardRenewalRequestProps {
  customerId: string;

  proofOfIdentity?: CherryVisa01ProofOfIdentity;

  issuedDebitCardInformation?: IssuedDebitCardInformation;

  deliveryTrackingInformation?: DeliveryTrackingInformation;

  proofOfReceipt?: CherryVisa01ProofOfReceipt;
}

export type DebitCardRenewalRequestCherryVisa01RawDataType = DebitCardRenewalRequestRawDataType & {
  props: DebitCardRenewalRequestCherryVisa01Props & {
    customerId: string;

    proofOfIdentity?: CherryVisa01ProofOfIdentityRawDataType;

    issuedDebitCardInformation?: IssuedDebitCardInformationRawDataType;

    deliveryTrackingInformation?: DeliveryTrackingInformationRawDataType;

    proofOfReceipt?: CherryVisa01ProofOfReceiptRawDataType;
  };
};

export type DebitCardRenewalRequestCherryVisa01RawDataTypeWithProofOfIdentityFiles = DebitCardRenewalRequestRawDataType & {
  props: DebitCardRenewalRequestProps & {
    customerId: string;

    proofOfIdentity?: CherryVisa01ProofOfIdentityRawDataTypeWithFiles;
  };
};

export type DebitCardRenewalRequestCherryVisa01RawDataTypeWithProofOfReceiptFiles = DebitCardRenewalRequestRawDataType & {
  props: DebitCardRenewalRequestProps & {
    customerId: string;

    proofOfReceipt?: CherryVisa01ProofOfReceiptRawDataTypeWithFiles;
  };
};

export const DebitCardRenewalRequestCherryVisa01PropsFormat = {
  ...DebitCardRenewalRequestPropsFormat,

  customerId: Joi.string().required().allow(''),

  proofOfIdentity: Joi.any(),

  issuedDebitCardInformation: Joi.any(),

  deliveryTrackingInformation: Joi.any(),

  proofOfReceipt: Joi.any(),
};

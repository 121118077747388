import React from 'react';

// DomainObject
import DebitCardRenewalCancelRequest from './DebitCardRenewalCancelRequest';

export interface DebitCardRenewalCancelRequestContextProps {
  debitCardRenewalCancelRequest?: DebitCardRenewalCancelRequest;
}

const DebitCardRenewalCancelRequestContext = React.createContext<
  DebitCardRenewalCancelRequestContextProps
>({
  debitCardRenewalCancelRequest: undefined,
});

export default DebitCardRenewalCancelRequestContext;

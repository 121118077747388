import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDebitCardRenewalRequestCherryVisa01StatusViewUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRequestRenewalCherryVisa01StatusViewProvider/useDebitCardRenewalRequestCherryVisa01StatusViewUseCase';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalRequestCherryVisa01StatusViewProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01StatusViewProvider: FC<DebitCardRenewalRequestCherryVisa01StatusViewProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01StatusViewProviderProps) => {
  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { id } = useParams<{ id: string }>();

  const {
    state,
    setState,
    debitCardRenewalRequestCherryVisa01,
  } = useDebitCardRenewalRequestCherryVisa01StatusViewUseCase(
    id,
    userAuthorized,
  );

  return (
    <DebitCardRenewalRequestCherryVisa01Context.Provider
      value={{ debitCardRenewalRequestCherryVisa01 }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalRequestCherryVisa01Context.Provider>
  );
};

export default DebitCardRenewalRequestCherryVisa01StatusViewProvider;

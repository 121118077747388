import React, { FC, useContext } from 'react';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import { useDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider/useDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase';
import ProofOfReceiptFileContext from 'enhancers/fileHandler/debitCard/debitCardRequest/ProofOfReceiptFileProvider/CherryVisa01ProofOfReceiptFileContext';
import DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContext from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

// Type
import { FormInputCherryVisa01ProofOfReceiptContext } from 'components/organisms/form/debitCardRenewalRequests/FormCherryVisa01ProofOfReceipt/FormCherryVisa01ProofOfReceipt.type';

export type DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider: FC<DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    submitProofOfReceipt,
    formInputProofOfReceiptContext,
    proofOfReceiptFileContext,
  } = useDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase(
    debitCardRenewalRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <FormInputCherryVisa01ProofOfReceiptContext.Provider
      value={formInputProofOfReceiptContext}
    >
      <DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContext.Provider
        value={{
          state,
          submitProofOfReceipt,
        }}
      >
        <ProofOfReceiptFileContext.Provider value={proofOfReceiptFileContext}>
          <SystemErrorHandler state={state} setState={setState}>
            {children}
          </SystemErrorHandler>
        </ProofOfReceiptFileContext.Provider>
      </DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateContext.Provider>
    </FormInputCherryVisa01ProofOfReceiptContext.Provider>
  );
};
export default DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider;

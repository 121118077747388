// IService
import IDebitCardRenewalRequestCherryVisa01QueryService from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01QueryService';

// IRepository
import { IDebitCardRenewalRequestCherryVisa01QueryRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

class DebitCardRenewalRequestCherryVisa01QueryService
  implements IDebitCardRenewalRequestCherryVisa01QueryService {
  private debitCardRenewalRequestCherryVisa01QueryRepository: IDebitCardRenewalRequestCherryVisa01QueryRepository;

  constructor(
    debitCardRenewalRequestCherryVisa01QueryRepository: IDebitCardRenewalRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRenewalRequestCherryVisa01QueryRepository = debitCardRenewalRequestCherryVisa01QueryRepository;
  }

  public findById = async (id: string) => {
    const debitCardRenewalRequest = await this.debitCardRenewalRequestCherryVisa01QueryRepository.findById(
      id,
    );

    return debitCardRenewalRequest;
  };
}

export default DebitCardRenewalRequestCherryVisa01QueryService;

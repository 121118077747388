// IService
import ISavingsAccountQueryService from 'useCases/bankAccount/savingsAccount/ISavingsAccountQueryService';
import IDebitCardV2QueryService from 'useCases/debitCard/debitCard/IDebitCardV2QueryService';

// Service
import SavingsAccountQueryService from 'service/bankAccount/savingsAccount/SavingsAccountQueryService';
import DebitCardV2QueryService from 'service/debitCard/debitCard/DebitCardV2QueryService';

// IRepository
import { ISavingsAccountQueryRepository } from 'domain/bankAccount/savingsAccount/ISavingsAccountRepository';
import IDebitCardV2Repository from 'domain/debitCard/debitCardV2/IDebitCardV2Repository';

// DomainObject
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewUseCase {
  private savingsAccountQueryService: ISavingsAccountQueryService;

  private debitCardV2QueryService: IDebitCardV2QueryService;

  constructor(
    savingsAccountQueryRepository: ISavingsAccountQueryRepository,
    debitCardV2Repository: IDebitCardV2Repository,
  ) {
    this.savingsAccountQueryService = new SavingsAccountQueryService(
      savingsAccountQueryRepository,
    );
    this.debitCardV2QueryService = new DebitCardV2QueryService(
      debitCardV2Repository,
    );
  }

  public open = async (user: UserAuthorized, debitCardId: string) => {
    const debitCardV2s = await this.debitCardV2QueryService.findAllByUserId(
      user.getId(),
    );

    const debitCardV2 = debitCardV2s.filterById(debitCardId);

    const savingsAccount = await this.savingsAccountQueryService.findById(
      debitCardV2.getProps().savingsAccountNumber,
    );

    return { savingsAccount };
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewUseCase;

import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

// Constant
import { TIMEZONE } from '../../config/constants/business';

class TimeZonedTime {
  private momentTZ: moment.Moment;

  constructor(time?: number | string) {
    moment.tz.setDefault(TIMEZONE);
    this.momentTZ = time ? moment(time).tz(TIMEZONE) : moment.tz(TIMEZONE);
  }

  // Return format 'the 8th of November 2020 at 11:44 PM.'
  public mailDatetimeFormat = () => {
    return `the ${this.momentTZ.format(
      'Do of MMMM YYYY',
    )} at ${this.momentTZ.format('LT')}`;
  };

  public getTimestamp = () => this.momentTZ.valueOf();

  public getYYYYMMDD = () => this.momentTZ.format('YYYYMMDD');

  public getYear = () => this.momentTZ.format('YYYY');

  public getDateTime = () => `${this.momentTZ.format('DD/MMM/YYYY HH:mm:ss')}`;

  public getTwoLinesDateTime = () =>
    `${this.momentTZ.format('DD/MMM/YYYY')}\n${this.momentTZ.format(
      'HH:mm:ss',
    )}`;

  public getTableAccessorValue = () =>
    // YYYY/MM/DD for sort, DD/MMM/YYYY for filter
    `${this.momentTZ.format('YYYY/MM/DD HH:mm:ss')}${this.momentTZ.format(
      'DD/MMM/YYYY HH:mm:ss',
    )}`;

  public format = (formatString: string) => this.momentTZ.format(formatString);

  public addMonth = (month: number) => {
    const momentTZ = cloneDeep(this.momentTZ);
    const momentTZMonthAdded = momentTZ.add(month, 'M');
    return new TimeZonedTime(momentTZMonthAdded.valueOf());
  };

  public subtractDay = (day: number) => {
    const momentTZ = cloneDeep(this.momentTZ);
    const momentTZMonthSubtracted = momentTZ.subtract(day, 'd');
    return new TimeZonedTime(momentTZMonthSubtracted.valueOf());
  };

  public subtractMonth = (month: number) => {
    const momentTZ = cloneDeep(this.momentTZ);
    const momentTZMonthSubtracted = momentTZ.subtract(month, 'M');
    return new TimeZonedTime(momentTZMonthSubtracted.valueOf());
  };

  public addMinute = (minute: number) => {
    const momentTZ = cloneDeep(this.momentTZ);
    const momentTZMinuteAdded = momentTZ.add(minute, 'm');
    return new TimeZonedTime(momentTZMinuteAdded.valueOf());
  };

  public diffFromToday = () => {
    const today = moment().tz(TIMEZONE).format('YYYY-MM-DD');
    const diff = {
      year: this.momentTZ.diff(today, 'year'),
      month: this.momentTZ.diff(today, 'month'),
      day: this.momentTZ.diff(today, 'day'),
    };
    return diff;
  };

  public getSystemMaintenanceDatetimeFormat = () => {
    const ampm = this.momentTZ.format('a') === 'am' ? 'a.m.' : 'p.m.';

    return `${this.momentTZ.format('h:mm ')}${ampm} ${this.momentTZ.format(
      'on D MMM YYYY',
    )}`;
  };

  public diffTimestampFromCurrentTime = () => {
    const currentTimestamp = new TimeZonedTime().getTimestamp();

    const timestamp = this.getTimestamp();

    const diff = timestamp - currentTimestamp;

    return diff;
  };

  public isSummerTime = () => this.momentTZ.isDST();
}
export default TimeZonedTime;

// Type
import { DebitCardRenewalRequestCherryVisa01Props } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01.type';
import { DebitCardRenewalRequestCherryVisa01StatusType } from 'types/DebitCardRenewalRequestCherryVisa01.type';

// DomainObject
import DebitCardRenewalRequest from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest';

// ValueObject
import DeliveryTrackingInformation from 'value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformation';
import IssuedDebitCardInformation from 'value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformation';
import CherryVisa01ProofOfIdentity from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfIdentity/CherryVisa01ProofOfIdentity';
import CherryVisa01ProofOfReceipt from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt';

class DebitCardRenewalRequestCherryVisa01 extends DebitCardRenewalRequest {
  private customerId: string;

  private proofOfIdentity?: CherryVisa01ProofOfIdentity;

  private issuedDebitCardInformation?: IssuedDebitCardInformation;

  private deliveryTrackingInformation?: DeliveryTrackingInformation;

  private proofOfReceipt?: CherryVisa01ProofOfReceipt;

  constructor(id: string, props: DebitCardRenewalRequestCherryVisa01Props) {
    super(id, props);
    this.customerId = props.customerId;
    this.status = props.status;
    this.proofOfIdentity = props.proofOfIdentity;
    this.issuedDebitCardInformation = props.issuedDebitCardInformation;
    this.deliveryTrackingInformation = props.deliveryTrackingInformation;
    this.proofOfReceipt = props.proofOfReceipt;
    this.props = props;
  }

  public getProps() {
    return {
      ...super.getProps(),
      customerId: this.customerId,
      status: this.status,
      proofOfIdentity: this.proofOfIdentity,
      issuedDebitCardInformation: this.issuedDebitCardInformation,
      deliveryTrackingInformation: this.deliveryTrackingInformation,
      proofOfReceipt: this.proofOfReceipt,
    };
  }

  public isProofOfReceiptEditable = () =>
    [
      DebitCardRenewalRequestCherryVisa01StatusType.waitingProofOfReceipt,
      DebitCardRenewalRequestCherryVisa01StatusType.proofOfReceiptRejected,
    ].includes(this.status);
}
export default DebitCardRenewalRequestCherryVisa01;

import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SavingsAccountContext from 'domain/bankAccount/savingsAccount/SavingsAccount/SavingsAccountContext';

// Style
import {
  TableVerticalLayout,
  TableVerticalStyle,
} from 'components/organisms/table/confirm/TableCardIssuanceFeePayment/style';

// Mapper
import TableCardIssuanceFeePaymentMapper from 'components/organisms/table/confirm/TableCardIssuanceFeePayment/TableCardIssuanceFeePaymentMapper';

// Component
import TableVertical from 'components/molecules/table/table/TableVertical';

const TableCardIssuanceFeePayment: FC = () => {
  const { t } = useTranslation();
  const { savingsAccount } = useContext(SavingsAccountContext);

  const rows = useMemo(
    () =>
      savingsAccount &&
      TableCardIssuanceFeePaymentMapper.toTableData(savingsAccount, t),
    [savingsAccount, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableCardIssuanceFeePayment;

// IUseCase
import IDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewProvider/IDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase';

// IService
import IDebitCardRenewalRequestCherryVisa01QueryService from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01QueryService';

// Service
import DebitCardRenewalRequestCherryVisa01QueryService from 'service/debitCard/debitCardRenewalRequest/debitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01QueryService';

// IRepository
import { IDebitCardRenewalRequestCherryVisa01QueryRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

class DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase
  implements IDebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase {
  private debitCardRenewalRequestCherryVisa01QueryService: IDebitCardRenewalRequestCherryVisa01QueryService;

  constructor(
    debitCardRenewalRequestCherryVisa01QueryRepository: IDebitCardRenewalRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRenewalRequestCherryVisa01QueryService = new DebitCardRenewalRequestCherryVisa01QueryService(
      debitCardRenewalRequestCherryVisa01QueryRepository,
    );
  }

  public open = async (id: string) => {
    const debitCardRenewalRequestCherryVisa01 = await this.debitCardRenewalRequestCherryVisa01QueryService.findById(
      id,
    );

    return { debitCardRenewalRequestCherryVisa01 };
  };
}

export default DebitCardRenewalRequestCherryVisa01ProofOfReceiptViewUseCase;

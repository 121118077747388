// Type
import { CherryAPIFilePurposeType } from 'types/CherryAPIFilePurposeType';

// IUseCase
import IDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateProvider/IDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase';

// IService
import ICherryStorageService from 'useCases/storage/ICherryStorageService';

// Service
import DebitCardRenewalRequestCherryVisa01UpdateService from 'service/debitCard/debitCardRenewalRequest/debitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01UpdateService';
import CherryStorageService from 'service/storage/CherryStorageService';

// IRepository
import ICherryStorageHandler from 'storage/cherryStorageHandler/ICherryStorageHandler';
import { IDebitCardRenewalRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';
import IDebitCardRenewalRequestCherryVisa01UpdateService from './IDebitCardRenewalRequestCherryVisa01UpdateService';

class DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase
  implements IDebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase {
  private debitCardRenewalRequestCherryVisa01UpdateService: IDebitCardRenewalRequestCherryVisa01UpdateService;

  private storageService: ICherryStorageService;

  constructor(
    debitCardRenewalRequestCherryVisa01Repository: IDebitCardRenewalRequestCherryVisa01UpdateRepository,
    storageHandler: ICherryStorageHandler,
  ) {
    this.debitCardRenewalRequestCherryVisa01UpdateService = new DebitCardRenewalRequestCherryVisa01UpdateService(
      debitCardRenewalRequestCherryVisa01Repository,
    );

    this.storageService = new CherryStorageService(storageHandler);
  }

  public update = async (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
    selfie: File,
  ) => {
    // Upload Selfie
    const uploadSelfie = async () => {
      if (selfie) {
        return this.storageService.upload(
          selfie,
          CherryAPIFilePurposeType.selfie,
        );
      }

      return undefined;
    };

    // UploadFile
    const selfieId = await uploadSelfie();

    const updatedDebitCardRenewalRequestCherryVisa01 = await this.debitCardRenewalRequestCherryVisa01UpdateService.updateProofOfReceipt(
      debitCardRenewalRequestCherryVisa01,
      selfieId as string,
    );

    return {
      debitCardRenewalRequestCherryVisa01: updatedDebitCardRenewalRequestCherryVisa01,
    };
  };
}

export default DebitCardRenewalRequestCherryVisa01ProofOfReceiptUpdateUseCase;

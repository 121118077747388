/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

// Type
import { FormInputCherryVisa01ProofOfReceipt } from 'components/organisms/form/debitCardRenewalRequests/FormCherryVisa01ProofOfReceipt/FormCherryVisa01ProofOfReceipt.type';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';

export const isValid = (
  errors: DeepMap<FormInputCherryVisa01ProofOfReceipt, FieldError>,
  watch: (
    name: keyof FormInputCherryVisa01ProofOfReceipt,
  ) => UnpackNestedValue<FormInputCherryVisa01ProofOfReceipt>,
) => {
  return isEmpty(errors) && !isEmpty(watch('selfieUrl'));
};

// Type
import { DebitCardRenewalCancelRequestCherryVisa01Props } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01.type';
import { DebitCardRenewalCancelRequestCherryVisa01StatusType } from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

// DomainObject
import DebitCardRenewalCancelRequest from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequest';

// ValueObject
import TimeZonedTimeFactory from 'value/datetime/TimeZonedTimeFactory';

class DebitCardRenewalCancelRequestCherryVisa01 extends DebitCardRenewalCancelRequest {
  private customerId: string;

  private isCancelFeePaid: boolean;

  constructor(
    id: string,
    props: DebitCardRenewalCancelRequestCherryVisa01Props,
  ) {
    super(id, props);
    this.customerId = props.customerId;
    this.status = props.status;
    this.isCancelFeePaid = props.isCancelFeePaid;
    this.props = props;
  }

  public getProps() {
    return {
      ...super.getProps(),
      customerId: this.customerId,
      status: this.status,
      isCancelFeePaid: this.isCancelFeePaid,
    };
  }

  public isRequestFormSubmitted = () =>
    [
      DebitCardRenewalCancelRequestCherryVisa01StatusType.completed,
      DebitCardRenewalCancelRequestCherryVisa01StatusType.cancellationFormSent,
    ].includes(this.status);

  public getSubmissionDeadline(nextMonth: number) {
    const { year, month } = this.expiredYearMonth;
    const date = `${year}/${month.toString().padStart(2, '0')}/01`;

    // ExpiryDate
    const expiryDateTz = TimeZonedTimeFactory.createZeroOClock(date).addMonth(
      1,
    );

    // First day of preparation month
    const firstDayOfPreparationMonthTz = expiryDateTz.subtractMonth(nextMonth);

    return firstDayOfPreparationMonthTz.format('DD/MMM/YYYY');
  }
}
export default DebitCardRenewalCancelRequestCherryVisa01;

import React, { FC, useContext } from 'react';
import { useDebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider/useDebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider/DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalRequestCherryVisa01PayRenewalFeeProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider: FC<DebitCardRenewalRequestCherryVisa01PayRenewalFeeProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01PayRenewalFeeProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  const {
    state,
    setState,
    payRenewalFee,
  } = useDebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase(
    debitCardRenewalRequestCherryVisa01,
    userAuthorized,
  );

  return (
    <DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext.Provider
      value={{
        state,
        payRenewalFee,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext.Provider>
  );
};
export default DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider;

// Functions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import IDebitCardRenewalCancelRequestRepository from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/IDebitCardRenewalCancelRequestRepository';

// Constant
import { DEBIT_CARD_RENEWAL_CANCEL_REQUEST_GET_RAW_UN_COMPLETED_BY_USER_ID } from 'infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import DebitCardRenewalCancelRequestsFactory from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequests/DebitCardRenewalCancelRequestsFactory';

class DebitCardRenewalCancelRequestFunctions
  extends FirebasePrivateFunctions
  implements IDebitCardRenewalCancelRequestRepository {
  public findAllUnCompletedByUserId = async (userId: string) => {
    // Replace userId
    const endpoint = DEBIT_CARD_RENEWAL_CANCEL_REQUEST_GET_RAW_UN_COMPLETED_BY_USER_ID.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    return DebitCardRenewalCancelRequestsFactory.createRawDataFromDebitCardRenewalCancelRequestsCherryVisa01RawData(
      response.data.data,
    );
  };
}
export default DebitCardRenewalCancelRequestFunctions;

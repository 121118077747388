import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// Type
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';
import {
  DebitCardRenewalRequestCherryVisa01Props,
  DebitCardRenewalRequestCherryVisa01PropsFormat,
  DebitCardRenewalRequestCherryVisa01RawDataType,
} from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01.type';

// ValueObject
import CherryVisa01ProofOfReceiptFactory from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceiptFactory';
import DeliveryTrackingInformationFactory from 'value/debitCard/DebitCardRequestContent/DeliveryTrackingInformation/DeliveryTrackingInformationFactory';
import IssuedDebitCardInformationFactory from 'value/debitCard/DebitCardRequestContent/IssuedDebitCardInformation/IssuedDebitCardInformationFactory';

class DebitCardRenewalRequestCherryVisa01Factory {
  static create = (
    id: string,
    props: DebitCardRenewalRequestCherryVisa01Props,
  ) => {
    const result = Joi.object(
      DebitCardRenewalRequestCherryVisa01PropsFormat,
    ).validate({
      props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardRenewalRequestCherryVisa01.name,
          reason: `${result.error.details[0].message}`,
        },
      );

    return new DebitCardRenewalRequestCherryVisa01(id, props);
  };

  static createFromRawData = (
    debitCardRequestCherryVisa01RawData: DebitCardRenewalRequestCherryVisa01RawDataType,
  ) => {
    const { id, props } = debitCardRequestCherryVisa01RawData;

    const deliveryTrackingInformation =
      props.deliveryTrackingInformation &&
      DeliveryTrackingInformationFactory.create(
        props.deliveryTrackingInformation,
      );

    const proofOfReceipt =
      props.proofOfReceipt &&
      CherryVisa01ProofOfReceiptFactory.create(props.proofOfReceipt);

    const issuedDebitCardInformation =
      props.issuedDebitCardInformation &&
      IssuedDebitCardInformationFactory.create(
        props.issuedDebitCardInformation,
      );

    return new DebitCardRenewalRequestCherryVisa01(id, {
      ...debitCardRequestCherryVisa01RawData.props,
      proofOfReceipt,
      deliveryTrackingInformation,
      issuedDebitCardInformation,
    });
  };
}

export default DebitCardRenewalRequestCherryVisa01Factory;

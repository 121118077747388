import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRequestCherryVisa01Context from 'domain/debitCard/debitCardRequest/DebitCardRequestCherryVisa01/DebitCardRequestCherryVisa01Context';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';

// Style
import {
  TableVerticalLayout,
  TableVerticalStyle,
} from 'components/organisms/table/confirm/TableCardDeliveryStatus/style';

// Mapper
import TableCardDeliveryStatusMapper from 'components/organisms/table/confirm/TableCardDeliveryStatus/TableCardDeliveryStatusMapper';

// Component
import TableVertical from 'components/molecules/table/table/TableVertical';

const TableCardDeliveryStatus: FC = () => {
  const { t } = useTranslation();
  const { debitCardRequestCherryVisa01 } = useContext(
    DebitCardRequestCherryVisa01Context,
  );
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  const { deliveryTrackingInformation } =
    debitCardRequestCherryVisa01?.getProps() ??
    debitCardRenewalRequestCherryVisa01?.getProps() ??
    {};

  const rows = useMemo(
    () =>
      TableCardDeliveryStatusMapper.toTableData(t, deliveryTrackingInformation),
    [deliveryTrackingInformation, t],
  );

  return (
    <TableVertical
      isTableBorder
      layout={TableVerticalLayout}
      rows={rows}
      theme={TableVerticalStyle}
    />
  );
};

export default TableCardDeliveryStatus;

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import {
  DebitCardRenewalCancelRequestCherryVisa01StepType,
  DebitCardRenewalCancelRequestCherryVisa01StepStatusType,
} from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

// Style
import {
  SubTitleStyle,
  TitleStyle,
  DescriptionStyle,
  ButtonTextStyle,
  IconStyle,
} from 'components/organisms/card/verification/DebitCardRenewalCancelRequestStatusCard/style';

// Component
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRenewalCancelRequestStatusSquareButton from 'components/atoms/button/DebitCardRenewalCancelRequestStatusSquareButton';
import DebitCardRenewalCancelRequestStepBadge from 'components/molecules/badge/DebitCardRenewalCancelRequestStatusBadge';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';
import DebitCardRenewalCancelRequestStatusCardLayout from 'components/organisms/card/verification/DebitCardRenewalCancelRequestStatusCard/DebitCardRenewalCancelRequestStatusCardLayout';

const ORGANISM_ID = `organisms.card.DebitCardRenewalCancelRequestStatusCard`;

export interface DebitCardRenewalCancelRequestStatusCardProps {
  submissionDeadline?: string;
  debitCardRequestStepType: DebitCardRenewalCancelRequestCherryVisa01StepType;
  status: DebitCardRenewalCancelRequestCherryVisa01StepStatusType;
  onClick: () => void;
}

const DebitCardRenewalCancelRequestStatusCard: FC<DebitCardRenewalCancelRequestStatusCardProps> = ({
  submissionDeadline,
  debitCardRequestStepType,
  status,
  onClick,
}: DebitCardRenewalCancelRequestStatusCardProps) => {
  const { t } = useTranslation();

  const isShowSubtitle = useMemo(
    () =>
      debitCardRequestStepType ===
        DebitCardRenewalCancelRequestCherryVisa01StepType.cancellationForm &&
      status ===
        DebitCardRenewalCancelRequestCherryVisa01StepStatusType.notSubmitted,
    [debitCardRequestStepType, status],
  );

  const description = useMemo(() => {
    const keyOfDescription =
      status ===
      DebitCardRenewalCancelRequestCherryVisa01StepStatusType.submitted
        ? 'descriptionSubmitted'
        : 'description';

    return t(
      `${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.${keyOfDescription}`,
      { deadline: `<b>${submissionDeadline}</b>` },
    );
  }, [debitCardRequestStepType, status, submissionDeadline, t]);

  return (
    <DebitCardRenewalCancelRequestStatusCardLayout
      status={status}
      debitCardRequestStepType={debitCardRequestStepType}
      isShowSubtitle={isShowSubtitle}
    >
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.title`)}
      </TextPrimary>
      <TextSecondary key="subTitle" theme={SubTitleStyle}>
        {t(
          `${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.subTitle`,
          { deadline: submissionDeadline },
        )}
      </TextSecondary>
      <DebitCardRenewalCancelRequestStepBadge
        key="statusBadge"
        status={status}
        layout={{
          height: 40,
          iconWidth: 18,
          iconHeight: 18,
        }}
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced text={description} />
      </TextSecondary>
      <DebitCardRenewalCancelRequestStatusSquareButton
        key="button"
        height="100%"
        marginBetween={10}
        onClick={onClick}
        status={status}
        theme={{
          text: ButtonTextStyle,
          icon: IconStyle,
        }}
        width="100%"
      />
    </DebitCardRenewalCancelRequestStatusCardLayout>
  );
};
export default DebitCardRenewalCancelRequestStatusCard;

// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import DebitCardRenewalRequests from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequests/DebitCardRenewalRequests';

export interface DebitCardRenewalRequestsState {
  list: DebitCardRenewalRequests | undefined;
}

// eslint-disable-next-line
const initialState: any = {
  list: undefined,
};

const debitCardRenewalRequestsSlice = createSlice({
  name: 'debitCardRenewalRequests',
  initialState,
  reducers: {
    setDebitCardRenewalRequests: (
      state: DebitCardRenewalRequestsState,
      action: PayloadAction<{
        debitCardRenewalRequests: DebitCardRenewalRequests;
      }>,
    ) => ({
      ...state,
      list: action.payload.debitCardRenewalRequests,
    }),
  },
});

export const {
  setDebitCardRenewalRequests,
} = debitCardRenewalRequestsSlice.actions;
export default debitCardRenewalRequestsSlice.reducer;

import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// Type
import {
  DebitCardRenewalCancelRequestProps,
  DebitCardRenewalCancelRequestPropsFormat,
} from './DebitCardRenewalCancelRequest.type';

// DomainObject
import DebitCardRenewalCancelRequest from './DebitCardRenewalCancelRequest';

class DebitCardRenewalCancelRequestFactory {
  static create = (id: string, props: DebitCardRenewalCancelRequestProps) => {
    const result = Joi.object(
      DebitCardRenewalCancelRequestPropsFormat,
    ).validate({
      id,
      ...props,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: DebitCardRenewalCancelRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new DebitCardRenewalCancelRequest(id, props);
  };
}

export default DebitCardRenewalCancelRequestFactory;

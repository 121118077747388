import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import {
  ContainerBoxStyle,
  SupportBoxStyle,
} from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/style';

// Component
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayoutProps {
  children: React.ReactElement[];
}

const DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayout: FC<DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayoutProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayoutProps) => {
  const elements = [
    'title',
    'table',
    'description',
    'contactUs',
    'emailSupport',
    'submitButton',
    'cancelButton',
  ];
  const [
    title,
    table,
    description,
    contactUs,
    emailSupport,
    submitButton,
    cancelButton,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      alignItems="center"
      width={840}
      height="auto"
      maxHeight="100%"
      padding={'50 70'}
      theme={ContainerBoxStyle}
    >
      {title}
      <Margin top={60} />
      {description}
      <Margin top={60} />
      {table}
      <Margin top={60} />
      <FlexBoxColumn
        theme={SupportBoxStyle}
        padding={'40 50'}
        alignItems="center"
      >
        {contactUs}&nbsp;
        {emailSupport}
      </FlexBoxColumn>
      <Margin top={60} />
      <FlexBoxRow justifyContent="center">
        {submitButton}
        <Margin left={40} />
        {cancelButton}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalLayout;

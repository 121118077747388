import styled from 'styled-components';

// LayoutComponent
import Box, { BoxProps } from '../../utils/layout/Box/Box';

interface TdProps extends BoxProps {
  verticalAlign?: 'top' | 'middle' | 'bottom';
  textAlign?: 'left' | 'center' | 'right';
}

const Td = styled(Box.withComponent('td')) <TdProps>`
  vertical-align: ${(props: TdProps) => props.verticalAlign || 'top'};
  word-break: break-all;
`;

interface TableBorderedProps {
  theme: {
    borderBottom: string;
    padding?: string | number;
    textAlign?: 'left' | 'center' | 'right';
    whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap';
  };
}

export const TdBorderBottom = styled(Td)`
  box-sizing: border-box;
  text-align: ${(props: TableBorderedProps) =>
    props.theme.textAlign ? props.theme.textAlign : 'center'};
  border-bottom: ${(props: TableBorderedProps) => props.theme.borderBottom};
  white-space: ${(props: TableBorderedProps) => props.theme.whiteSpace || 'normal'}
`;

export default Td;

import color from 'components/atoms/utils/const/color';
import fontWeight from 'components/atoms/utils/const/fontWeight';

export const DescriptionStyle = {
  fontSize: 22,
  color: color.text.zambezi,
  fontWeight: fontWeight.regular,
  lineHeight: 44,
  textAlign: 'left',
};

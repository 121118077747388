// Functions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import IDebitCardRenewalRequestCherryVisa01Repository from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

// Constant
import {
  DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_CONFIRM_SHIPPING_ADDRESS,
  DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_GET_RAW_BY_ID,
  DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PAY_RENEWAL_FEE,
  DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_RECEIPT,
} from 'infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

class DebitCardRenewalRequestCherryVisa01Functions
  extends FirebasePrivateFunctions
  implements IDebitCardRenewalRequestCherryVisa01Repository {
  public confirmShippingAddress = async (
    debitCardRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    const endpoint = DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_CONFIRM_SHIPPING_ADDRESS;

    const response = await this.privatePOST(endpoint, {
      id: debitCardRequestCherryVisa01.getId(),
    });

    return response.data.data;
  };

  public findById = async (id: string) => {
    // Replace id
    const endpoint = DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_GET_RAW_BY_ID.replace(
      /:id/,
      id,
    );

    const response = await this.privateGET(endpoint);

    return response.data.data;
  };

  public payRenewalFee = async (
    debitCardRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    const id = debitCardRequestCherryVisa01.getId();

    const endpoint = DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PAY_RENEWAL_FEE;

    await this.privatePOST(endpoint, { id });

    return debitCardRequestCherryVisa01;
  };

  public updateProofOfReceipt = async (
    debitCardRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
    selfieId: string,
  ) => {
    const id = debitCardRequestCherryVisa01.getId();

    const endpoint = DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_RECEIPT;

    await this.privatePOST(endpoint, { id, selfieId });

    return debitCardRequestCherryVisa01;
  };
}
export default DebitCardRenewalRequestCherryVisa01Functions;

// Service
import DebitCardRenewalCancelRequestCherryVisa01QueryService from 'service/debitCard/debitCardRenewalCancelRequest/debitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01QueryService';

// IRepository
import { IDebitCardRenewalCancelRequestCherryVisa01QueryRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01Repository';

class DebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase {
  private debitCardRequestCherryVisa01QueryService: DebitCardRenewalCancelRequestCherryVisa01QueryService;

  constructor(
    debitCardRequestCherryVisa01QueryRepository: IDebitCardRenewalCancelRequestCherryVisa01QueryRepository,
  ) {
    this.debitCardRequestCherryVisa01QueryService = new DebitCardRenewalCancelRequestCherryVisa01QueryService(
      debitCardRequestCherryVisa01QueryRepository,
    );
  }

  public open = async (userId: string) => {
    const debitCardRenewalCancelRequestCherryVisa01 = await this.debitCardRequestCherryVisa01QueryService.findById(
      userId,
    );

    return { debitCardRenewalCancelRequestCherryVisa01 };
  };
}

export default DebitCardRenewalCancelRequestCherryVisa01StatusViewUseCase;

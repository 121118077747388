// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import { DebitCardRenewalCancelRequestCherryVisa01RawDataType } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01.type';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

export interface DebitCardRenewalCancelRequestCherryVisa01sState {
  byId: {
    [id: string]: DebitCardRenewalCancelRequestCherryVisa01RawDataType;
  };
}

// eslint-disable-next-line
const initialState: any = {
  byId: {},
};

const debitCardRenewalCancelRequestCherryVisa01sSlice = createSlice({
  name: 'debitCardRenewalCancelRequestCherryVisa01s',
  initialState,
  reducers: {
    setDebitCardRenewalCancelRequestCherryVisa01ById: (
      state: DebitCardRenewalCancelRequestCherryVisa01sState,
      action: PayloadAction<
        DebitCardRenewalCancelRequestCherryVisa01RawDataType
      >,
    ) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload,
      },
    }),

    updateDebitCardRenewalCancelRequestCherryVisa01FeePaidById: (
      state: DebitCardRenewalCancelRequestCherryVisa01sState,
      action: PayloadAction<{
        debitCardRenewalCancelRequestCherryVisa01: DebitCardRenewalCancelRequestCherryVisa01;
        isCancelFeePaid: boolean;
      }>,
    ) => {
      const {
        debitCardRenewalCancelRequestCherryVisa01,
        isCancelFeePaid,
      } = action.payload;
      const id = debitCardRenewalCancelRequestCherryVisa01.getId();
      if (typeof state.byId[id] !== 'undefined') {
        // eslint-disable-next-line no-param-reassign
        state.byId[id].props.isCancelFeePaid = isCancelFeePaid;
      }
    },
  },
});

export const {
  setDebitCardRenewalCancelRequestCherryVisa01ById,
  updateDebitCardRenewalCancelRequestCherryVisa01FeePaidById,
} = debitCardRenewalCancelRequestCherryVisa01sSlice.actions;
export default debitCardRenewalCancelRequestCherryVisa01sSlice.reducer;

import color from 'components/atoms/utils/const/color';

export const TableVerticalStyle = {
  th: {
    backgroundColor: color.background.ghostWhite,
    borderBottom: `${1 / 16}rem solid ${color.border.white}`,
  },
  td: {
    backgroundColor: color.background.white,
    borderBottom: `${1 / 16}rem solid ${color.border.gray}`,
    whiteSpace: 'pre-wrap',
  },
};

export const TableVerticalLayout = {
  rowWidth: '100%',
  rowHeight: 80,
  thWidth: '36%',
  tdWidth: '64%',
};

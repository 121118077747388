import styled from 'styled-components';
import { convertRem, getPadding } from '../../../helper/helper';

export interface BoxProps {
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  padding?: string | number;
  paddingTop?: string | number;
  paddingRight?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
  theme?: {
    backgroundColor?: string;
    border?: string;
    borderLeft?: string;
    borderRight?: string;
    borderRadius?: string | number;
    boxShadow?: string;
    overflow?: string;
    opacity?: number;
  };
  textAlign?: 'left' | 'right' | 'center';
}

const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  width: ${(props: BoxProps) =>
    props.width || props.width === 0 ? convertRem(props.width) : '100%'};
  height: ${(props: BoxProps) =>
    props.height || props.height === 0 ? convertRem(props.height) : '100%'};
  max-height: ${(props: BoxProps) =>
    props.maxHeight || props.maxHeight === 0
      ? convertRem(props.maxHeight)
      : 'none'};
  padding: ${(props: BoxProps) => getPadding(props)};
  text-align: ${(props: BoxProps) => props.textAlign || 'center'};
  background-color: ${(props: BoxProps) => props.theme?.backgroundColor || ''};
  border: ${(props: BoxProps) => props.theme?.border || 'none'};
  box-shadow: ${(props: BoxProps) => props.theme?.boxShadow || 'none'};
  border-left: ${(props: BoxProps) =>
    props.theme?.borderLeft || props.theme?.border || 'none'};
  border-right: ${(props: BoxProps) =>
    props.theme?.borderRight || props.theme?.border || 'none'};
  border-radius: ${(props: BoxProps) =>
    props.theme?.borderRadius ? convertRem(props.theme.borderRadius) : 0};
  opacity: ${(props: BoxProps) => props.theme?.opacity || 1};
  overflow: ${(props: BoxProps) => props.theme?.overflow || 'initial'};
`;

export default Box;

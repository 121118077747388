import React, { FC, useContext } from 'react';
import { useDebitCardRenewalRequestCherryVisa01CancelConfirmUseCase } from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01CancelConfirmProvider/useDebitCardRenewalRequestCherryVisa01CancelConfirmUseCase';
import DebitCardRenewalRequestCherryVisa01CancelConfirmContext from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01CancelConfirmProvider/DebitCardRenewalRequestCherryVisa01CancelConfirmContext';
import DebitCardRenewalRequestContext from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequestContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type DebitCardRenewalRequestCherryVisa01CancelConfirmProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalRequestCherryVisa01CancelConfirmProvider: FC<DebitCardRenewalRequestCherryVisa01CancelConfirmProviderProps> = ({
  children,
}: DebitCardRenewalRequestCherryVisa01CancelConfirmProviderProps) => {
  const { debitCardRenewalRequest } = useContext(
    DebitCardRenewalRequestContext,
  );

  const {
    state,
    setState,
    cancel,
  } = useDebitCardRenewalRequestCherryVisa01CancelConfirmUseCase(
    debitCardRenewalRequest,
  );

  return (
    <DebitCardRenewalRequestCherryVisa01CancelConfirmContext.Provider
      value={{
        state,
        cancel,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </DebitCardRenewalRequestCherryVisa01CancelConfirmContext.Provider>
  );
};
export default DebitCardRenewalRequestCherryVisa01CancelConfirmProvider;

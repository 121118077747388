// IService
import IDebitCardRenewalRequestCherryVisa01UpdateService from 'useCases/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01UpdateService';

// Service
import DebitCardRenewalRequestCherryVisa01UpdateService from 'service/debitCard/debitCardRenewalRequest/debitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01UpdateService';

import { IDebitCardRenewalRequestCherryVisa01UpdateRepository } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/IDebitCardRenewalRequestCherryVisa01Repository';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

class DebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase {
  private debitCardRenewalRequestCherryVisa01UpdateService: IDebitCardRenewalRequestCherryVisa01UpdateService;

  constructor(
    debitCardRenewalRequestCherryVisa01Repository: IDebitCardRenewalRequestCherryVisa01UpdateRepository,
  ) {
    this.debitCardRenewalRequestCherryVisa01UpdateService = new DebitCardRenewalRequestCherryVisa01UpdateService(
      debitCardRenewalRequestCherryVisa01Repository,
    );
  }

  payRenewalFee = async (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    const updatedDebitCardRenewalRequestCherryVisa01 = await this.debitCardRenewalRequestCherryVisa01UpdateService.payRenewalFee(
      debitCardRenewalRequestCherryVisa01,
    );

    return {
      debitCardRenewalRequestCherryVisa01: updatedDebitCardRenewalRequestCherryVisa01,
    };
  };
}

export default DebitCardRenewalRequestCherryVisa01PayRenewalFeeUseCase;

import { useSelector } from 'react-redux';

// Type
import { GlobalStateType } from 'redux/store';
import { DebitCardRenewalRequestProps } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest.type';

// Domain
import DebitCardRenewalRequests from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequests/DebitCardRenewalRequests';
import DebitCardRenewalRequestFactory from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequestFactory';

export const useDebitCardRenewalRequests = ():
  | DebitCardRenewalRequests
  | undefined => {
  const state = useSelector(
    (globalState: GlobalStateType) => globalState.debitCardRenewalRequestsStore,
  );

  return state?.list?.reduce(
    (
      objs: DebitCardRenewalRequests,
      item: { id: string; props: DebitCardRenewalRequestProps },
    ) => {
      objs.add(DebitCardRenewalRequestFactory.create(item.id, item.props));
      return objs;
    },
    new DebitCardRenewalRequests(),
  );
};

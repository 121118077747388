import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import DebitCardIssuanceFeePaymentModalContext from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal/DebitCardIssuanceFeePaymentModalContext';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider';
import DebitCardRenewalRequestCherryVisa01PayIssuanceFeeContext from 'enhancers/useCase/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01PayRenewalFeeProvider/DebitCardRenewalRequestCherryVisa01PayRenewalFeeContext';

// Component
import DebitCardIssuanceFeePaymentModal from 'components/organisms/modal/DebitCardIssuanceFeePaymentModal';

export type DebitCardRenewalFeePaymentModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardRenewalFeePaymentModalProvider: FC<DebitCardRenewalFeePaymentModalProviderProps> = ({
  children,
}: DebitCardRenewalFeePaymentModalProviderProps) => {
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );
  const userAuthorizedContext = useContext(UserAuthorizedContext);

  const { state, payRenewalFee } = useContext(
    DebitCardRenewalRequestCherryVisa01PayIssuanceFeeContext,
  );

  const [showModal, hideModal] = useModal(
    () => (
      <DebitCardIssuanceFeePaymentModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRenewalRequestCherryVisa01Context.Provider
          value={{ debitCardRenewalRequestCherryVisa01 }}
        >
          <UserAuthorizedContext.Provider value={userAuthorizedContext}>
            <DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider>
              <DebitCardIssuanceFeePaymentModal
                state={state}
                onSubmit={() => {
                  payRenewalFee(() => {
                    hideModal();
                  });
                }}
              />
            </DebitCardRenewalRequestCherryVisa01PayRenewalFeeViewProvider>
          </UserAuthorizedContext.Provider>
        </DebitCardRenewalRequestCherryVisa01Context.Provider>
      </DebitCardIssuanceFeePaymentModalContext.Provider>
    ),
    [
      debitCardRenewalRequestCherryVisa01,
      userAuthorizedContext,
      state,
      payRenewalFee,
    ],
  );

  return (
    <DebitCardIssuanceFeePaymentModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </DebitCardIssuanceFeePaymentModalContext.Provider>
  );
};
export default DebitCardRenewalFeePaymentModalProvider;

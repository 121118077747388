// IService
import IDebitCardRenewalCancelRenewalRequestQueryService from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/IDebitCardRenewalCancelRequestQueryService';

// IRepository
import { IDebitCardRenewalCancelRequestQueryRepository } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/IDebitCardRenewalCancelRequestRepository';

class DebitCardRenewalCancelRequestQueryService
  implements IDebitCardRenewalCancelRenewalRequestQueryService {
  private debitCardRenewalCancelRequestQueryRepository: IDebitCardRenewalCancelRequestQueryRepository;

  constructor(
    debitCardRenewalCancelRequestQueryRepository: IDebitCardRenewalCancelRequestQueryRepository,
  ) {
    this.debitCardRenewalCancelRequestQueryRepository = debitCardRenewalCancelRequestQueryRepository;
  }

  public findAllUnCompletedByUserId = async (userId: string) => {
    const debitCardRenewalCancelRequests = await this.debitCardRenewalCancelRequestQueryRepository.findAllUnCompletedByUserId(
      userId,
    );

    return debitCardRenewalCancelRequests;
  };
}

export default DebitCardRenewalCancelRequestQueryService;

// PREFIX
export const PREFIX_AUTH = `/auth`;
export const PREFIX_AUTHENTICATOR = '/authenticator';
export const PREFIX_ASSET = '/assets';
export const PREFIX_SAVINGS_ACCOUNT = '/bank-account/savings-accounts';
export const PREFIX_ECB_BANK_ACCOUNT = '/bank-account/ecb/savings-accounts';
export const PREFIX_DEBIT_CARD = '/debit-cards';
export const PREFIX_DEBIT_CARD_V2 = '/v2/debit-cards';
export const PREFIX_CONSENT_FORM = '/consent-form';
export const PREFIX_CONTRACT_TIME_DEPOSIT = '/contract/time-deposit';
export const PREFIX_CRYPTO_CURRENCY = '/crypto-currency';
export const PREFIX_CRYPTO_CURRENCY_RATE = '/crypto-currency/rate';
export const PREFIX_DEBIT_CARD_REQUEST = '/debit-card-requests';
export const PREFIX_DEBIT_CARD_RENEWAL_REQUEST = '/debit-card-renewal-requests';
export const PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST =
  '/debit-card-renewal-cancel-requests';
export const PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01 =
  '/debit-card-requests-cherry-visa-01';
export const PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01 =
  '/debit-card-renewal-requests-cherry-visa-01';
export const PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01 =
  '/debit-card-renewal-cancel-requests-cherry-visa-01';
export const PREFIX_REFERRAL_TIME_DEPOSIT = '/invite/referrals/time-deposit';
export const PREFIX_KYC_REQUEST = '/kyc-request';
export const PREFIX_TRANSACTION = '/transaction';
export const PREFIX_TRANSACTION_V1 = '/v1/transactions';
export const PREFIX_TRANSACTION_V2 = '/v2/transaction';
export const PREFIX_TRANSFER = '/transfer';
export const PREFIX_TRANSFER_V2 = '/v2/transfer';
export const PREFIX_USER_REQUEST = '/user-request';
export const PREFIX_USER = '/users';

// auth
export const AUTH_SIGN_UP = `${PREFIX_AUTH}/signup`;
export const AUTH_REGISTER = `${PREFIX_AUTH}/register`;
export const AUTH_GENERATE_OTP = `${PREFIX_AUTH}/generate-otp`;
export const AUTH_FORGOT_PASSWORD = `${PREFIX_AUTH}/forgot-password`;

// authenticator
export const AUTHENTICATOR_VERIFY = `${PREFIX_AUTHENTICATOR}/verify`;
export const AUTHENTICATOR_UPDATE = `${PREFIX_AUTHENTICATOR}`;
export const AUTHENTICATOR_CREATE = `${PREFIX_AUTHENTICATOR}`;
export const AUTHENTICATOR_CREATE_RE_REGISTRATION = `${PREFIX_AUTHENTICATOR}/re-registration`;

// asset
export const ASSET_GET_SAVINGS_ACCOUNT_RAW_DATA_BY_USER_ID_CURRENCY = `${PREFIX_ASSET}/savings-account/:userId/:currency`;
export const ASSET_GET_DEBIT_CARD_RAW_DATA_BY_USER_ID_CURRENCY = `${PREFIX_ASSET}/debit-card/:userId/:currency`;
export const ASSET_GET_TIME_DEPOSIT_RAW_DATA_BY_USER_ID_CURRENCY = `${PREFIX_ASSET}/time-deposit/:userId/:currency`;

// consentForm
export const CONSENT_FORM_UPDATE_CONSENT_FORM = `${PREFIX_CONSENT_FORM}/update/consent-form`;
export const CONSENT_FORM_UPDATE_TWO_FACTOR_AUTH_APP_SETTINGS = `${PREFIX_CONSENT_FORM}/update/two-factor-auth`;

// contract
export const CONTRACT_TIME_DEPOSIT_CREATE = `${PREFIX_CONTRACT_TIME_DEPOSIT}`;
export const CONTRACT_TIME_DEPOSIT_GET_RAW_DATA_BY_ID = `${PREFIX_CONTRACT_TIME_DEPOSIT}/:id`;
export const CONTRACT_TIME_DEPOSIT_GET_RAW_DATA_ALL_BY_USER_ID = `${PREFIX_CONTRACT_TIME_DEPOSIT}/user/:userId/all`;
export const CONTRACT_TIME_DEPOSIT_CREATE_RENEWAL_RESERVATION = `${PREFIX_CONTRACT_TIME_DEPOSIT}/:id/create/renewal-reservation`;
export const CONTRACT_TIME_DEPOSIT_UPDATE_RENEWAL_RESERVATION = `${PREFIX_CONTRACT_TIME_DEPOSIT}/:id/update/renewal-reservation`;
export const CONTRACT_TIME_DEPOSIT_DELETE_RENEWAL_RESERVATION = `${PREFIX_CONTRACT_TIME_DEPOSIT}/:id/delete/renewal-reservation`;
export const CONTRACT_TIME_DEPOSIT_HISTORIES_GET_RAW_ALL_BY_USER_ID = `${PREFIX_CONTRACT_TIME_DEPOSIT}/histories/user/:userId`;

// cryptoCurrency
export const CRYPTO_CURRENCY_TRADE_CREATE = `${PREFIX_CRYPTO_CURRENCY}/trade`;

// cryptoCurrencyRate OSL
export const CRYPTO_CURRENCY_RATE_OSL = `${PREFIX_CRYPTO_CURRENCY_RATE}/osl`;
export const CRYPTO_CURRENCY_RATE_BINANCE = `${PREFIX_CRYPTO_CURRENCY_RATE}/binance`;
export const CRYPTO_CURRENCY_GET_TRADE_LIMIT = `${PREFIX_CRYPTO_CURRENCY}/trade-limit/:currencyPair`;

// debitCard
export const DEBIT_CARD_GET_RAW_DATA_ALL_BY_SAVING_ACCOUNT_NUMBER = `${PREFIX_DEBIT_CARD}/savings-account/:savingsAccountNumber/all`;
export const DEBIT_CARD_GET_RAW_DATA_ALL_BY_USER_ID = `${PREFIX_DEBIT_CARD}/user-id/:userId/all`;

// debitCard v2
export const DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_SAVING_ACCOUNT_NUMBER = `${PREFIX_DEBIT_CARD_V2}/savings-account/:savingsAccountNumber/all`;
export const DEBIT_CARD_V2_GET_RAW_DATA_ALL_BY_USER_ID = `${PREFIX_DEBIT_CARD_V2}/user-id/:userId/all`;

// debitCardRequest
export const DEBIT_CARD_REQUEST_CREATE = `${PREFIX_DEBIT_CARD_REQUEST}`;
export const DEBIT_CARD_REQUEST_GET_UNCONFIRMED_RAW_DATA = `${PREFIX_DEBIT_CARD_REQUEST}/uncompleted/:issuer/:savingsAccountNumber`;
export const DEBIT_CARD_REQUEST_GET_RAW_ALL_BY_USER_ID = `${PREFIX_DEBIT_CARD_REQUEST}/user/:userId`;

// debitCardRequestCherryVisa01
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/:id`;
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID_WITH_PROOF_OF_IDENTITY_FILES = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/proof-of-identity/:id`;
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_GET_RAW_DATA_BY_ID_WITH_PROOF_OF_RECEIPT_FILES = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/proof-of-receipt/:id`;
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_PAY_ISSUANCE_FEE = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/pay-issuance-fee`;
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_IDENTITY = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/proof-of-identity/:id`;
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_RECEIPT = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/proof-of-receipt/:id`;
export const DEBIT_CARD_REQUEST_CHERRY_VISA_01_UPLOAD_FILE = `${PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/upload-file`;

// debitCardRenewalRequest
export const DEBIT_CARD_RENEWAL_REQUEST_GET_RAW_BY_USER_ID = `${PREFIX_DEBIT_CARD_RENEWAL_REQUEST}/user/:userId`;
export const DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_CONFIRM_SHIPPING_ADDRESS = `${PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}/confirm-shipping-address`;
export const DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_GET_RAW_BY_ID = `${PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}/:id`;
export const DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PAY_RENEWAL_FEE = `${PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}/pay-renewal-fee`;
export const DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_UPDATE_PROOF_OF_RECEIPT = `${PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}/proof-of-receipt`;

// debitCardRenewalCancelRequest
export const DEBIT_CARD_RENEWAL_CANCEL_REQUEST_GET_RAW_UN_COMPLETED_BY_USER_ID = `${PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST}/uncompleted/user/:userId`;
export const DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_CREATE = `${PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}`;
export const DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_GET_RAW_BY_ID = `${PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}/:id`;
export const DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PAY_CANCELLATION_FEE = `${PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}/pay-cancel-fee`;
export const DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_CONFIRM_SENT_CANCELLATION_FORM = `${PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}/confirm-sent-cancellation-form/:id`;
export const DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_COMPLETE_CANCELLATION = `${PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}/complete-cancellation`;

// Invite
export const INVITE_REFERRALS_TIME_DEPOSIT_FETCH_ALL_RANK_D = `${PREFIX_REFERRAL_TIME_DEPOSIT}/rank-d/:userId`;
export const INVITE_REFERRALS_TIME_DEPOSIT_FETCH_ALL_RANK_A = `${PREFIX_REFERRAL_TIME_DEPOSIT}/rank-a/:userId`;

// kycRequest
export const KYC_REQUEST_UPDATE_CORPORATE_INFORMATION = `${PREFIX_KYC_REQUEST}/update/corporate-information`;
export const KYC_REQUEST_UPDATE_CORPORATE_ADDRESS = `${PREFIX_KYC_REQUEST}/update/corporate-address`;
export const KYC_REQUEST_UPDATE_PERSONAL_INFORMATION = `${PREFIX_KYC_REQUEST}/update/personal-information`;
export const KYC_REQUEST_UPDATE_PROOF_OF_RESIDENCY = `${PREFIX_KYC_REQUEST}/update/proof-of-residency`;

// savingsAccount
export const SAVINGS_ACCOUNT_GET_CONTRACT_NAME = `${PREFIX_SAVINGS_ACCOUNT}/contract-name`;
export const SAVINGS_ACCOUNT_GET_RAW_DATA_BY_ID = `${PREFIX_SAVINGS_ACCOUNT}/:id`;
export const SAVINGS_ACCOUNT_GET_RAW_DATA_ALL_BY_USER_ID = `${PREFIX_SAVINGS_ACCOUNT}/:userId/all`;

// bankACcount ECB
export const SAVINGS_ACCOUNT_FETCH_CONTRACT_NAME_ECB_SAVINGS_ACCOUNT = `${PREFIX_ECB_BANK_ACCOUNT}/contract-name/:id`;

// transaction (bank)
export const TRANSACTION_FETCH_FUTURE_COMMISSION_TIME_DEPOSIT = `${PREFIX_TRANSACTION}/bank/future/deposit/commissionsTimeDeposit/:userId`;
export const TRANSACTION_FETCH_SPOT_RAW_ALL_BY_SAVINGS_ACCOUNT_NUMBER = `${PREFIX_TRANSACTION}/bank/spot/savings-account/:savingsAccountNumber`;

// transaction (debit)
// [DEPRECATED]
export const TRANSACTION_DEBIT_CARD_FETCH_SPOT_RAW_ALL_BY_DEBIT_CARD_NUMBER = `${PREFIX_TRANSACTION}/debit-card/spot/debit-card/:debitCardNumber`;

// transaction (debit) V2
export const TRANSACTION_DEBIT_CARD_FETCH_SPOT_RAW_ALL_BY_CARD_ID = `${PREFIX_TRANSACTION_V2}/debit-card/spot/debit-card/:cardId`;

// transfer (SavingsAccount)
export const TRANSFER_SAVINGS_ACCOUNT = `${PREFIX_TRANSFER}/savings-account`;
export const TRANSFER_ECB_BANK_ACCOUNT = `${PREFIX_TRANSFER}/savings-account/ecb`;

// transfer (DebitCard)
export const TRANSFER_DEBIT_CARD_REQUEST_V2 = `${PREFIX_TRANSFER_V2}/debit-card/request`;
export const TRANSFER_DEBIT_CARD_TRANSACTION_GET_RAW_ALL_BY_CARD_ID = `${PREFIX_TRANSFER_V2}/debit-card/:cardId`;
export const TRANSFER_DEBIT_CARD_TRANSACTION_GET_RAW_UNCONFIRMED_ALL_BY_USER_ID = `${PREFIX_TRANSFER_V2}/debit-card/unconfirmed/:userId`;
export const TRANSFER_DEBIT_CARD_GET_RAW_ALL_BY_USER_ID = `${PREFIX_TRANSFER_V2}/debit-card/userId/:userId`;
export const CANCEL_TRANSFER_DEBIT_CARD_TRANSACTION_V2_CREATE = `${PREFIX_TRANSFER_V2}/debit-card/cancel`;

// [DEPRECATED] transfer (DebitCard)
export const TRANSFER_DEBIT_CARD_REQUEST = `${PREFIX_TRANSFER}/debit-card/request`;

// user
export const USER = `${PREFIX_USER}`;

// userRequests
export const USER_REQUEST_EMAIL_UPDATE = `${PREFIX_USER_REQUEST}/update-email`;
export const USER_REQUEST_FORGOT_PASSWORD = `${PREFIX_USER_REQUEST}/forgot-password`;
export const USER_REQUEST_RE_SIGN_UP = `${PREFIX_USER_REQUEST}/resignup`;
export const USER_REQUEST_RE_SIGN_UP_FETCH_RAW_BY_OOB_CODE = `${PREFIX_USER_REQUEST}/resignup/oobCode/:oobCode`;
export const USER_REQUEST_RE_SIGN_UP_CONFIRM = `${PREFIX_USER_REQUEST}/resignup/confirm`;
export const USER_REQUEST_SIGN_IN = `${PREFIX_USER_REQUEST}/signin`;
export const USER_REQUEST_SIGN_UP = `${PREFIX_USER_REQUEST}/signup`;
export const USER_REQUEST_RESET_AUTHENTICATOR_REQUEST = `${PREFIX_USER_REQUEST}/reset-authenticator`;
export const USER_REQUEST_RESET_AUTHENTICATOR_REQUEST_RESET = `${PREFIX_USER_REQUEST}/reset-authenticator/reset`;

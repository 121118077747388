// Type
import { DebitCardRenewalRequestCherryVisa01StepType } from 'types/DebitCardRenewalRequestCherryVisa01.type';
import { TypeOfHistory } from 'types/typeof/History.type';

// Constant
import {
  ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY,
  ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PROOF_OF_RECEIPT,
} from 'config/constants/routing';

// DomainObject
import DebitCardRenewalRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01';

// Mapper
import { debitCardRenewalRequestCherryVisa01StatusMapper } from 'components/organisms/list/DebitCardRenewalRequestCherryVisa01StatusList/mapper/DebitCardRenewalRequestCherryVisa01StepMapper/debitCardRenewalRequestCherryVisa01StepStatusMapper';

class DebitCardRenewalRequestCherryVisa01StepMapper {
  static toStepsStatus = (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
  ) => {
    const { status } = debitCardRenewalRequestCherryVisa01.getProps();

    return debitCardRenewalRequestCherryVisa01StatusMapper[status];
  };

  static toSteps = (
    debitCardRenewalRequestCherryVisa01: DebitCardRenewalRequestCherryVisa01,
    history: TypeOfHistory,
    onClickCardDelivery: () => void,
    onClickPayForCardIssuanceFee: () => void,
  ) => {
    const debitCardRenewalRequestStepsStatus = DebitCardRenewalRequestCherryVisa01StepMapper.toStepsStatus(
      debitCardRenewalRequestCherryVisa01,
    );

    return [
      {
        debitCardRenewalRequestStepType:
          DebitCardRenewalRequestCherryVisa01StepType.shippingInformation,
        status: debitCardRenewalRequestStepsStatus.shippingInformation,
        onClick: () => {
          history.push(
            `${ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY}/${debitCardRenewalRequestCherryVisa01.getId()}`,
          );
        },
      },
      {
        debitCardRenewalRequestStepType:
          DebitCardRenewalRequestCherryVisa01StepType.preparingCardShipment,
        status: debitCardRenewalRequestStepsStatus.preparingCardShipment,
      },
      {
        debitCardRenewalRequestStepType:
          DebitCardRenewalRequestCherryVisa01StepType.cardDelivery,
        status: debitCardRenewalRequestStepsStatus.cardDelivery,
        onClick: onClickCardDelivery,
      },
      {
        debitCardRenewalRequestStepType:
          DebitCardRenewalRequestCherryVisa01StepType.payForCardIssuanceFee,
        status: debitCardRenewalRequestStepsStatus.payForCardIssuanceFee,
        onClick: onClickPayForCardIssuanceFee,
      },
      {
        debitCardRenewalRequestStepType:
          DebitCardRenewalRequestCherryVisa01StepType.proofOfReceipt,
        status: debitCardRenewalRequestStepsStatus.proofOfReceipt,
        onClick: () => {
          history.push(
            ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PROOF_OF_RECEIPT.replace(
              ':id',
              debitCardRenewalRequestCherryVisa01.getId(),
            ),
          );
        },
      },
      {
        debitCardRenewalRequestStepType:
          DebitCardRenewalRequestCherryVisa01StepType.waitingProofOfReceiptReview,
        status: debitCardRenewalRequestStepsStatus.waitingProofOfReceiptReview,
      },
    ];
  };
}

export default DebitCardRenewalRequestCherryVisa01StepMapper;

import React, { FC, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import DebitCardCancellationFeePaymentModalContext from 'components/organisms/modal/DebitCardCancellationFeePaymentModal/DebitCardCancellationFeePaymentModalContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import DebitCardRenewalCancelRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Context';
import DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider';
import DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider';

// Component
import DebitCardCancellationFeePaymentModal from '.';

export type DebitCardCancellationFeePaymentModalProviderProps = {
  children: React.ReactNode;
};

const DebitCardCancellationFeePaymentModalProvider: FC<DebitCardCancellationFeePaymentModalProviderProps> = ({
  children,
}: DebitCardCancellationFeePaymentModalProviderProps) => {
  const { debitCardRenewalCancelRequestCherryVisa01 } = useContext(
    DebitCardRenewalCancelRequestCherryVisa01Context,
  );
  const userAuthorizedContext = useContext(UserAuthorizedContext);

  const [showModal, hideModal] = useModal(
    () => (
      <DebitCardCancellationFeePaymentModalContext.Provider
        value={{ showModal, hideModal }}
      >
        <DebitCardRenewalCancelRequestCherryVisa01Context.Provider
          value={{ debitCardRenewalCancelRequestCherryVisa01 }}
        >
          <UserAuthorizedContext.Provider value={userAuthorizedContext}>
            <DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider>
              <DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider>
                <DebitCardCancellationFeePaymentModal />
              </DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeProvider>
            </DebitCardRenewalCancelRequestCherryVisa01PayCancellationFeeViewProvider>
          </UserAuthorizedContext.Provider>
        </DebitCardRenewalCancelRequestCherryVisa01Context.Provider>
      </DebitCardCancellationFeePaymentModalContext.Provider>
    ),
    [debitCardRenewalCancelRequestCherryVisa01, userAuthorizedContext],
  );

  return (
    <DebitCardCancellationFeePaymentModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
    </DebitCardCancellationFeePaymentModalContext.Provider>
  );
};
export default DebitCardCancellationFeePaymentModalProvider;

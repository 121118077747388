import React, { FC } from 'react';
import DebitCardPage from 'containers/debitCard/DebitCardPage/DebitCardPage';
import DebitCardViewProvider from 'enhancers/useCase/debitCard/debitCard/DebitCardViewProvider';
import DebitCardTransactionV2ViewProvider from 'enhancers/useCase/debitCard/debitCard/DebitCardTransactionV2ViewProvider';
import TransferDebitCardTransactionCancelModalProvider from 'components/organisms/modal/TransferDebitCardTransactionCancelModal/TransferDebitCardTransactionCancelModalProvider';
import DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProvider from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModal/DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProvider';
import DebitCardRenewalRequestCherryVisa01CancelConfirmModalProvider from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal/DebitCardRenewalRequestCherryVisa01CancelConfirmModalProvider';
// import DebitCardStatementModalProvider from '../../../components/organisms/modal/DebitCardStatementModal/DebitCardStatementModalProvider';
// import DebitCardStatementViewProvider from '../../../enhancers/useCase/debitCard/debitCard/DebitCardStatementViewProvider';

const EnhancedDebitCardPage: FC = () => {
  return (
    <DebitCardViewProvider>
      <DebitCardTransactionV2ViewProvider>
        {/* <DebitCardStatementViewProvider> */}
        {/* <DebitCardStatementModalProvider> */}
        <TransferDebitCardTransactionCancelModalProvider>
          <DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProvider>
            <DebitCardRenewalRequestCherryVisa01CancelConfirmModalProvider>
              <DebitCardPage />
            </DebitCardRenewalRequestCherryVisa01CancelConfirmModalProvider>
          </DebitCardRenewalRequestCherryVisa01ShippingAddressConfirmModalProvider>
        </TransferDebitCardTransactionCancelModalProvider>
        {/* </DebitCardStatementModalProvider> */}
        {/* </DebitCardStatementViewProvider> */}
      </DebitCardTransactionV2ViewProvider>
    </DebitCardViewProvider>
  );
};

export default EnhancedDebitCardPage;

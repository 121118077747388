import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CherryVisa01ProofOfReceiptFileContext from 'enhancers/fileHandler/debitCard/debitCardRequest/ProofOfReceiptFileProvider/CherryVisa01ProofOfReceiptFileContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { FileType } from 'types/File.type';
import { ImageType } from 'components/atoms/image/Image/Image.type';

// Style
import { DescriptionStyle } from 'components/organisms/form/debitCardRenewalRequests/FormCherryVisa01ProofOfReceipt/FormSelfieUpload/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRequestFormContainer from 'components/organisms/form/common/DebitCardRequestFormContainer';
import DropzoneArea from 'components/molecules/dropzone2/DropzoneArea';
import CheckPointList from 'components/organisms/list/CheckPointList';
import DebitCardRequestExampleImageDescriptionCard from 'components/organisms/card/verification/DebitCardRequestExampleImageDescriptionCard';
import FormSelfieUploadLayout from 'components/organisms/form/debitCardRenewalRequests/FormCherryVisa01ProofOfReceipt/FormSelfieUpload/FormSelfieUploadLayout';

// ValueObject
import CherryVisa01ProofOfReceipt from 'value/debitCard/DebitCardRequestContent/CherryVisa01ProofOfReceipt/CherryVisa01ProofOfReceipt';

const FORM_ID = `organisms.form.debitCards.FormProofOfReceipt.selfieUpload`;

export interface SelfieUploadFormProps {
  proofOfReceipt?: CherryVisa01ProofOfReceipt;
}

const SelfieUploadForm: FC<SelfieUploadFormProps> = ({
  proofOfReceipt,
}: SelfieUploadFormProps) => {
  const { t } = useTranslation();
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { selfie, setSelfie } = useContext(
    CherryVisa01ProofOfReceiptFileContext,
  );

  const defaultFilePath = useMemo(() => {
    const uploadedFile = proofOfReceipt?.getProps().selfie;

    return uploadedFile && URL.createObjectURL(uploadedFile);
  }, [proofOfReceipt]);

  /* *
   *
   *  UseEffect
   *
   * */

  useEffect(() => {
    if (selfie && userAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadedFilePath = (selfie as any).path;
      setValue('selfieUrl', uploadedFilePath);
    }
    // eslint-disable-next-line
  }, [selfie, userAuthorized]);

  return (
    <DebitCardRequestFormContainer key="selfie" order={1} formId={FORM_ID}>
      <FormSelfieUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="selfie"
          urlName="selfieUrl"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={defaultFilePath ?? ''}
          maxFileSize={2}
          setFile={setSelfie}
          width={667}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleOK"
          imageType={ImageType.exampleImageOK}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleNG1"
          imageType={ImageType.exampleImageNG1}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleNG2"
          imageType={ImageType.exampleImageNG2}
        />
        <DebitCardRequestExampleImageDescriptionCard
          key="exampleNG3"
          imageType={ImageType.exampleImageNG3}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.cardMustBeUsed`),
          ]}
        />
      </FormSelfieUploadLayout>
    </DebitCardRequestFormContainer>
  );
};

export default SelfieUploadForm;

import React from 'react';

// DomainObject
import DebitCardRenewalRequest from './DebitCardRenewalRequest';

export interface DebitCardRenewalRequestContextProps {
  debitCardRenewalRequest?: DebitCardRenewalRequest;
}

const DebitCardRenewalRequestContext = React.createContext<
  DebitCardRenewalRequestContextProps
>({
  debitCardRenewalRequest: undefined,
});

export default DebitCardRenewalRequestContext;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DebitCardRenewalRequestCherryVisa01Context from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01Context';

// Mapper
import DebitCardRenewalRequestCherryVisa01StepMapper from 'components/organisms/list/DebitCardRenewalRequestCherryVisa01StatusList/mapper/DebitCardRenewalRequestCherryVisa01StepMapper/DebitCardRenewalRequestCherryVisa01StepMapper';

// Component
import FormCherryVisa01ProofOfReceipt from 'components/organisms/form/debitCardRenewalRequests/FormCherryVisa01ProofOfReceipt';
import DebitCardRenewalRequestCherryVisa01StepTemplate from 'components/templates/debitCardRenewalRequest/DebitCardRenewalRequestCherryVisa01StepTemplate';

const TEMPLATE_ID = `templates.debitCards.CherryVisa01ProofOfReceiptTemplate`;

const CherryVisa01ProofOfReceiptTemplate: FC = () => {
  const { t } = useTranslation();
  const { debitCardRenewalRequestCherryVisa01 } = useContext(
    DebitCardRenewalRequestCherryVisa01Context,
  );

  if (!debitCardRenewalRequestCherryVisa01) return <></>;

  // First Level Property
  const { proofOfReceipt } = debitCardRenewalRequestCherryVisa01.getProps();

  // Second Level Property
  const rejectedReason = proofOfReceipt?.getProps().rejectedReason;
  const status = DebitCardRenewalRequestCherryVisa01StepMapper.toStepsStatus(
    debitCardRenewalRequestCherryVisa01,
  )?.proofOfReceipt;

  return (
    <DebitCardRenewalRequestCherryVisa01StepTemplate
      debitCardRenewalRequestCherryVisa01={debitCardRenewalRequestCherryVisa01}
      status={status}
      title={t(`${TEMPLATE_ID}.title`)}
      rejectedReason={rejectedReason}
    >
      <FormCherryVisa01ProofOfReceipt
        id={debitCardRenewalRequestCherryVisa01.getId()}
        proofOfReceipt={proofOfReceipt}
      />
    </DebitCardRenewalRequestCherryVisa01StepTemplate>
  );
};

export default CherryVisa01ProofOfReceiptTemplate;

/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import DebitCardShippingInformationModalContext from 'components/organisms/modal/DebitCardShippingInformationModal/DebitCardShippingInformationModalContext';

// Style
import {
  CancelButtonTextStyle,
  contentStyle,
  overlayStyle,
  TitleStyle,
} from 'components/organisms/modal/DebitCardShippingInformationModal/style';

// Components
import { CancelButton } from 'components/atoms/button/Button2';
import { TextPrimary } from 'components/atoms/text2/Text2';
import TableCardDeliveryStatus from 'components/organisms/table/confirm/TableCardDeliveryStatus';
import CardShippingInformationModalLayout from 'components/organisms/modal/DebitCardShippingInformationModal/DebitCardShippingInformationModalLayout';

const ORGANISM_ID = `organisms.modal.CardShippingInformationModal`;

const CardShippingInformationModal = () => {
  const { t } = useTranslation();
  const { hideModal } = useContext(DebitCardShippingInformationModalContext);

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <CardShippingInformationModalLayout>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TableCardDeliveryStatus key="table" />
        <CancelButton key="button" width={470} height={75} onClick={hideModal}>
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.Close`)}
          </TextPrimary>
        </CancelButton>
      </CardShippingInformationModalLayout>
    </ReactModal>
  );
};

export default CardShippingInformationModal;

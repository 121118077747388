// React
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { setDebitCardRenewalRequests } from 'redux/reducers/debitCardRenewalRequestsReducer';
import { setDebitCardRenewalCancelRequests } from 'redux/reducers/debitCardRenewalCancelRequestsReducer';

// ErrorHandler
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// UseCase
import DebitCardViewUseCase from 'useCases/debitCard/debitCard/DebitCardViewUseCase';

// Repository
import SavingsAccountFunctions from 'repository/bankAccount/savingsAccount/SavingsAccountFunctions';
import DebitCardV2Functions from 'repository/debitCard/debitCard/DebitCardV2Functions';
import DebitCardRenewalRequestFunctions from 'repository/debitCard/debitCardRenewalRequest/DebitCardRenewalRequestFunctions';
import DebitCardRenewalCancelRequestFunctions from 'repository/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestFunctions';

// DomainObject
import { useSavingsAccounts } from 'domain/bankAccount/savingsAccount/SavingsAccounts/useSavingsAccounts';
import { useDebitCardV2s } from 'domain/debitCard/debitCardV2/DebitCardV2s/useDebitCardV2s';
import { useDebitCardRenewalRequests } from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequests/useDebitCardRenewalRequests';
import { useDebitCardRenewalCancelRequests } from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequest/DebitCardRenewalCancelRequests/useDebitCardCancelRenewalRequests';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// Option
import { useSavingsAccountOption } from 'components/molecules/selectBox/option/option/SavingsAccountOption/useSavingsAccountOption';
import { useDebitCardOption } from 'components/molecules/selectBox/option/option/DebitCardOption/useDebitCardOption';

export const useDebitCardViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  /* *
   *
   *  DomainObject
   *
   * */
  const { savingsAccounts, setSavingsAccounts } = useSavingsAccounts();
  const { debitCardV2s, setDebitCardV2s } = useDebitCardV2s();
  const debitCardRenewalRequests = useDebitCardRenewalRequests();
  const debitCardRenewalCancelRequests = useDebitCardRenewalCancelRequests();

  // SelectBox
  const {
    savingsAccountOption,
    setSavingsAccountOption,
  } = useSavingsAccountOption();
  const { debitCardOption, setDebitCardOption } = useDebitCardOption();

  /* *
   *
   *  Repository
   *
   * */
  const savingsAccountRepository = new SavingsAccountFunctions();
  const debitCardV2Repository = new DebitCardV2Functions();
  const debitCardRenewalRequestRepository = new DebitCardRenewalRequestFunctions();
  const debitCardRenewalCancelRequestRepository = new DebitCardRenewalCancelRequestFunctions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardViewUseCase(
    debitCardV2Repository,
    savingsAccountRepository,
    debitCardRenewalRequestRepository,
    debitCardRenewalCancelRequestRepository,
  );

  /* *
   *
   *  Methods
   *
   * */
  const openDebitCard = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // execute
      const output = await useCase.open(user);

      setSavingsAccounts(output.savingsAccounts);
      dispatch(
        setDebitCardRenewalRequests({
          debitCardRenewalRequests: output.debitCardRenewalRequests,
        }),
      );
      dispatch(
        setDebitCardRenewalCancelRequests({
          debitCardRenewalCancelRequests: output.debitCardRenewalCancelRequests,
        }),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  const fetchDebitCards = useCallback(async () => {
    if (!savingsAccountOption) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // execute
      const output = await useCase.findDebitCards(savingsAccountOption);

      setDebitCardV2s(output.debitCardV2s);

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [savingsAccountOption]);

  /* *
   *
   *  UseEffect
   *
   * */
  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) openDebitCard();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  // Initial setup (SavingsAccounts is fetched once)
  useEffect(() => {
    setSavingsAccountOption(savingsAccounts.head());
  }, [savingsAccounts, setSavingsAccountOption]);

  // Fetch debitCards (by SavingsAccountOption)
  useEffect(() => {
    let isMounted = true;

    if (isMounted && savingsAccountOption) fetchDebitCards();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [savingsAccountOption]);

  // Initial setup (DebitCards is fetched once)
  useEffect(() => {
    setDebitCardOption(debitCardV2s.head());
  }, [debitCardV2s, setDebitCardOption]);

  // get uncompleted debitCardRenewalRequest
  const debitCardRenewalRequest = useMemo(
    () =>
      debitCardRenewalRequests &&
      debitCardOption &&
      debitCardRenewalRequests
        .filterByDebitCardId(debitCardOption.getId())
        .filterNotCompleted()
        .head(),
    [debitCardRenewalRequests, debitCardOption],
  );

  return {
    state,
    setState,
    savingsAccounts,
    savingsAccountOption,
    setSavingsAccountOption,
    debitCards: debitCardV2s,
    debitCardOption,
    setDebitCardOption,
    debitCardRenewalRequest,
    debitCardRenewalCancelRequest: debitCardRenewalCancelRequests?.head(),
  };
};
